// vue component
import 'vue-loading-overlay/dist/vue-loading.css';
import { required } from 'vee-validate/dist/rules';
import { extend } from 'vee-validate';
import { httpRequest, httpPost } from '@/services/httpService';

extend('required', {
    ...required,
    message: 'Ce champ est requis',
})
export default {
    name: 'AppInterface',
    created() {
        this.getLangues();
        this.getInterfaces();
        this.getTypeInterfaces();
    },
    data() {
        return {
            on: true,
            valid:false,
            isloading: false,
            itemsPerPage: 10,
            langues: [],
            interfaces: [],
            typeInterfaces: [],
            search: '',
            headers: [
                { text: 'Nom de l\'interface', value: 'cAppInterface' },
                { text: 'URL', value: 'cURL' },
                { text: 'Type', value: 'cType' },
                { text: 'Actions', value: 'modifier', sortable: false, align: 'center' },
            ],
            dialogCreateAppInterface: false,
            formInterface: {
                nom: '',
                url: '',
                description: '',
                type: '',
                id: '',
                bActif: false
            },
            messageError: '',
            typeAction: '',

        }
    },
    methods: {
        getInterfaces() {
            this.isloading = true;
            httpRequest('/admAppInterfaces')
                .then(response => {
                    this.interfaces = response.data;
                    this.isloading = false;
                })
        },

        getLangues() {
            httpRequest('/langues')
                .then(response => {
                    this.langues = response.data;
                })
                console.log('langues', this.langues);
        },

        createUpdateInterface() {
            if (this.typeAction == 'create') {
                this.searchInterface();
            } else {
                this.updateInterface();
            }
        },

        openDialogCreateInterface(item, typeAction) {
            this.typeAction = typeAction;
            this.dialogCreateAppInterface = true;
        },

        closeDialogCreateInterface() {
            this.clearForm();
            this.dialogCreateAppInterface = false;
        },

        async searchInterface() {
            const respons = await httpPost('/admAppInterfaces/searchAppInterfaceNom', { 'cAppInterface': this.formInterface.nom })
            if (!respons.status) {
                this.createInterface();
            } else {
                this.$swal({
                    position: 'bottom',
                    icon: 'warning',
                    title: 'Cette interface existe déjà',
                    showConfirmButton: false,
                    timer: 2500
                })
            }
        },

        async createInterface() {
            await httpPost('/admAppInterfaces/create', {
                cAppInterface: this.formInterface.nom,
                cURL: this.formInterface.url,
                cDescription: this.formInterface.description,
                cType: this.formInterface.type,
                bActif: this.formInterface.bActif,
            })
            this.clearForm();
            this.dialogCreateAppInterface = false;
            this.getInterfaces();
            this.$swal({
                type: 'success',
                title: 'Succès',
                icon: 'success',
                text: "L'interface a été créée avec succès",
                confirmButtonText: 'Ok',
            })
        },

        async updateInterface() {
            await httpPost('/admAppInterfaces/update', {
                pAppInterface: this.formInterface.id,
                cAppInterface: this.formInterface.nom,
                cURL: this.formInterface.url,
                cDescription: this.formInterface.description,
                cType: this.formInterface.type,
                bActif: this.formInterface.bActif,
            })
            this.clearForm();
            this.dialogCreateAppInterface = false;
            this.getInterfaces();
            var msg = "L'interface a été modifiée avec succès";
            this.$swal({
                type: 'success',
                title: 'Succès',
                text: msg,
                icon: 'success',
                confirmButtonText: 'Ok',
            })
        },

        openDialogUpdateInterface(item, typeAction) {
            this.dialogCreateAppInterface = true;
            this.formInterface.nom = item.cAppInterface;
            this.formInterface.url = item.cURL;
            this.formInterface.description = item.cDescription;
            this.formInterface.id = item.pAppInterface;
            this.formInterface.bActif = item.bActif;
            this.formInterface.type = item.cType;
            this.typeAction = typeAction;
        },

        clearForm() {
            this.$refs.observer.reset();
            this.formInterface.nom = '';
            this.formInterface.url = '';
            this.formInterface.description = '';
            this.formInterface.id = '';
            this.formInterface.bActif = false;
            this.typeAction = '';
            this.messageError = '';
        },
       
        async getTypeInterfaces() {
            const response = await httpRequest('/admAppTypesInterfaces');
            if (response) {
                this.typeInterfaces = response.data;
            }
        },
        
    },
}