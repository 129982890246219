import { email, required } from 'vee-validate/dist/rules';
import { extend, ValidationObserver, ValidationProvider } from 'vee-validate'
import { httpPost } from '@/services/httpService';

extend('email', {
    ...email,
    message: 'Veuillez entrer un courriel valide',
})

extend('required', {
    ...required,
    message: 'Ce champ est requis',
})

export default {
    name: 'createGroupeComponent',
    props: {
        dialogCreateGroupe: {
            type: Boolean,
            default: '',
        },
        formGroupe: {
            type: Object,
            default: () => ({
                nom: '',
                description: '',
                pDepartement: null,
                cDepartement: '',
                pGroupe: null,
                bActif: false,
            }),
        },
        typeAction: {
            type: String,
            default: 'create',
        },

    },
    components: {
        ValidationProvider,
        ValidationObserver
    },

    data() {
        return {
            on: false,
            isloading: false,
            groupes: [],
            search: '',
            valid: true,
            messageError: '',
        }
    },

    methods: {
        closeDialogCreateGroupe() {
            this.$refs.observer.reset();

            this.$emit('closeDialogCreateGroupe');
            this.$store.state.modalCreate.show = false;
        },
        createUpdateGroupe() {
            if (this.typeAction === 'create') {
                console.log('createUpdateGroupe', this.typeAction);
                this.searchGroupes();
            } else {
                console.log('update');
                this.updateGroupe();
            }
        },

        async searchGroupes() {
            console.log('searchGroupes  ', this.formGroupe.nom);
            const respons = await httpPost('/groupes/searchgroupe', { 'cGroupe': this.formGroupe.nom, 'pDepartement': this.formGroupe.pDepartement });
            console.log('respons', respons);
            if (!respons.status) {
                this.createGroupe();
            } else {
                this.$swal({
                    position: 'bottom',
                    icon: 'warning',
                    title: 'Ce groupe existe déjà',
                    showConfirmButton: false,
                    timer: 2500
                })
            }
        },
        async createGroupe() {
            await httpPost('/groupes/create', {
                cGroupe: this.formGroupe.nom,
                cDescription: this.formGroupe.description,
                pDepartement: this.formGroupe.pDepartement,
                bActif: this.formGroupe.bActif
            })
            this.clearForm();
            this.closeDialogCreateGroupe();
            this.$emit('getUnites');

            this.$swal({
                title: "Succès",
                text: "Groupe enregistré",
                type: "success",
                icon: 'success',
                confirmButtonText: "Ok"
            });

        },
        clearForm() {
            this.formGroupe.nom = '';
            this.formGroupe.description = '';
            this.formGroupe.bActif = false;

            this.messageError = '';
            this.$refs.observer.reset();
        },
        async updateGroupe() {

            await httpPost('/groupes/update', {
                pDepartement: this.formGroupe.pDepartement,
                pGroupe: this.formGroupe.pGroupe,
                cGroupe: this.formGroupe.nom,
                cDescription: this.formGroupe.description,
                bActif: this.formGroupe.bActif
            })
            this.clearForm();
            this.closeDialogCreateGroupe();
            this.$emit('getUnites');

            this.$swal({
                title: "Succès",
                text: "Groupe modifié",
                type: "success",
                icon: 'success',
                confirmButtonText: "Ok"
            });
        }
    },
}
