import axios from 'axios';

/**
 * function para servicio get
 * @param {text} url 
 *  * 
 */

const  httpGet = async (url) => {    
    const token = localStorage.getItem('token');
    const headers = { Authorization: `Bearer ${token}`, Accept :'application/json',};
    
    const response = await axios.get(process.env.VUE_APP_API_URL + url, { headers: headers })
    return response.data;
        
};

const  httpRequest = (url) => {  
    const token = localStorage.getItem('token');
    const headers = { Authorization: `Bearer ${token}`, Accept :'application/json',};

    return axios.get(process.env.VUE_APP_API_URL + url, { headers: headers });       
};

const  httpRequestApi = (url) => { 
    const token = localStorage.getItem('token');
    const headers = { Authorization: `Bearer ${token}`, Accept :'application/json',};

    return axios.get(url, { headers: headers });       
};

//get users interface
const httpGetUsersInterface = () => {   
    //console.log(process.env.VUE_INTERFACE_ESPAGNOL); 
    return axios.get('http://localhost:81/example/cms/interface.php'); 
};

/**
 * function para servicio post
 * @param {text} url 
 * @param {object} model 
 */
const httpPost = async (url, model) => {
    const token = localStorage.getItem('token');
    const headers = { Authorization: `Bearer ${token}`, Accept :'application/json',};

    const response = await axios.post(process.env.VUE_APP_API_URL + url, model, { headers: headers })
    return response.data;        
};

export {httpGet, httpPost, httpRequest, httpGetUsersInterface, httpRequestApi};

