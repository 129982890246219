import { httpPost } from "../../../../services/httpService";
export default {
  name: "GrillesRows",
  props: {
    grilleRows: {
      type: Array,
      required: true,
    },
    grilleBlocks: {
      type: Array,
      required: true,
    },
  },

  data() {
    return {};
  },

  methods: {
    filterRow(block) {
      console.log("filterRow", block);
      return this.grilleRows.filter((item) => item.pGrilleBlock === block);
    },
    async addRow(item) {
      const data = {
        cTitre: item.title,
        cSousTitre: "",
        pGrilleBlock: item.pGrilleBlock,
        bActif: 1,
        nFilter: "",
      };
      const result = await httpPost("/grilleRows/create", data);
      const { status } = result;
      if (status === 200) {
        this.grilleRows.push(result.data);
      }
      this.$emit("refresh");
    },
    async deleteItemRow(item) {
      //console.log("item", item.pRow);
      await httpPost("/grilleRows/delete", {
        pRow: item.pGrilleBlockRows,
      });
      //console.log("response", response);
      this.$emit("refresh");
    },
  },
};