<template>
  <div>
    <v-dialog 
    v-model="$store.state.modalInterface.show" 
    persistent 
    width="800">
      <div v-if="user.pInterface == null">
        <v-toolbar dark color="primary">
          <v-toolbar-title class="mt-4 mb-4">
            <span class="text-h5">
              Attribuer l'interface à l'utilisateur
              <br />
              <strong> {{ user ? user.cPrenom + " " + user.cNom : "" }}</strong>
            </span>
          </v-toolbar-title>
        </v-toolbar>
        <v-card class="mx-auto" outlined>
          <v-card-title>
            <v-row>
              <v-col cols="6">
                <v-select
                  v-model="selectInterface"
                  :items="interfaces"
                  item-text="cAppInterface"
                  item-value="pAppInterface"
                  label="Interface"
                  @input="getUtilisateurs"
                  single-line
                >
                </v-select>
              </v-col>

              <v-col cols="6">
                <v-spacer></v-spacer>
                <v-text-field
                  v-model="search"
                  append-icon="mdi-magnify"
                  label="Recherche"
                  single-line
                  hide-details
                ></v-text-field>
              </v-col>
            </v-row>
          </v-card-title>

          <v-card-text>
            <v-data-table
              dense
              v-model="selected"
              :headers="headers"
              :items="users"
              :items-per-page="10"
              class="elevation-1"
              :search="search"
              :single-select="true"
              :loading="isloading"
              show-select
              loading-text="Chargement... Veuillez patienter"
              :footer-props="{
                'items-per-page-text': 'Lignes par page',
                'items-per-page-all-text': 'Tout',
              }"
            >
              <template v-slot:item.actions="{ item }">
                <v-icon small class="mr-2" @click="editItem(item)">
                  mdi-pencil
                </v-icon>
              </template>
            </v-data-table>
          </v-card-text>

          <v-card-actions>
            <v-btn color="error" class="ml-3 mr-4" @click="clearForm">
              Annuler
            </v-btn>
            <v-btn
              :disabled="invalid"
              color="primary"
              class="mr-4 white--text"
              @click="validerInterface('joindre')"
            >
              Enregistrer
            </v-btn>
          </v-card-actions>
        </v-card>
      </div>
      <div v-else>
        <v-toolbar dark color="primary">
          <v-toolbar-title class="mt-4 mb-4">
            <span class="text-h5 ">
              Supprimer l'interface de l'utilisateur
              <br />
              <strong> {{ user ? user.cPrenom + " " + user.cNom : "" }}</strong>
            </span>
          </v-toolbar-title>
        </v-toolbar>

        <v-card class="mx-auto" outlined>
          <v-card-text>
            <v-alert
              :value="true"
              type="error"
              icon="mdi-alert-circle"
              color="amber accent-4"
            >
              <strong>Attention !</strong>
              <br />
              Vous êtes sur le point de supprimer l'interface de l'utilisateur
              <strong>
                {{ user ? user.cPrenom + " " + user.cNom : "" }}.
              </strong>
              <br />
              Cette action est irréversible.
            </v-alert>
          </v-card-text>
          <v-card-actions>
            <v-btn color="error" class="ml-3 mr-4" @click="clearForm">
              Annuler
            </v-btn>
            <v-btn
              :disabled="invalid"
              color="primary"
              class="mr-4 white--text"
              @click="validerInterface({})"
            >
              Supprimer interface
            </v-btn>
          </v-card-actions>
        </v-card>
      </div>
    </v-dialog>
  </div>
</template>

<script>
import { httpRequestApi } from "@/services/httpService";
import { httpPost } from "../services/httpService";
export default {
  name: "RolesInterface",
  props: {
    user: {
      type: Object,
      required: true,
    },
    interfaces: {
      type: Array,
      required: true,
    },
  },
  data() {
    return {
      dialog: true,
      search: "",
      selectInterface: [],
      selected: [],
      users: [],
      invalid: false,
      isloading: false,
      status: null,
      headers: [
        { text: "Prénom", value: "prenom" },
        { text: "Nom", value: "nom" },
        { text: "Courriel", value: "email" },
      ],
    };
  },
  methods: {
    async getUtilisateurs() {
      this.isloading = true;
      const url = this.interfaces.find(
        (langue) => langue.pAppInterface === this.selectInterface
      );

      const respons = await httpRequestApi(url.cURL);
      this.isloading = false;
      this.users = respons.data;
    },
    clearForm() {
      this.users = [];
      this.selected = [];
      this.selectInterface = [];
      this.search = "";
      this.isloading = false;
      this.$store.dispatch("setModalInterface", false);
    },

    validerInterface(typeAction) {
      if (typeAction === "joindre") {
        if (this.selectInterface.length != 0) {
          this.status = this.selectInterface;
          this.saveInteraface();
        } else {
          this.$swal({
            title: "Erreur",
            text: "Veuillez sélectionner une interface",
            type: "error",
            showConfirmButton: false,
            timer: 2500,
            position: "bottom",
            icon: "warning",
          });
        }
      } else {
        this.status = null;
        this.saveInteraface();
      }
    },

    async saveInteraface() {
      await httpPost("/users/updatepInterface", {
        pUser: this.user.pUser,
        pInterface: this.status,
      });
      this.clearForm();
      this.$swal({
        title: "Succès",
        text: "Succès",
        type: "success",
        confirmButtonText: "Ok",
      });
      this.$store.dispatch("setModalInterface", false);
      this.$emit("refresh");
    },
  },
};
</script>
