import Vue from "vue";
import VueRouter from "vue-router";
import Signin from "@/views/public/users/signin/SigninView.vue";
import Signup from "@/views/private/signup/Signup.vue";
import Roles from "@/views/private/config/User/roles/Roles.vue";
import RolesRessouces from "@/views/private/config/Options/Organisation/RolesRessources.vue";
import Users from "@/views/private/config/User/Users.vue";
import Edituser from "@/views/private/config/User/EditUser/Edituser.vue";
import Unites from "@/views/private/config/Options/Organisation/Unites.vue";
import Langues from "@/views/private/config/Options/Organisation/Langues.vue";
import Competences from "@/views/private/config/Options/Organisation/Competences.vue";
import Home from "@/views/Home.vue";
import Dashboard from "@/views/private/dashboard/Dashboard.vue";
import Profile from "@/views/private/config/profile/Profile.vue";
import ProfileUtilisateur from "@/views/private/config/profile/ProfileUtilisateur.vue";
import Interface from "@/views/private/interface/interface.vue";
import AppTypeInterface from "@/views/private/config/Options/Organisation/AppTypeInterface.vue";
import AppInterface from "@/views/private/config/Options/Organisation/AppInterface.vue";
import GrilleRegles from "@/views/private/config/Grilles/GrilleRegles.vue";
import GrilleURL from "@/views/private/config/Grilles/GrilleURL.vue";
import passwordRecovery from "@/views/private/config/User/passwordRecovery/passwordRecovery.vue";
import Buttons from '@/component/Buttons.vue';
import Grid from '@/component/Grid.vue';
import vue404 from '@/components/404.vue';
import underConstruction from '@/components/underConstruction.vue';
import attention from '@/components/attention.vue';
import Rapports from '@/views/private/reports/Rapports.vue';
import Grilles from '@/views/private/config/Grilles/Grilles.vue';
//
import CreateGrille from '@/views/private/config/Grilles/CreateGrille.vue';
import ListeGrillesNew from '@/views/private/config/Grilles/ListeGrillesNew.vue';
import PreviewPage from '@/views/private/config/Grilles/PreviewPage.vue';
import GrilleEchantillonQuestion from '@/views/private/config/Grilles/GrilleEchantillonQuestion.vue';
import GrilleEchantillonList from '@/views/private/config/Grilles/GrilleEchantillonList.vue';
import GrilleEchantillon from '@/views/private/config/Grilles/GrilleEchantillon.vue';

import GrilleEntrainement from '@/views/private/grilles/GrilleEntrainement.vue';
import GrilleRechauffement from '@/views/private/grilles/GrilleRechauffement.vue';
//
import ListGrilles from '@/views/private/config/Grilles/ListGrilles.vue';
import Correction from '@/views/private/correction/Correction.vue';
import VueCookies from "vue-cookies";

// store
import store from '../store/store'

Vue.use(VueRouter);

const routes = [
  { path: '*', component: vue404 },
  { path: "/login", name: "Login", component: Signin,},
  { path: "/correct/:code/:competence/:langue", name: "interface", component: Correction, },
  { path: "/previewPage/:id/:type", name: "PreviewPage", component: PreviewPage,
    meta: {
      requiresAuth: false
    }
  },
  { path: "/", component: Home, name: "Home", 
    meta: {
      requiresAuth: true
    },
    children: [
      { path: "", component: Dashboard },
      { path: "/dashboard", component: Dashboard },
      { path: "/users", component: Users },
      { path: "/user/edit/:user", name: "Edituser", component: Edituser },
      { path: "/user/passwordRecovery/:user", name: "passwordRecovery", component: passwordRecovery },
      { path: "/roles", name: "Roles", component: Roles },
      { path: "/roles/ressources", name: "RolesRessouces", component: RolesRessouces },
      { path: "/unites", name: "Unites", component: Unites },
      { path: "/langues", name: "Langues", component: Langues },
      { path: "/competences", name: "Competences", component: Competences },
      { path: "/profile2", name: "profile", component: Profile },
      { path: "/profile", name: "ProfileUtilisateur", component: ProfileUtilisateur },
      { path: "/register", name: "Register", component: Signup, },
      { path: "/appinterface", name: "Interface", component: AppInterface },
      { path: "/apptypeinterface", name: "TypeInterface", component: AppTypeInterface },
      { path: "/grilleregles", name: "GrilleRegles", component: GrilleRegles },
      { path: "/grilleurl", name: "GrilleURL", component: GrilleURL },
      { path: "/rapports", name: "Rapports", component: Rapports },
      { path: "/grilles", name: "Grilles", component: Grilles },
      { path: "/Listgrilles", name: "Listgrilles", component: ListGrilles },
      { path: "/liste", name: "ListeGrillesNew", component: ListeGrillesNew },
      { path: "/creategrille", name: "Creategrille", component: CreateGrille },
      { path: "/entrainement/:id/:type", name: "entrainement", component: GrilleEntrainement },
      { path: "/rechauffement/:id/:type", name: "rechauffement", component: GrilleRechauffement },
      { path: "/echantillonquestion", name: "echantillonquestion", component: GrilleEchantillonQuestion },
      { path: "/echantillonlist", name: "echantillonlist", component: GrilleEchantillonList },
      { path: "/echantillon", name: "echantillon", component: GrilleEchantillon },
      { path: "/interface/:value/:corrector1/:corrector2/:domaine", name: "interface", component: Interface, },
      { path: "/button", name: "interface", component: Buttons, },
      { path: "/grid", name: "interface", component: Grid, },
      { path: '/underconstruction', component: underConstruction },
      { path: '/attention', component: attention },
    ],
  },
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
})

router.beforeEach((to, from, next) => {
  //console.log("to", to, "from", from, "next", next);
  const userRoles = localStorage.getItem("user_roles");
  //const userRoles = store.getters.getRole; 
  const userRolesData = userRoles ? JSON.parse(userRoles) : [];
  let user_roles = [];
  userRolesData.forEach((rol_obj) => {
    const roles_id = rol_obj.pRoles.replace(/\[|\]/g, "").split(",");
    roles_id.forEach((rol_id) => {
      user_roles.push(rol_id);
    });
  });

  if (user_roles.includes("1")) {
    next();
  } else {
    //User restrictions
    if (to.path == "/users" || to.path == "/unites") {
      next("*");
    }
    //Grilles restrictions
    if (
      to.path == "/grilles" ||
      to.path == "/ListgrillesNew" ||
      to.path == "/liste" ||
      to.path == "/grilleregles" ||
      to.path == "/grilleurl" ||
      to.path == "/creategrille" ||
      to.path == "/echantillonquestion" ||
      to.path == "/echantillonlist" ||
      to.path == "/echantillon"
    ) {
      if (
        !user_roles.includes("2") &&
        !user_roles.includes("3")
      ) {
        next("*");
      } else if (
        !user_roles.includes("2") &&
        (to.path == "/ListgrillesNew" ||
          to.path == "/grilleregles" ||
          to.path == "/grilleurl" ||
          to.path == "/creategrille" ||
          to.path == "/echantillonquestion" ||
          to.path == "/echantillonlist" ||
          to.path == "/echantillon"
        )
      ) {
        next("*");
      }
    }
    //Consultation restrictions
    if (
      to.path == "/reports" ||
      to.path == "/rapports" ||
      to.path == "/reporttypes"
    ) {
      if (!user_roles.includes("4")) {
        next("*");
      }
    }
    //Entrainement restrictions
    if (to.path == "/entrainement") {
      if (!user_roles.includes("6")) {
        next("*");
      }
    }
    //Rechauffement restrictions
    if (to.path == "/rechauffement") {
      if (!user_roles.includes("7")) {
        next("*");
      }
    }
  }
  if (to.path == "/logout") {
    VueCookies.remove("token");
    localStorage.removeItem("user");
    store.dispatch('doLogout')
    // redirect to the login page
    next("/login");
  }

  if (to.matched.some((record) => record.meta.requiresAuth)) {
    const token = VueCookies.get("token");
    if (token == null) {
      // If token doesn't exist (user is not logged in), redirect to login
      //console.log("to", to);
      if (to.fullPath != "/") {
        next({
          path: "/login?redirect=" + to.fullPath,
        });
      } else {
        next({
          path: "/login",
          params: { nextUrl: to.fullPath },
        });
      }
    } else {
      next();
    }
  } else {
    next();
  }
});

export default router;