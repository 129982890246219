import { httpGet } from '@/services/httpService';

export default {
    name: 'GrilleEchantillonList',
    created() {
        this.getEchantillons();
        this.getEchantillonQuestionAttributions();
    },
    data() {
        return {
            headers: [
                { text: 'Échantillon', value: 'cEchantillon', sortable: false },
                { text: 'Langue', value: 'cEchantillonLangue', sortable: false },
                { text: 'Compétence', value: 'cEchantillonCompetence', sortable: false },
                { text: 'Questions attribuées', sortable: false},
                { text: 'Grille attribuée', sortable: false },
                { text: 'Actions', value: 'action', sortable: false, align: 'center' },
            ],
            echantillons: [],
            questions: [],
            search: '',
            isloading: false,
            itemsPerPage: 10,
        };
    },
    methods: {
        async getEchantillons() {
            const result = await httpGet('/echantillons');
            this.echantillons = result;
        },
        async getEchantillonQuestionAttributions() {
            const result = await httpGet('/echantillonQuestionAttributions');
            this.questions = result;
        },

        // filter this.questions by pGrilleEchantillon
        filterQuestionsAttributions(pGrilleEchantillon) {
            let questions = this.questions.filter(question => parseInt(question.pGrilleEchantillon) === parseInt(pGrilleEchantillon));
            return questions.length;
        },
              
        createEchantillon() {
            this.$router.push('/echantillon');
        },
        editEchantillon(id) {
            this.$router.push('/echantillon?id=' + id);
        },
    }
}