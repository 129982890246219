import { httpGet, httpRequest } from "@/services/httpService";

export default {
    name: "GrillesPreview",
    data() {
        return {
            pGrille: "",
            cGrille: "",
            cGrilleDesc: "",
            cGrilleLangue: "",
            cGrilleCompetence: "",
            pasEvalue: false,
            textPasEvalue: "",
            grilleColumns: [],
            grilleBlocs: [],
            grilleBlocsLignes: [],
            grilleCellules: [],
            previewLignes: [
                {
                    typeLigne: "",
                    previewColumns: [
                        {
                            indexCelluleStyle: 0,
                            selected: false,
                        },
                    ],
                },
            ],
            grillePasEvalue: false,
            textGrillePasEvalue: "",
        }
    },
    async created() {
        if (this.$route.params.id) {
            this.pGrille = this.$route.params.id;
            await this.indexTablePreview();
        }
    },

    methods: {
        async getGrilles(pGrille) {
            const response = await httpGet("/preview/grille/" + pGrille);
            return response;
        },
        async getColumns() {
            const response = await httpRequest("/preview/grilleColumns/" + this.pGrille);
            return response;
        },
        async getBlocs() {
            const blocs = await httpGet("/preview/grilleBlocks/" + this.pGrille);
            return blocs;
        },
        async getBlocLignes() {
            const response = await httpGet("/preview/grilleRows/grille/" + this.pGrille);
            return response;
        },
        filterRow(bloc) {
            const filterRow = this.grilleBlocsLignes.filter(
                (item) => parseInt(item.pGrilleBlock) === bloc
            );
            return filterRow;
        },
        async getCellules() {
            const response = await httpGet("/preview/grilleRowColumns/pgrille/" + this.pGrille);
            return response
        },

        filterCellule(column, bloc, row) {
            const filterCellule = this.grilleCellules.filter(
                (item) =>
                    parseInt(item.pGrilleColumns) === parseInt(column) &&
                    parseInt(item.pGrilleBlock) === parseInt(bloc) &&
                    parseInt(item.pGrilleBlockRows) === parseInt(row)
            );
            return filterCellule;
        },
        async indexTablePreview() {
            const responseGrille = await this.getGrilles(this.pGrille);
            this.cGrille = responseGrille.message.cGrille;
            this.cGrilleDesc = responseGrille.message.cGrilleDesc;
            this.cGrilleLangue = responseGrille.message.cGrilleLangue;
            this.cGrilleCompetence = responseGrille.message.cGrilleCompetence;

            const responseColumns = await this.getColumns();
            this.grilleColumns = responseColumns.data;

            const responseBlocs = await this.getBlocs();
            this.grilleBlocs = responseBlocs.message;

            const responseBlocsLignes = await this.getBlocLignes()
            this.grilleBlocsLignes = responseBlocsLignes.message;

            const responseCellules = await this.getCellules();
            this.grilleCellules = responseCellules;

            this.buildIndexTablePreview(this.grilleBlocs, this.grilleBlocsLignes, this.grilleColumns);
        },

        buildIndexTablePreview(grilleBlocs, grilleRows, grilleColumns) {
            this.previewLignes = [];
            let indexCellule = 0;
            let index = 0;
            let blocActif = grilleBlocs.filter((bloc) => parseInt(bloc.bActif) === 1);
            for (let i = 0; i < blocActif.length; i++) {
                let blocRows = grilleRows.filter((row) => parseInt(row.pGrilleBlock) === parseInt(blocActif[i].pGrilleBlock));
                for (let j = 0; j < blocRows.length + 1; j++) {
                    if (j === 0) {
                        this.previewLignes[index] = {
                            typeLigne: "bloc",
                            previewColumns: []
                        };
                    } else {
                        this.previewLignes[index] = {
                            typeLigne: "ligne",
                            previewColumns: []
                        };
                        for (let k = 0; k < grilleColumns.length; k++) {
                            this.previewLignes[index].previewColumns[k] = {
                                indexCelluleStyle: indexCellule,
                                selected: false,
                                pGrilleBlockRowColumns: ""
                            };
                            indexCellule++;
                        }
                    }
                    index++;
                }
            }
            return this.previewLignes;
        },

        changeStyleCellule(event, column, block, row) {
            let cellule = this.filterCellule(column, block, row);

            if (cellule.length > 0) {
                let indexRow = "";
                let indexColumn = "";
                let indexCelluleStyle = "";

                for (let i = 0; i < event.composedPath().length; i++) {
                    let x = event.composedPath()[i];
                    if (x.className === "cellule") {
                        indexColumn = x.cellIndex - 1;
                    } else if (x.className === "rowCellule") {
                        indexRow = x.rowIndex;
                    }
                }
                if (indexRow !== "" && indexColumn !== "") {
                    indexCelluleStyle = this.previewLignes[indexRow].previewColumns[indexColumn].indexCelluleStyle;
                    this.changeColeurCellule(indexRow, indexCelluleStyle);
                    this.modifierPreviewLignes(indexRow, indexColumn, cellule[0]);
                    //this.inactiverRow(indexRow, parseInt(cellule[0].nFiltre));
                }

            }
        },

        changeColeurCellule(indexLigne, indexCelluleStyle) {
            for (let i = 0; i < this.grilleColumns.length; i++) {
                let j = this.previewLignes[indexLigne].previewColumns[i].indexCelluleStyle;
                document.getElementsByClassName("cellule")[j].style.backgroundColor = "#FFFFFF";
            }
            document.getElementsByClassName("cellule")[indexCelluleStyle].style.backgroundColor = "#90CAF9";
        },

        modifierPreviewLignes(indexLigne, indexColumn, cellule) {
            this.previewLignes[indexLigne].previewColumns.forEach(element => {
                element.selected = false;
                element.pGrilleBlockRowColumns = "";
            });
            this.previewLignes[indexLigne].previewColumns[indexColumn].selected = true;
            this.previewLignes[indexLigne].previewColumns[indexColumn].pGrilleBlockRowColumns = cellule.pGrilleBlockRowColumns;
        },

        inactiverRow(indexLigne, filtre) {
            if (filtre === 1 || filtre === 2) {
                this.filtreCellule(indexLigne, filtre);
            } else {
                this.cleanFiltre(indexLigne);
            }
        },

        filtreCellule(indexLigne, filtre) {
            this.cleanFiltre(indexLigne);
            for (let i = indexLigne + 1; i < this.previewLignes.length; i++) {
                if (this.previewLignes[i].typeLigne === "ligne") {
                    this.previewLignes[i].previewColumns.forEach(element => {
                        let c = element.indexCelluleStyle;
                        document.getElementsByClassName("cellule")[c].style.backgroundColor = "#cccccc";
                        document.getElementsByClassName("cellule")[c].style.color = "#666666";
                        document.getElementsByClassName("cellule")[c].style.pointerEvents = "none";
                    });
                } else if (filtre === 1) {
                    break;
                }
            }
        },

        filtreAllCellules() {
            for (let i = 0; i < this.previewLignes.length; i++) {
                if (this.previewLignes[i].typeLigne === "ligne") {
                    this.previewLignes[i].previewColumns.forEach(element => {
                        let c = element.indexCelluleStyle;
                        document.getElementsByClassName("cellule")[c].style.backgroundColor = "#cccccc";
                        document.getElementsByClassName("cellule")[c].style.color = "#666666";
                        document.getElementsByClassName("cellule")[c].style.pointerEvents = "none";
                    });
                }
            }
        },

        cleanFiltre(indexLigne) {
            for (let i = indexLigne + 1; i < this.previewLignes.length; i++) {
                if (this.previewLignes[i].typeLigne === "ligne") {
                    this.previewLignes[i].previewColumns.forEach(element => {
                        let c = element.indexCelluleStyle;
                        element.selected = false;
                        element.pGrilleBlockRowColumns = "";
                        document.getElementsByClassName("cellule")[c].style.backgroundColor = "#FFFFFF";
                        document.getElementsByClassName("cellule")[c].style.color = "#000000";
                        document.getElementsByClassName("cellule")[c].style.pointerEvents = "auto";
                    });
                }
            }
        },

        cleanAllFiltre() {
            for (let i = 0; i < this.previewLignes.length; i++) {
                if (this.previewLignes[i].typeLigne === "ligne") {
                    this.previewLignes[i].previewColumns.forEach(element => {
                        let c = element.indexCelluleStyle;
                        element.selected = false;
                        element.pGrilleBlockRowColumns = "";
                        document.getElementsByClassName("cellule")[c].style.backgroundColor = "#FFFFFF";
                        document.getElementsByClassName("cellule")[c].style.color = "#000000";
                        document.getElementsByClassName("cellule")[c].style.pointerEvents = "auto";
                    });
                }
            }
        },

        changePasEvalue() {
            if (this.grillePasEvalue) {
                this.filtreAllCellules();
            } else {
                this.textGrillePasEvalue = "";
                this.cleanAllFiltre();
            }
        },
        resetGrille() {
            this.grillePasEvalue = false;
            this.textGrillePasEvalue = "";
            this.cleanAllFiltre();
        },

    },
}  