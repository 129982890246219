import BarChart from "@/components/Bar.vue";
import DoughnutChart from "@/components/Doughnut.vue";
import { httpRequest } from '@/services/httpService';

export default {
    components: { BarChart, DoughnutChart },
    name: "Dashboard",
    data() {
        return {
            dialog: false,
            token: null,
            usersData: [],
            grillesData: [],
            resultatsData: [],
            resultatsTypeData: [],
            resultatsTypeDataCompte: [],
            resultatsTypeDataCompteMois: [
                { nMois: 'Janvier', mois:1, compte: 0 },
                { nMois: 'Février', mois:2, compte: 0 },
                { nMois: 'Mars', mois:3, compte: 0 },
                { nMois: 'Avril', mois:4, compte: 0 },
                { nMois: 'Mai', mois:5, compte: 0 },
                { nMois: 'Juin', mois:6, compte: 0 },
                { nMois: 'Juillet', mois:7, compte: 0 },
                { nMois: 'Août', mois:8, compte: 0 },
                { nMois: 'Septembre', mois:9, compte: 0 },
                { nMois: 'Octobre', mois:10, compte: 0 },
                { nMois: 'Novembre', mois:11, compte: 0 },
                { nMois: 'Décembre', mois:12, compte: 0 },
            ],
            echantillonsData: [],
            year: '',
            isloading: false,
            itemsPerPage: 10,
            resultatTopCorrecteurs: [],
            headersTopCorrecteurs: [
                { text: 'Nom', value: 'correcteur' },
                { text: 'Langue', value: 'langue' },
                { text: 'Nombre de grilles corrigées', value: 'total' },
            ],
            headersEchantillons: [
                { text: 'Langue', value: 'cEchantillonLangue' },
                { text: 'Compétence', value: 'cEchantillonCompetence' },
                { text: 'Total', value: 'total' },
            ],
            echantillonsDataTotal: [],
        };
    },
    async created() {
        // vuex store getRole 
        //const userRoles = await this.$store.getters.getRole;    
        const userRoles = localStorage.getItem("user_roles");
        const userRolesData = JSON.parse(userRoles);
        // userRolesData include 1, 2 or 3
        if (!userRolesData[0].pRoles.includes(1 || 2 || 3) ) {
            this.$router.push("/attention");
        } else { 
            this.getUsers();
            this.getGrilles();
            this.getResultats();
            this.getResultatsType();
            this.getResultatsTypeCompte();
            this.getEchantillons();
            this.getResultatsTypeCompteMois();
            this.year = this.printYear();
            this.getTopCorrecteurs();
            this.getEchantillonsTotal();
        }
    },
    methods: {
        async getUsers() {
            let response = await httpRequest('/users');
            this.usersData = response.data;
        },
        async getGrilles() {
            let response = await httpRequest('/grilles');
            this.grillesData = response.data;
        },
        async getResultats() {
            let response = await httpRequest('/grilleResultats');
            this.resultatsData = response.data;
        },
        compteResultat() {
            return (this.resultatsData.filter(resultat => resultat.cType != 'echantillon')).length;
        },

        async getResultatsType() {
            let response = await httpRequest('/grilleResultats/indexResultats');
            this.resultatsTypeData = response.data;
        },

        async getResultatsTypeCompte() {
            let response = await httpRequest('/grilleResultats/compteResultats');
            this.resultatsTypeDataCompte = response.data;
        },
        async getEchantillons() {
            let response = await httpRequest('/echantillons');
            this.echantillonsData = response.data;
        },
        async getResultatsTypeCompteMois() {
            let response = await httpRequest('/grilleResultats/compteMoisResultats');
            if(response.data.length > 0) {
                response.data.forEach(element => {
                    this.resultatsTypeDataCompteMois.forEach(mois => {
                        if(mois.mois == parseInt(element.mois)) {
                            mois.compte = element.compte;
                        }
                    });
                });
            }
        },
        printYear: function () {
            return new Date().getFullYear();
        },

        async getTopCorrecteurs() {
            let response = await httpRequest('/grilleResultats/indexBypCorrecteurpUsers');
            this.resultatTopCorrecteurs = response.data;
        },

        async getEchantillonsTotal() {
            let response = await httpRequest('/echantillons/indexEchantillonLangue');
            this.echantillonsDataTotal = response.data;
        }
    },

};