<template>
  <v-snackbar v-model="snackbar" :timeout="timeout" :color="colorSnackbar">
    {{ textSnackbar }}
    <template v-slot:action="{ attrs }">
       <v-btn :color="colorBtnSnackbar" text v-bind="attrs" @click="snackbar == false">
        Close
      </v-btn> 
    </template>
  </v-snackbar>
</template>

<script>
export default {
  name: "SnackMessage",
  props: {
    snackbar: {
      type: Boolean,
      default: false,
    },
    timeout: {
      type: Number,
      default: 2000,
    },
    colorSnackbar: {
      type: String,
      default: "success",
    },
    colorBtnSnackbar: {
      type: String,
      default: "white",
    },
    textSnackbar: {
      type: String,
      default: "Lorem ipsum dolor sit amet, consectetur adipiscing elit.",
    },
  },
  created(){       
  },
  methods: {
    closeEmit() {
      this.$emit("close");
    },
  },
  
};
</script>
