import { httpPost } from "@/services/httpService";
export default {
    name: "Columns",
    props: {
        grille: {
            type: Object,
            required: true,
        },
        grilleColumns: {
            type: Array,
            default: function () {
                return [];
            },
        },
    },
    data() {
        return {
            nTtitle: "",
            invalid: false,
        };
    },
    methods: {
        async AddOtherColumn() {
            ///grilleColumns/create
            console.log(FormData);
            const data = {
                cGrilleColumns: this.nTtitle,
                nValue: 0,
                pGrille: this.grille.pGrille,
            };
            const result = await httpPost("/grilleColumns/create", data);
            console.log(result);
            this.$emit("refresh");
            this.clearForm();
        },
        saveColumns() {
            this.$emit('Next');
        },
        async deleteItemColumn(index) {
            console.info(index);
            await httpPost("/grilleColumns/delete", { pGrilleColumns: index.pGrilleColumns });
            this.$emit("refresh");
        },
        back() {
            this.$emit("back");
        },
        clearForm() {
            this.nTtitle = "";
            this.$refs.observer.reset();
        }

    },
};