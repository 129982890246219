export default {
    title: 'PasswordRecovery',
    created() {
        this.user = this.$route.params.user;
        console.log(this.user);
    },
    data() {
        return {
            test: 'test',
        loading: false,
        user: '',
        password: '',
        confirmPassword: '',
        passwordError: '',
        }
    },
    methods: {
        send() {
            console.log(this.user);
            console.log(this.password);
            console.log(this.confirmPassword);
            if (this.password != this.confirmPassword) {
                this.passwordError = "Les mots de passe ne correspondent pas";
            }
            else {
                this.loading = true;
                this.$store.dispatch('user/updatePassword', {
                    user: this.user,
                    password: this.password,
                }).then(() => {
                    this.loading = false;
                    this.$router.push('/login');
                }).catch(() => {
                    this.loading = false;
                });
            }
        }
    },
}