//import { email, required } from "vee-validate/dist/rules";
//import { extend, ValidationObserver, ValidationProvider } from "vee-validate";
import { httpRequest, httpPost } from "@/services/httpService";

export default {
    name: "Profile",
    data() {
        return {
            user: [],
            nomComplet: "",
            reset: false,
            listDepartements: [],
            listGroupes: [],
            form: {
                cPrenom: "",
                cNom: "",
                cCourriel: "",
                departement: "",
                groupe: "",
                cPassword: "",
                cNouveauPassword: "",
                cConfirmationPassword: "",
            },
            show1: false,
            show2: false,
            rules: {
                required: value => !!value || 'Required.',
                min: v => v.length >= 8 || 'Min 8 characters',
                emailMatch: () => (`The email and password you entered don't match`),
            },
            okNewPass: false,
            messageNewPass: "",
            cur_pass: false,
        };
    },
    created() {
        this.getDepartement();
        this.getAllGroupes();
        this.getUser();
    },
    methods: {
        getUser() {
            const user = localStorage.getItem("user");
            if (user) {
                const userData = JSON.parse(user);
                this.nomComplet = userData.cPrenom + " " + userData.cNom;
                const courriel = userData.cCourriel;
                this.form.cCourriel = courriel;
                this.searchUser(courriel);
            }
        },
        async searchUser(courriel) {
            const respons = await httpPost('/users/search', { 'cCourriel': courriel });
            console.log('respons', respons);
            if(respons.status) {
                this.form.cPrenom = respons.user.cPrenom;
                this.form.cNom = respons.user.cNom;
                this.form.departement = parseInt(respons.user.pDepartement);
                this.form.groupe = parseInt(respons.user.pGroupe);
                this.user = parseInt(respons.user.pUser);
            }
        },
        getDepartement() {
            httpRequest("/departements").then((response) => {
                this.listDepartements = response.data;
            });
        },
        getAllGroupes() {
            httpRequest("/groupes").then((response) => {
                this.listGroupes = response.data;
            });
        },
        getGroupes() {
            httpRequest("/groupes/dept/" + this.form.departement).then((response) => {
                this.listGroupes = response.data;
            });
        },
        async updateUser() {
            let vld = await this.validateNewPassword();
            this.okNewPass = vld.validation;
            this.messageNewPass = vld.message;
            let response_error = false;
            let response_message = "";

            if (!this.okNewPass) {
                this.$swal({
                    title: "Erreur",
                    text: this.messageNewPass,
                    type: "error",
                    confirmButtonText: "Ok",
                    icon: "error",
                });
                return;
            }

            const response = await httpPost("/users/update", {
              pUser: this.user,
              cNom: this.form.cNom,
              cPrenom: this.form.cPrenom,
              cCourriel: this.form.cCourriel,
              pGroupe: this.form.groupe,
              bActif: true,
              cPassword: this.form.cNouveauPassword,
            }).catch(function (error) {
              if (error.response.status == 403) {
                response_error = true;
                response_message = error.response.data.message;
              } else if (error.response.status == 500) {
                response_error = true;
                response_message = "Erreur lors de l'envoi du formulaire";
              }
            });

            if (response_error) {
              this.$swal({
                title: "Erreur",
                text: response_message,
                type: "error",
                confirmButtonText: "Ok",
                icon: "error",
              });
              return;
            }

            if (response.status === "success") {
              this.$swal({
                title: "Succès",
                text: "Utilisateur modifié avec succès",
                type: "success",
                icon: "success",
                confirmButtonText: "Ok",
              });
              this.clearForm();
            }
        },
        validateNewPassword() {
            return new Promise((resolve) => {
                this.cur_pass = false;
                let validation = true;
                let message = "";
                this.validateCurPass().then(() => {
                    if (this.form.cPassword) {
                        if (this.cur_pass) {
                            if (this.form.cNouveauPassword) {
                                if (this.form.cNouveauPassword == this.form.cConfirmationPassword) {
                                    validation = true;
                                } else {
                                    message = "Les mots de passe ne correspondent pas";
                                    validation = false;
                                }
                            } else {
                                message = "Indiquez le mot de passe à changer";
                                validation = false;
                            }
                        } else {
                            message = "Mauvais mot de passe actuel";
                            validation = false;
                        }

                    }
                    resolve({ 'validation': validation, 'message': message });
                })
            })
        },
        async validateCurPass() {
            const response = await httpPost("/users/checkPassword", {
                pUser: this.user,
                cPassword: this.form.cPassword,
            });
            if (response.success) {
                this.cur_pass = true;
            }
        },
        clearForm() {
            this.form.cPassword = "";
            this.form.cNouveauPassword = "";
            this.form.cConfirmationPassword = "";
            this.$refs.form.resetValidation();
        },
    },
};