import { httpGet } from '@/services/httpService';

export default {
    name: 'ListeGrillesNew',
    created () {
        this.getGrilles();

        const userRoles = localStorage.getItem("user_roles");
        const userRolesData = JSON.parse(userRoles);
        userRolesData.forEach((rol_obj) => {
        const roles_id = rol_obj.pRoles.replace(/\[|\]/g, "").split(",");
            roles_id.forEach((rol_id) => {
                this.user_roles.push(rol_id);
            });
        });
        this.show_btn = this.user_roles.includes("1") || this.user_roles.includes("2");
    },
    data() {
        return {
            headers: [
                { text: 'Grille', value: 'cGrille' },
                { text: 'Langue', value: 'cGrilleLangue' },
                { text: 'Compétence', value: 'cGrilleCompetence' },
                { text: 'Actions', value: 'action', sortable: false, align: 'center' },
            ],
            grilles: [],
            search: '',
            isloading: false,
            itemsPerPage: 10,
            user_roles: [],
            show_btn: true,
        };
    },
    methods: {
        createGrille(){
            this.$router.push('/creategrille');
        },
        async getGrilles(){
            const result = await httpGet('/grilles');
            this.grilles = result;
        },
        editGrille (id){
            this.$router.push('/creategrille?id=' + id);

        },
        Preview (id){
            //this.$router.push('/previewPage/' + id +'/1');
            this.$router.push('/creategrille?id=' + id + '&preview=true');
        },
    }
}