var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('v-dialog',{attrs:{"persistent":"","max-width":"600px"},model:{value:(_vm.dialogCreateAppInterface),callback:function ($$v) {_vm.dialogCreateAppInterface=$$v},expression:"dialogCreateAppInterface"}},[_c('v-card',{staticClass:"mx-auto"},[_c('v-card-title',{staticClass:"primary"},[_c('span',{staticClass:"text-h5 white--text"},[_vm._v(" "+_vm._s(_vm.typeAction === "create" ? "Nouvelle " : "Modifier l'")+"interface "),_c('br'),(_vm.typeAction === 'update')?_c('strong',[_vm._v(" "+_vm._s(_vm.formInterface.nom)+" ")]):_vm._e()])]),_c('v-card-text',[_c('v-container',[_c('validation-observer',{ref:"observer",scopedSlots:_vm._u([{key:"default",fn:function(ref){
var invalid = ref.invalid;
return [_c('v-form',{ref:"formInterface",attrs:{"lazy-validation":""},model:{value:(_vm.valid),callback:function ($$v) {_vm.valid=$$v},expression:"valid"}},[_c('validation-provider',{attrs:{"name":"text","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-select',{attrs:{"items":_vm.langues,"error-messages":errors,"item-text":"cLangue","item-value":"cLangue","label":"Nom de l'interface","required":""},model:{value:(_vm.formInterface.nom),callback:function ($$v) {_vm.$set(_vm.formInterface, "nom", $$v)},expression:"formInterface.nom"}})]}}],null,true)}),_c('validation-provider',{attrs:{"name":"text","rules":"url"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{attrs:{"type":"text","label":"URL","hint":"Saisissez l'URL de l'interface","error-messages":errors,"required":""},model:{value:(_vm.formInterface.url),callback:function ($$v) {_vm.$set(_vm.formInterface, "url", $$v)},expression:"formInterface.url"}})]}}],null,true)}),_c('v-text-field',{attrs:{"type":"text","label":"Détails de l'interface"},model:{value:(_vm.formInterface.description),callback:function ($$v) {_vm.$set(_vm.formInterface, "description", $$v)},expression:"formInterface.description"}}),_c('validation-provider',{attrs:{"name":"text","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-select',{attrs:{"items":_vm.typeInterfaces,"error-messages":errors,"item-text":"cAppTypeInterface","item-value":"cAppTypeInterface","label":"Type d'interface","persistent-hint":"","single-line":"","required":""},model:{value:(_vm.formInterface.type),callback:function ($$v) {_vm.$set(_vm.formInterface, "type", $$v)},expression:"formInterface.type"}})]}}],null,true)}),_c('v-switch',{attrs:{"label":"Actif","hint":"Activer ou désactiver l'interface"},model:{value:(_vm.formInterface.bActif),callback:function ($$v) {_vm.$set(_vm.formInterface, "bActif", $$v)},expression:"formInterface.bActif"}}),_c('v-row',[_c('v-col',[_c('v-btn',{staticClass:"mr-4 mt-5",attrs:{"color":"error"},on:{"click":_vm.closeDialogCreateInterface}},[_vm._v(" Annuler ")]),_c('v-btn',{staticClass:"mt-5 mr-4 white--text",attrs:{"disabled":invalid,"color":"primary"},on:{"click":_vm.createUpdateInterface}},[_vm._v(" "+_vm._s(_vm.typeAction == "create" ? "Enregistrer" : "Modifier")+" ")])],1)],1)],1)]}}])})],1)],1)],1)],1),_c('v-card',{attrs:{"outlined":""}},[_c('v-card-title',[_c('span',{staticClass:"headline"},[_vm._v("Interfaces applications")]),_c('v-divider',{staticClass:"mx-4",attrs:{"inset":"","vertical":""}}),_c('v-btn',_vm._g({staticClass:"ml-2",attrs:{"color":"primary","dark":""},on:{"click":function($event){return _vm.openDialogCreateInterface({}, 'create')}}},_vm.on),[_c('v-icon',{staticClass:"mr-2"},[_vm._v(" mdi-plus-thick ")]),_vm._v(" Nouvelle interface ")],1),_c('v-spacer'),_c('v-text-field',{attrs:{"append-icon":"mdi-magnify","label":"Recherche","single-line":"","hide-details":""},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}})],1),_c('v-data-table',{staticClass:"outlined",attrs:{"headers":_vm.headers,"items":_vm.interfaces,"page":1,"items-per-page":_vm.itemsPerPage,"search":_vm.search,"loading":_vm.isloading,"loading-text":"Chargement... Veuillez patienter","footer-props":{
        'items-per-page-text': 'Lignes par page',
        'items-per-page-all-text': 'Tout',
      }},scopedSlots:_vm._u([{key:"item",fn:function(ref){
      var item = ref.item;
return [_c('tr',[_c('td',[_vm._v(" "+_vm._s(item.cAppInterface)+" "),(!item.bActif)?_c('span',{staticStyle:{"color":"red","margin-left":"1.25em"}},[_vm._v(" (inactif) ")]):_vm._e()]),_c('td',[_c('a',{attrs:{"target":"_blank","href":item.cURL}},[_vm._v(_vm._s(item.cURL)+" ")])]),_c('td',[_vm._v(" "+_vm._s(item.cType)+" ")]),_c('td',{staticClass:"d-flex justify-center d-flex align-center"},[_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
      var on = ref.on;
return [_c('v-btn',_vm._g({staticClass:"mr-2",attrs:{"text":"","small":""},on:{"click":function($event){return _vm.openDialogUpdateInterface(item, 'update')}}},on),[_c('v-icon',[_vm._v("mdi-pencil")])],1)]}}],null,true)},[_c('span',[_vm._v("Modifier l'interface")])])],1)])]}},{key:"no-results",fn:function(){return [_vm._v(" Aucun résultat! ")]},proxy:true},{key:"no-data",fn:function(){return [_vm._v(" Pas de données à afficher! ")]},proxy:true}])})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }