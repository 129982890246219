var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-card',{attrs:{"outlined":""}},[_c('v-card-title',[_c('span',{staticClass:"headline"},[_vm._v("Liste d'utilisateurs")]),_c('v-divider',{staticClass:"mx-4",attrs:{"inset":"","vertical":""}}),_c('v-btn',{staticClass:"ml-2",attrs:{"color":"primary","dark":""},on:{"click":function($event){return _vm.createEditUser('create', {})}}},[_c('v-icon',{staticClass:"mr-2"},[_vm._v(" mdi-account-plus ")]),_vm._v(" Nouvel utilisateur ")],1),_c('v-spacer'),_c('v-text-field',{attrs:{"append-icon":"mdi-magnify","label":"Recherche","single-line":"","hide-details":""},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}})],1),_c('v-data-table',{staticClass:"outlined",attrs:{"headers":_vm.headers,"items":_vm.usersData,"page":1,"items-per-page":_vm.itemsPerPage,"search":_vm.search,"loading":_vm.isloading,"loading-text":"Chargement... Veuillez patienter","footer-props":{
      'items-per-page-text': 'Lignes par page',
      'items-per-page-all-text': 'Tout',
    }},scopedSlots:_vm._u([{key:"item",fn:function(ref){
    var item = ref.item;
return [_c('tr',[_c('td',[_vm._v(" "+_vm._s(item.cNomComplet)+" "),(item.bActif == 0)?_c('span',{staticStyle:{"color":"red","margin-left":"1.25em"}},[_vm._v(" (inactif) ")]):_vm._e()]),_c('td',[_vm._v(_vm._s(item.cCourriel))]),_c('td',{staticClass:"d-flex justify-center d-flex align-center"},[_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
    var on = ref.on;
return [_c('v-btn',_vm._g({staticClass:"mr-2",attrs:{"text":"","small":""},on:{"click":function($event){return _vm.createEditUser('update', item)}}},on),[_c('v-icon',[_vm._v("mdi-pencil")])],1)]}}],null,true)},[_c('span',[_vm._v("Modifier l'utilisateur")])]),_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
    var on = ref.on;
return [_c('v-btn',_vm._g({staticClass:"mr-2",attrs:{"text":"","small":""},on:{"click":function($event){return _vm.editRole(item)}}},on),[_c('v-icon',[_vm._v("mdi-badge-account-alert-outline")])],1)]}}],null,true)},[_c('span',[_vm._v("Ajouter ou modifier des rôles l’utilisateur")])]),(item.pInterface == 1)?_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
    var on = ref.on;
return [_c('v-btn',_vm._g({staticClass:"mr-2",attrs:{"text":"","small":""},on:{"click":function($event){return _vm.linkUtilisateur(item)}}},on),[_c('v-icon',[_vm._v("mdi-link-variant")])],1)]}}],null,true)},[_c('span',[_vm._v("L’utilisateur est joint à une interface")])]):_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
    var on = ref.on;
return [_c('v-btn',_vm._g({staticClass:"mr-2",attrs:{"text":"","small":""},on:{"click":function($event){return _vm.linkUtilisateur(item)}}},on),[_c('v-icon',[_vm._v("mdi-link-variant-plus")])],1)]}}],null,true)},[_c('span',[_vm._v("Joindre l'utilisateur à une interface")])])],1)])]}},{key:"no-results",fn:function(){return [_vm._v(" Aucun résultat! ")]},proxy:true},{key:"no-data",fn:function(){return [_vm._v(" Pas de données à afficher! ")]},proxy:true}])}),_c('modalInterface',{attrs:{"interfaces":_vm.listInterface,"user":_vm.userEdit},on:{"refresh":_vm.getUsers}}),_c('modalRoles',{attrs:{"roles":_vm.roles,"user":_vm.userEdit}}),_c('createUserComponent',{attrs:{"typeAction":_vm.typeAction,"user":_vm.userEdit},on:{"refresh":_vm.getUsers}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }