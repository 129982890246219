import { email, required } from "vee-validate/dist/rules";
import { extend, ValidationObserver, ValidationProvider } from "vee-validate";
import { httpRequest, httpPost, httpGet } from "@/services/httpService";

extend("email", {
  ...email,
  message: "Veuillez entrer un courriel valide",
});

extend("required", {
  ...required,
  message: "{_field_} ce champ est requis",
});

export default {
  name: "createUserComponent",
  props: {
    user: {},
    typeAction: "",
  },
  components: {
    ValidationProvider,
    ValidationObserver,
  },
  created() {
    this.getDepartement();
  },

  data() {
    return {
      info: "",
      valid: false,
      reset: false,
      listDepartements: [],
      listGroupes: [],
      loading: false,
      form: {
        cPrenom: "",
        cNom: "",
        pDepartement: "",
        pGroupe: "",
        cCourriel: "",
        bActif: false,
        pUser: "",
      },
      error: {
        name: false,
        email: false,
        password: false,
        password_confirmation: false,
        active: false,
      },
    };
  },
  watch: {
    user: function (newVal, oldval) {
      console.log("Change user - oldval - newval", oldval, newVal);
      if (this.typeAction === "update") {
        this.groupebyDepartment(
          parseInt(newVal.pDepartement),
          parseInt(newVal.pGroupe)
        );
        this.form = newVal;
      }
    },
  },

  methods: {
    getDepartement() {
      httpRequest("/departements").then((response) => {
        this.listDepartements = response.data;
      });
    },

    getGroupes() {
      httpRequest("/groupes/dept/" + parseInt(this.form.pDepartement)).then(
        (response) => {
          this.listGroupes = response.data;
        }
      );
    },

    async clearForm() {
      //this.$refs.form.reset();
      this.form.cPrenom = "";
      this.form.cNom = "";
      this.form.cCourriel = "";
      this.form.pDepartement = "";
      this.form.pGroupe = "";
      this.form.bActif = false;
      this.$refs.observer.reset();

      this.$emit("refresh");
      this.$store.state.modalCreate.show = false;
    },
    createUpdateUser() {
      if (this.typeAction === "create") {
        this.searchUser();
      } else {
        this.updateUser(this.form.pUser);
      }
    },

    async searchUser() {
      const respons = await httpPost("/users/search", {
        cCourriel: this.form.cCourriel,
      });
      if (!respons.status) {
        this.register();
      } else {
        this.$swal({
          position: "bottom",
          icon: "warning",
          title: "Cet utilisateur existe déjà",
          showConfirmButton: false,
          timer: 2500,
        });
      }
    },

    async register() {
      let bActifN = 0;
      if(this.form.bActif) {
        console.log("Activo");
        bActifN = 1;
      } 
      await httpPost("/users/register", {
        cNom: this.form.cNom,
        cPrenom: this.form.cPrenom,
        pDepartement: this.form.pDepartement,
        pGroupe: this.form.pGroupe,
        cCourriel: this.form.cCourriel,
        cPassword: "12345",
        bActif: bActifN,
      }).then((response) => {
        if (response.status === "success") {
          this.$swal({
            title: "Succès",
            text: "Vous êtes inscrits avec succès",
            type: "success",
            icon: "success",
            confirmButtonText: "Ok",
          });
        }
        this.clearForm();
      });
    },

    async updateUser(pUser) {
      let bActifN = 0;
      if(this.form.bActif) {
        console.log("Activo");
        bActifN = 1;
      } 

      const response = await httpPost("/users/update", {
        pUser: pUser,
        cNom: this.form.cNom,
        cPrenom: this.form.cPrenom,
        cCourriel: this.form.cCourriel,
        pDepartement: this.form.pDepartement,
        pGroupe: this.form.pGroupe,
        bActif: bActifN,
      });
      if (response.status === "success") {
        this.$swal({
          title: "Succès",
          text: "Utilisateur modifié avec succès",
          type: "success",
          icon: "success",
          confirmButtonText: "Ok",
        });
        this.clearForm();
      }
    },

    async resetPassword() {
      if (this.form.pUser !== "") {
        const response = await httpPost("/users/updatePassword", {
          pUser: this.form.pUser,
          cPassword: "12345",
        });
        if (response.status === "success") {
          this.$swal({
            title: "Succès",
            text: "Mot de passe réinitialisé avec succès",
            type: "success",
            icon: "success",
            confirmButtonText: "Ok",
          });
          this.clearForm();
        }
      }
    },

    async groupebyDepartment(pDepartement, pGroupe) {
      if (pDepartement) {
        const respons = await httpGet("/groupes/dept/" + pDepartement);
        this.listGroupes = respons;
        this.form.pDepartement = parseInt(respons[0].pDepartement);
        //this.form.pGroupe = parseInt(respons[0].pGroupe);
        this.form.pGroupe = pGroupe;
        this.$forceUpdate();
      }
    },
  },
};
