import axios  from "axios";

const getToken = () => {
    if(localStorage.getItem('token')) {
      // commit('setToken', localStorage.getItem('token'))
    } else {
      // commit('setToken', null)
    }
  }

  //set token
    const setToken = (token) => {
    localStorage.setItem('token', token)
    // commit('setToken', token)
    }

const loginApi = (model) => {
    try {        
        
        return axios.post(process.env.VUE_APP_API_URL +'/login', model);
    } catch (error) {
        return error;
    }
}

const registerApi = async (model) => {
    try {
        
        const response = await axios.post(process.env.VUE_APP_API_URL +'/register', model);
        return response;
    } catch (error) {
        return error;
    }
}

export {getToken, loginApi, registerApi, setToken};