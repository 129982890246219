import axios from 'axios';
import { email, required } from 'vee-validate/dist/rules'
import { extend, ValidationObserver, ValidationProvider } from 'vee-validate'
import { httpGet, httpPost } from '../../../../../services/httpService';

extend('email', {
    ...email,
    message: 'Veuillez entrer un courriel valide',
  })

  extend('required', {
    ...required,
    message: 'Ce champ est requis',
  })

export default {
    name: "modifyuser",  
    
    components: {
        ValidationProvider,
        ValidationObserver,
      },

    // create component
    async created() {
        this.getDepartement();
        this.getUser(this.$route.params.user);
    },

    // données du composant
    data() {
        return {
            info: '',
            valid: false,
            reset: false,
            listDepartements: [],
            listGroupes: [],
            form: {
                name: '',
                lastName: '',
                departement:'',
                groupe:'',
                email: '',
                password: '',
                password_confirmation: '',
                active: false
            },
            error: {
                name: false,
                email: false,
                password: false,
                password_confirmation: false,
                active: false
            },
            rules: {
                

            },
            loading: false
        }
    },

    methods: {
        async register() {
            // axios get request
            
            const response = await httpPost('/users/update',
                {
                    pUser: this.$route.params.user,
                    cNom: this.form.lastName,
                    cPrenom: this.form.name,
                    pGroupe: this.form.groupe,
                    cCourriel: this.form.email,                    
                    bActif: this.form.active
                })
               if (response.status == 'success') {
                   this.$swal({
                        title: 'Succès',
                        text: 'Utilisateur modifié avec succès',
                        type: 'success',
                        icon: 'success',
                        confirmButtonText: 'Ok'
                    })
                    this.$router.push('/users');
                }
  
        },
        validateUQAM() {
            console.log('validateUQAM');
            //axios get request
            axios.get('https://apipersonnes.dev.uqam.ca/v1/personnes?mail=' + this.form.email)
            .then(response => (this.info = response))
           
        },
        async getDepartement() {
            const response = await httpGet('/departements');
            this.listDepartements = response;
            this.$forceUpdate();
        },
        async getGroupes() {
            const groupes = await this.groupebyDepartment(this.form.departement);
            this.listGroupes = groupes.data;           
        },
        // get departement by groupe
        async groupebyDepartment(id) {
            const respons = await httpGet ('/groupes/dept/'+id);            
            this.listGroupes = respons;
            this.form.departement = respons[0].pDepartement;
            this.form.groupe = respons[0].pGroupe;
            this.$forceUpdate();
        },
        // get user by id
        async getUser(id) {
            const response = await httpGet ('/users/'+id);
            console.log(response.result);
            this.form.name = response.result.cPrenom;
            this.form.lastName = response.result.cNom;
            this.form.email = response.result.cCourriel;                                    
            this.form.active = response.result.bActif;
            if (response.result.pGroupe != null) {
                await this.groupebyDepartment(response.result.pGroupe);
                this.form.groupe = response.result.pGroupe;                
            }
                      
        },

    },
  };