<template>
  <div>
    <img src="../../public/img/Error500.png" height="200" width="400"/>
  </div>
</template>

<script>
/**
 * Error500 Component
 * Guillermo guzman 24-02-2022
 * @version 1.0.0
 */
export default {
  name: "Error500",
  data() {
    return {
      msg: "Error 500",
    };
  },
};
</script>
