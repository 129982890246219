<template>
<div>
    <h2>Coorecteur1: {{ correcteur1 }}  correcteur2: {{ correcteur2 }} Value: {{ value }}</h2>
    <v-card v-if="domaine == 'Writing'">
        <h1>writing</h1>
    </v-card>
    <v-card v-if="domaine == 'Speaking'">
        <h1>speaking</h1>
    </v-card>
    <v-card v-if="domaine == 'Reading'">
        <h1>reading</h1>
    </v-card>
    </div>
</template>
<script >
export default {
    name: "Interface",
    created() {
      const par =  this.$route.params;
      console.log(par);
      this.domaine= par.domaine;
      this.correcteur1= par.correcteur1;
        this.correcteur2= par.correcteur2;
        this.value= par.value;

    },
    data() {
        return {
            value: '',
            domaine: '',
            correcteur1: '',
            correcteur2: '',
            
        }
    },
    methods: {
        //
    }
}

</script>