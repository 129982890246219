import { required } from 'vee-validate/dist/rules';
/* import { extend, ValidationObserver, ValidationProvider } from 'vee-validate' */
import { extend } from 'vee-validate'
import { httpRequest } from '@/services/httpService';

extend('required', {
    ...required,
    message: 'Ce champ est requis',
});

export default {
    name: 'createRapportComponent',
    props: {
        dialogCreateRapport: {
            type: Boolean,
            default: '',
        },
        typeAction: {
            type: String,
        },
    },
/*     components: {
        ValidationProvider,
        ValidationObserver
    }, */
    data() {
        return {
            listTables: [],
            listColumns: [],
            listOperators: ['=', '<', '>', '<=', '>=', '<>', 'LIKE', 'NOT LIKE'],
            selectedTable: "",
            selectedColonnes: "",
            selectedCondition: "",
            selectedOperator: "",
            selectedValue: "",
            valid: true,
        };
    },

    methods: {
        async getTables() {
            this.isloading = true;

            httpRequest('/admTables/getTables')
                .then(response => {
                    this.listTables = response.data.tables;
                    this.isloading = false;
                })
        },
        async getColumns() {
            this.isloading = true;
            httpRequest(`/admTables/getColumns/${this.selectedTable}`)
                .then(response => {
                    console.log('datos = ', response.data.columns);
                    this.listColumns = response.data.columns;
                    this.isloading = false;
                })
        }
    },
} 