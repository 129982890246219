var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-card',{attrs:{"outlined":""}},[_c('v-card-title',[_c('span',{staticClass:"headline"},[_vm._v("Query builder")]),_c('v-divider',{staticClass:"mx-4",attrs:{"inset":"","vertical":""}}),_c('v-btn',_vm._g({staticClass:"ml-2",attrs:{"color":"primary","dark":""},on:{"click":function($event){return _vm.openDialogCreateRapport({}, 'create')}}},_vm.on),[_c('v-icon',{staticClass:"mr-2"},[_vm._v(" mdi-plus-thick ")]),_vm._v(" Nouveau rapport ")],1),_c('v-spacer'),_c('v-text-field',{attrs:{"append-icon":"mdi-magnify","label":"Recherche","single-line":"","hide-details":""},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}})],1),_c('v-data-table',{staticClass:"elevation-1",attrs:{"headers":_vm.headers,"items":_vm.listQuery,"search":_vm.search,"page":1,"items-per-page":_vm.itemsPerPage,"loading":_vm.isloading,"loading-text":"Chargement... Veuillez patienter","footer-props":{
      'items-per-page-text': 'Lignes par page',
      'items-per-page-all-text': 'Tout',
    }},scopedSlots:_vm._u([{key:"item",fn:function(ref){
    var item = ref.item;
return [_c('tr',[_c('td',[_vm._v(" "+_vm._s(item.cQueryBuilder)+" "),(!item.bActif)?_c('span',{staticStyle:{"color":"red","margin-left":"1.25em"}},[_vm._v(" (inactif) ")]):_vm._e()]),_c('td',{staticClass:"d-flex justify-center d-flex align-center"},[_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
    var on = ref.on;
return [_c('v-btn',_vm._g({staticClass:"mr-2",attrs:{"text":"","small":""}},on),[_c('v-icon',[_vm._v("mdi-pencil")])],1)]}}],null,true)},[_c('span',[_vm._v("Modifier la requête")])]),_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
    var on = ref.on;
return [_c('v-btn',_vm._g({staticClass:"mr-2",attrs:{"text":"","small":""}},on),[_c('v-icon',[_vm._v("mdi-play-circle-outline")])],1)]}}],null,true)},[_c('span',[_vm._v("Exécuter la requête")])])],1)])]}}])}),_c('create-rapportComponent',{attrs:{"dialogCreateRapport":_vm.dialogCreateRapport,"formRapport":_vm.itemSelected,"typeAction":_vm.typeActionRapport},on:{"closeDialogCreateRapport":function($event){_vm.dialogCreateRapport = false}}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }