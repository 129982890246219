<template>
  <v-app>
    <router-view />
    <snackbar :snackbar="this.$store.showSnack"></snackbar>
  </v-app>
</template>

<script>
import snackbar from "@/components/SnackMessage.vue";

export default {
  name: "App",
  components: {
    snackbar,
  },
  data() {
    return {};
  },
};
</script>
