// vue component
export default {
    name: 'alertModal',
    props: ['modalOpen','modalTitle', 'modalContent'],    
    data() {
        return {
            dialog: false ,
        }
    },
    methods: {
        closeDialog() {
            this.dialog = false;
            // emit modalOpen to parent component equals false
            this.$emit('closeModal', false);
            
        },
    },
    watch: {
        modalOpen: function (val, oldVal) {
          console.log('new: %s, old: %s', val, oldVal)
          this.dialog = val;
        },
    },       
    
}