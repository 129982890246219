// vue component
import axios from 'axios';
import Error500 from '../../../../../components/Error500.vue';
// import { httpPost, httpGet } from '../../../services/HttpService';
import alertModal from '../../../../../components/alert/alertModal.vue';
import Loading from 'vue-loading-overlay';
import 'vue-loading-overlay/dist/vue-loading.css';


export default {
    name: 'Roles',
    components: {
        Error500,
        alertModal,
        Loading
    },
    async created() {
        if (this.$route.params.name != '') {
            this.name = this.$route.params.name;
            this.pUser = this.$route.params.pUser;
            await this.getRoles();
        } else {
            // redirect to Users
            this.$router.push('/users');
        }

    },
    data() {
        return {
            pUser: '',
            name: '',
            isloading: false,
            fullPage: true,
            roles: [],
            modalOpen: false,
            modalContent: '',
            modalTitle: '',
        }
    },
    methods: {
        async getRoles() {
            const response = await axios.get('/api/roles');
            this.roles = await this.getUserRoles(response.data);
        },
        async getUserRoles(roles) {
            const response = await axios.post('/api/userroles/searchuser', { pUser: this.pUser })
            const userRoles = JSON.parse(response.data.pRoles)
            for (const role of roles) {
                const hasRole = userRoles.includes(role.pRoles);
                role.active = hasRole;
            }
            return roles;
        },
        saveRole() {
            let listRoles = [];
            let pUser = this.pUser;
            this.roles.map(rol => {
                if (rol.active === true) {
                    listRoles.push(rol.pRoles);
                }
            })
            // console.log(roles);            
            axios.post('/api/userroles/create', { pUser, listRoles })
                .then(response => {
                    console.log(response);
                    console.log("grabado el rol");
                    this.isloading = false;
                    this.modalOpen = true;
                    this.modalTitle = "Rol";
                    this.modalContent = "Rol grabado correctamente";
                }
                )
                .catch(error => {
                    console.log(error);
                    this.modalOpen = true;
                    this.modalTitle = "Error";
                    this.modalContent = "Error al grabar el rol";
                });
        },
        cancel() {
            this.$router.push('/users');
        },

    },
}