<template>
  <div class="text-center">
    <v-btn
      :disabled="isLoading"
      :loading="isLoading"
      class="white--text"
      color="purple darken-2"
      @click="dialog = true"
    >
      Start loading
    </v-btn>
    <v-dialog
      v-model="isLoading"
      hide-overlay
      persistent
      width="300"
    >
      <v-card
        color="primary"
        dark
      >
        <v-card-text>
          Please stand by
          <v-progress-linear
            indeterminate
            color="white"
            class="mb-0"
          ></v-progress-linear>
        </v-card-text>
      </v-card>
    </v-dialog>
  </div>
</template>
<script >
import { mapGetters } from 'vuex'

  export default {
    data () {
      return {
        isLoading: false,
      }
    },
    //computed: mapGetters isloadding
    computed: {
      ...mapGetters({
        isLoading: 'isLoading'
      })
    },
  
  }

</script>