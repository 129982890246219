import DrawerNavigation from "@/components/drawer/Drawer.vue";

export default {
  name: "Home",
  components: {
    DrawerNavigation,
  },
  data() {
    return {
      clipped: false,
      drawer: true,
      fixed: false,
      userRole: "",
      name: "",
      user_roles: [],
    };
  },
  created() {
    const user = localStorage.getItem("user");
    if (user) {
      const userData = JSON.parse(user);
      this.name = userData.cNom + " " + userData.cPrenom;
    }
    //const userRoles = localStorage.getItem("user_roles");
    //const userRolesData = JSON.parse(userRoles);
    //console.log(userRolesData)

    // Role 1: Administrateur
    // Role 2: Administrateur des grilles
    // Role 3: Modificateur des grilles
    // Role 4: Consultation
    // Role 6: Entraînement
    // Role 7: Réchauffement
    const userRoles = localStorage.getItem("user_roles");
    const userRolesData = JSON.parse(userRoles);
    userRolesData.forEach((rol_obj) => {
      const roles_id = rol_obj.pRoles.replace(/\[|\]/g, "").split(",");
      roles_id.forEach((rol_id) => {
        this.user_roles.push(rol_id);
      });
    });
    if(this.user_roles.includes("6") || this.user_roles.includes("7")){
      this.drawer = false;
    }
  },
  methods: {
    openProfileUtilisateur() {
      this.$router.push("/profile");
    },
  },
};
