import Vue from 'vue';
import Vuetify from 'vuetify/lib/framework';

Vue.use(Vuetify);

const theme = {
    primary: '#0A55A0',
    secondary: '#b78200',
    accent: '#9C27b0',
    info: '#00CAE3',
  }

export default new Vuetify({
    theme: {
        themes: {
          dark: theme,
          light: theme,
        },
      },
});
