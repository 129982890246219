import { httpRequest, httpPost } from '@/services/httpService';

export default {
    name: 'GrilleEchantillonQuestion',
    data() {
        return {
            on: true,
            valid: false,
            isloading: false,
            itemsPerPage: 10,
            grillesEchantillonQuestions: [],
            loading: false,
            search: '',
            headers: [
                { text: 'Langue', value: 'cLangue', sortable: false },
                { text: 'Compétence', value: 'cCompetence', sortable: false },
                { text: 'Question', value: 'cGrilleEchantillonQuestion', sortable: false },
                { text: 'Commentaires', sortable: false },
                { text: 'Actions', value: 'modifier', sortable: false, align: 'center' }
            ],
            dialogCreateGrilleEchantillonQuestion: false,
            form: {
                langue: '',
                competence: '',
                question: '',
                commentaires: '',
                id: '',
            },
            typeAction: '',
            langues: [],
            competences: [],
        }
    },
    created() {
        this.getGrillesEchantillonQuestions();
        this.getLangues();
        this.getCompetences();
    },
    methods: {
        async getGrillesEchantillonQuestions() {
            this.loading = true;
            await httpRequest('/grilleEchantillonQuestions').then(response => {
                this.grillesEchantillonQuestions = response.data;
                this.loading = false;
            })

        },
        getLangues() {
            httpRequest('/langues').then(response => {
                this.langues = response.data;
            })
        },
        getCompetences() {
            httpRequest('/competences').then(response => {
                this.competences = response.data;
            })
        },
        openDialogCreateGrilleEchantillonQuestion(item, typeAction) {
            this.typeAction = typeAction;
            this.dialogCreateGrilleEchantillonQuestion = true;
        },
        openDialogUpdateQuestion(item, typeAction) {
            this.typeAction = typeAction;
            this.form.langue = item.cLangue;
            this.form.competence = item.cCompetence;
            this.form.question = item.cGrilleEchantillonQuestion;
            this.form.commentaires = item.cGrilleEchantillonCommentaires;
            this.form.id = item.pGrilleEchantillonQuestion;
            this.dialogCreateGrilleEchantillonQuestion = true;
        },
        closeDialogCreateQuestion() {
            this.clearForm();
            this.dialogCreateGrilleEchantillonQuestion = false;
        },
        createUpdateQuestion() {
            if (this.typeAction == 'create') {
                this.searchQuestion();
            } else {
                this.updateQuestion();
            }
        },
        async searchQuestion() {
            const response = await httpPost('/grilleEchantillonQuestions/searchLangueCompetenceQuestion', { 'cLangue': this.form.langue, 'cCompetence': this.form.competence, 'cGrilleEchantillonQuestion': this.form.question });
            if (!response.status) {
                this.createQuestion();
            } else {
                this.$swal({
                    title: 'Erreur',
                    text: 'La question existe déjà',
                    icon: 'error',
                    type: 'error',
                    confirmButtonText: 'Ok',
                })
            }
        },
        async createQuestion() {
            await httpPost('/grilleEchantillonQuestions/create',
                {
                    'cLangue': this.form.langue,
                    'cCompetence': this.form.competence,
                    'cGrilleEchantillonQuestion': this.form.question,
                    'cGrilleEchantillonCommentaires': this.form.commentaires,
                });
            this.$swal({
                title: 'Succès',
                text: 'La question a été créée',
                icon: 'success',
                type: 'success',
                confirmButtonText: 'Ok',
            })
            this.getGrillesEchantillonQuestions();
            this.closeDialogCreateQuestion();
        },
        async updateQuestion() {
            await httpPost('/grilleEchantillonQuestions/update',
                {
                    'pGrilleEchantillonQuestion': this.form.id,
                    'cLangue': this.form.langue,
                    'cCompetence': this.form.competence,
                    'cGrilleEchantillonQuestion': this.form.question,
                    'cGrilleEchantillonCommentaires': this.form.commentaires,
                });
            this.$swal({
                title: 'Succès',
                text: 'La question a été modifiée',
                icon: 'success',
                type: 'success',
                confirmButtonText: 'Ok',
            })
            this.getGrillesEchantillonQuestions();
            this.closeDialogCreateQuestion();
        },
        clearForm() {
            this.$refs.observer.reset();
            this.form.langue = '';
            this.form.competence = '';
            this.form.question = '';
            this.form.commentaires = '';
            this.typeAction = '';
        },
    }
}