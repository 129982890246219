import { httpRequestApi } from '../../../services/httpService';
import { getGrilleBlock, getGrilleRows, getGrilleColumns, grilleRowColumns } from '../../../services/grilleService';
import PreviewVue from '../config/Grilles/Preview.vue';
export default {
    name: "Correction",
    components: {
        PreviewVue
    },
    async created() {
        const code = this.$route.params.code;
        this.code = code;
        const competence = this.$route.params.competence;
        this.competence = competence;
        const langue = this.$route.params.langue;
        this.langue = langue;
        //get data correction
        await this.getCorrection(code);
        this.getGrilleBlocks(1);
        this.getGrilleRows(1);
        this.GrilleColumns(1);
        this.getGrilleRowColumns(1);
    },
    data() {
        return {
            code: "",
            competence: "",
            langue: "",
        };
    },
    props: {
        grilleBlocks: {
            type: Array,
            required: true,
        },
        grilleRows: {
            type: Array,
            required: true,
        },
        grilleColumns: {
            type: Array,
            required: true,
        },
        grilleBlockRowsColumns: {
            type: Array,
            required: true,
        },
        correction: {
            type: Array,
            required: false,
        },
    },
    methods: {
        async getCorrection(code) {
            const response = await httpRequestApi(`https://tests-cecl.uqam.ca/espagnol/CMS/interface.php?type=2&code=` + code)
            this.correction = response.data;
            console.log(response.data);
        },
        async getGrilleBlocks(id) {
            console.log("getGrilleBlocks");
            // het http grille
            this.grilleBlocks = await getGrilleBlock(id);
        },
        async getGrilleRows(id) {
            console.log("getGrilleRows");
            this.grilleRows = await getGrilleRows(id);
        },
        async GrilleColumns(id) {
            console.log("getGrilleColumns");
            this.grilleColumns = await getGrilleColumns(id);
        },
        async getGrilleRowColumns(id) {
            console.log("getGrilleBlockRowsColumns");
            this.grilleBlockRowsColumns = await grilleRowColumns(id);
        },
    },
};