import { httpRequest, httpPost } from '@/services/httpService';

export default {
    name: 'Langues',
    data() {
        return {
            on: true,
            valid: false,
            isloading: false,
            itemsPerPage: 10,
            langues: [],
            loading: false,
            search: '',
            headers: [
                { text: 'Langue', value: 'cLangue' },
                { text: 'Actions', value: 'modifier', sortable: false, align: 'center' }
            ],
            dialogCreateLangue: false,
            form: {
                langue: '',
                id: '',
            },
            typeAction: '',
        }
    },
    created() {
        this.getLangues();
    },

    methods: {
        getLangues() {
            this.loading = true;
            httpRequest('/langues').then(response => {
                this.langues = response.data;
                this.loading = false;
            })
        },
        openDialogCreateLangue(item, typeAction) {
            this.typeAction = typeAction;
            this.dialogCreateLangue = true;
        },
        openDialogUpdateLangue(item, typeAction) {
            this.typeAction = typeAction;
            this.form.langue = item.cLangue;
            this.form.id = item.pLangue;
            this.dialogCreateLangue = true;
        },
        createUpdateLangue() {
            if (this.typeAction === 'create') {
                this.searchLangue();
            } else {
                this.updateLangue();
            }
        },
        async searchLangue() {
            const response = await httpPost('/langues/search', { 'cLangue': this.form.langue });
            if (!response.status) {
                this.createLangue();
            } else {
                this.$swal({
                    title: 'Erreur',
                    text: 'Cette langue existe déjà',
                    icon: 'error',
                    confirmButtonText: 'Ok'
                })
            }
        },
        async createLangue() {
            await httpPost('/langues/create',
                {
                    'cLangue': this.form.langue,
                });
            this.$swal({
                title: 'Succès',
                text: 'Langue a été créée',
                type: 'success',
                icon: 'success',
                confirmButtonText: 'OK'
            });
            this.getLangues();
            this.closeDialogCreateLangue();
        },
        async updateLangue() {
            await httpPost('/langues/update',
                {
                    'pLangue': this.form.id,
                    'cLangue': this.form.langue,
                });
            this.$swal({
                title: 'Succès',
                text: 'Langue a été modifiée',
                type: 'success',
                icon: 'success',
                confirmButtonText: 'OK'
            });
            this.getLangues();
            this.closeDialogCreateLangue();
        },
        deleteLangue(item) {
            this.$swal({
                title: 'Êtes-vous sûr?',
                text: 'Vous ne pourrez pas récupérer cette langue!',
                type: 'warning',
                icon: 'warning',
                showCancelButton: true,
                confirmButtonText: 'Oui, supprimez-la!',
                cancelButtonText: 'Non, gardez-la'
            }).then((result) => {
                if (result.value) {
                    httpPost('/langues/delete', { 'pLangue': item.pLangue });
                    this.$swal(
                        'Supprimé!',
                        'Langue a été supprimée.',
                        'success'
                    );
                    this.getLangues();
                }
            })
        },

        closeDialogCreateLangue() {
            this.clearForm();
            this.dialogCreateLangue = false;
        },
        clearForm() {
            this.$refs.observer.reset();
            this.form.langue = '';
            this.form.id = '';
            this.typeAction = '';
        },
    }
}
