import Vue from 'vue'
import App from './App.vue'
import router from './router/router'
import vuetify from './plugins/vuetify'
import './plugins/vee-validate'
import store from './store/store'
//import i18n from './i18n'
import VueSweetalert2 from 'vue-sweetalert2';
import 'sweetalert2/dist/sweetalert2.min.css';
import VueLoading from 'vue-loading-overlay';
import 'vue-loading-overlay/dist/vue-loading.css';
import VueCookies from 'vue-cookies'
import JsonViewer from 'vue-json-viewer'

Vue.component('snack-message', require('./components/SnackMessage.vue').default);
// alert modal
Vue.component('alert-modal', require('./components/AlertModal.vue').default);
// confirm modal
Vue.component('confirm-modal', require('./components/ConfirmModal.vue').default);
// loading modal
Vue.component('loading-modal', require('./components/LoadingModal.vue').default);


const options = {
  confirmButtonColor: '#41b882',
  cancelButtonColor: '#ff7674',
};


Vue.config.productionTip = false
Vue.use(VueSweetalert2, options);
Vue.use(VueLoading);
Vue.use(VueCookies, { expire: '7d'})
Vue.use(JsonViewer)



new Vue({
  router,
  vuetify,
  store,
  //i18n,   
  render: h => h(App)
}).$mount('#app')
