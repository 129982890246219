import 'vue-loading-overlay/dist/vue-loading.css';
import { httpRequest, httpPost } from '@/services/httpService';
import { ValidationObserver } from 'vee-validate';

export default {
    name: 'GrillesURL',
    components: {
        ValidationObserver,
    },
    created() {
        this.getLangues();
        this.getCompetences();
    },
    data() {
        return {
            on: true,
            valid: false,
            langues: [],
            competences: [],
            textRules: [(v) => !!v || "Cet élément est obligatoire"],
            validGrille: true,
            form: {
                langue: '',
                competence: '',
                typeLien: '',
                typeActivite: '',
            },
            typeLiens: [
                {text: 'Entraînement', value: 1},
                {text: 'Réchauffement', value: 2},
            ],
            typeActivites: [
                {text: 'Visualiser', value: 1 },
                {text: 'Visualiser + Échantillon + Enregistrer', value: 2},
                {text: 'Visualiser + Échantillon + Enregistrer + Correction', value:3},
            ],
            cGrille: '',
            cGrilleDesc: '',
            pGrille: '',
            disabledBtn: true,
            lien: '',
        }
    },
    methods: {
        getLangues() {
            httpRequest('/langues')
                .then(response => {
                    this.langues = response.data;
                })
        },
        getCompetences() {
            httpRequest('/competences')
                .then(response => {
                    this.competences = response.data;
                })
        },
        validerGrille(){
            this.validGrille = false;
            if(this.$refs.formGrilleURL.validate()) {
                this.validGrille = true;
                this.rechercher();
            }
        },

        async rechercher() {
            this.clearVar();
            const response = await httpPost('/grilles/searchgrillelanguecompetence', {
                cGrilleLangue: this.form.langue,
                cGrilleCompetence: this.form.competence,
                bActif: 1,
            });
            if (response.status) {
                this.cGrille = response.message.cGrille;
                this.cGrilleDesc = response.message.cGrilleDesc;
                this.pGrille = response.message.pGrille;
                this.trouverLien();
                //this.disabledBtn = false;
            } else {
                this.$swal({
                    type: 'error',
                    title: 'Erreur',
                    icon: 'error',
                    text: response.message,
                    confirmationButtonText: 'OK',
                });
            }
        },
        trouverLien() {
            var URLactuelle = window.location.origin;

            if (this.form.typeLien == 1) {
                switch (this.form.typeActivite) {
                    case 1:
                        this.lien = URLactuelle + '/previewpage/' + this.pGrille + '/' + this.form.typeActivite;
                        break;
                    case 2:
                        this.lien = URLactuelle + '/entrainement/' + this.pGrille + '/' + this.form.typeActivite;
                        break;
                    case 3:
                        this.lien = URLactuelle + '/entrainement/' + this.pGrille + '/' + this.form.typeActivite;
                        break;
                }
            } else if (this.form.typeLien == 2) {
                switch (this.form.typeActivite) {
                    case 1:
                        this.lien = URLactuelle + '/previewpage/' + this.pGrille + '/' + this.form.typeActivite;
                        break;
                    case 2:
                        this.lien = '/admgrilles/' + this.cGrille + '/' + this.pGrille + '/visualiserechantillon';
                        break;
                    case 3:
                        this.lien = '/admgrilles/' + this.cGrille + '/' + this.pGrille + '/visualiserechantillonmodifier';
                        break;
                }

            }
        },
        copier() {
            if (this.lien != '') {
                navigator.clipboard.writeText(this.lien);
                this.$swal({
                    icon: 'success',
                    type: 'success',
                    title: 'Copié',
                    text: 'Le lien a été copié dans le presse-papier',
                    confirmationButtonText: 'OK',
                });
            } else {
                this.$swal({
                    icon: 'error',
                    type: 'error',
                    title: 'Erreur',
                    text: 'Aucun lien n\'a été trouvé',
                    confirmationButtonText: 'OK',
                });
            }
        },
        reinitialiser() {
            this.$refs.formGrilleURL.reset();
            this.clearVar();
        },
        clearVar() {
            this.cGrille = '';
            this.cGrilleDesc = '';
            this.pGrille = '';
            this.lien = '';
        },
    }
}
