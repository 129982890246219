import Vue from "vue";
import Vuex from "vuex";
import VueCookies from "vue-cookies";
import { version } from "../../package.json";

Vue.use(Vuex);

export default new Vuex.Store({
  namespaced: true,
  state: {
    auth: null,
    roles: [],
    appVersion: version,
    token: null,
    isloading: false,
    user: [],
    snackbar: false,
    snackbarText: "",
    snackbarColor: "",
    modalCreate: {
      title: "",
      show: false,
    },
    resources:[],
    drawer: null,
    modalInterface: {
      show: false,
      user: null,
    },
    modalRoles: {
      show: false,
      user: null,
    },
    modalCreateUser: {
      show: false,
      user: null,
      title: "",
    },
    modalProfileUtilisateur: {
      show: false,
      user: null,
      title: "",
    }
  },
  // getter isloading
  getters: {
    isloading: (state) => state.isloading,
    // resources
    getResources: (state) => state.resources,
    // getCookieToken
    getCookieToken: (state) => {
      return state.token;
    },
    // getRole
    getRole: (state) => {
      return state.roles;
    },
  },
  mutations: {
    // set role
    setRole(state, role) {
      state.roles = role;
    },
    //resources
    setResources(state, resources){
      state.resources = resources;
    },
    //get cookie token
    getCookieToken(state) {
      state.token = VueCookies.get("token");
    },
    // get role
    getRole(state) {
      return state.roles;
    },
    SHOW_MESSAGE(state, payload) {
      state.snackbarText = payload.text;
      state.snackbarColor = payload.color;
    },
    setUser(state, payload) {
      (state.user.name = payload), (state.user.email = payload.cCourriel);
      localStorage.setItem("user", payload);
    },
    setRoles(state, payload) {
      localStorage.setItem("user_roles", payload);
    },
    logout(state) {
      state.auth = false;
      state.token = null;
      localStorage.removeItem('token')
      VueCookies.remove("token");
    },
    setToken(state, token) {
      state.token = token;
      state.auth = true;
      localStorage.setItem("token", token); //redirect to dashboard
      VueCookies.set('token' , token, "15min");
    },
    //modal set tittle
    formCreateModal(state, payload) {
      state.formCreateModalTitle = payload;
    },
    setModalInterface(state, payload) {
      state.modalInterface.show = payload.show;
      state.modalInterface.user = payload.user;
    },
    setModalRoles(state, payload) {
      state.modalRoles.show = payload.show;
      state.modalRoles.user = payload.user;
    },
  },
  actions: {
    // set role
    setRole({ commit }, role) {
      commit("setRole", role);
    },
    // get role
    getRole({ commit }) {
      commit("getRole");
    },
    showMessage({ commit }, payload) {
      commit("SHOW_MESSAGE", payload);
    },
    doSetToken({ commit }, token) {
      commit("setToken", token);
    },
    doSetUser({ commit }, payload) {
      commit("setUser", payload);
    },
    doSetRoles({ commit }, payload) {
      commit("setRoles", payload);
    },
    doLogout({ commit }) {
      commit("logout");
    },
    //action modal
    setFormCreateModal({ commit }, payload) {
      commit("formCreateModal", payload);
    },
    // modal interface
    setModalInterface({ commit }, payload) {
      commit("setModalInterface", payload);
    },
    setModalRoles({ commit }, payload) {
      commit("setModalRoles", payload);
    },
    //resources
    setResources({ commit }, resources){
      commit("setResources", resources);
    },
    //get cookie token
    getCookieToken({ commit }) {
      commit("getCookieToken");
    },
    
  },
});
