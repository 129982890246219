import { httpGet } from "./httpService";

const getGrille = async (id) => {
    console.log("getGrille ===>", id);
    const result = await httpGet("/grilles/" + id);
    return result.message;
}

const getGrilleBlock = async (id) => {
    const blocks = await httpGet("/grilleBlocks/" + id);
    console.log(blocks.message);
    return blocks.message;

}

const getGrilleColumns = async (id) => {
    console.log("getGrilleColumns");
    const result = await httpGet("/grilleColumns/" + id);
    console.log('getGrilleColumns', result);
    return result;
}

const getGrilleRows = async (id) => {
    // get rows
    console.log("getRows");
    const result = await httpGet("/grilleRows/grille/" + id);
    console.log(result);
    return result.message;
}

const grilleRowColumns = async (id) => {
    const response = await httpGet('/grilleRowColumns/grille/' + id);
    return response.message;
}

export { getGrille, getGrilleBlock, getGrilleColumns, getGrilleRows, grilleRowColumns };