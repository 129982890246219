import { httpRequest, httpPost, httpGet } from "@/services/httpService";

export default {
  data() {
    return {
      pUser: "",
      isloading: false,
      curr: 1,
      lastStep: 6,
      textRules: [(v) => !!v || "Cet élément est obligatoire"],
      validStepGrille: true,
      validStepColonne: true,
      validStepBloc: true,
      validStepLigne: true,
      validStepCellule: true,
      enteteGrille: "En-tête de la grille",
      stepForm: [],
      panelBlocsLignes: [],

      competence: [],
      langue: [],
      grillesRegles: [],

      cGrille: "",
      cGrilleDesc: "",
      cGrilleLangue: "",
      cGrilleCompetence: "",
      pGrilleRegle: 0,
      bActif: false,
      pGrilleColumns: "",
      cGrilleColumns: "",
      nValue: "",
      pGrilleBlock: "",
      cGrilleBlock: "",
      cGrilleBlockDesc: "",
      bFilterBlock: false,
      bActifBlock: false,

      pGrilleBlockRows: [],
      cGrilleLigne: [],
      countBlocsLignes: 0,
      grilleLigneIndex: [],

      flagCellule: false,
      celluleBlock: [
        {
          celluleRow: [
            {
              cellulColumn: [
                {
                  cGrilleCellule: "",
                  nFiltreCellule: "",
                  cIconCellule: "",
                  pGrilleBlockRowColumns: "",
                }
              ]
            }
          ],
        }
      ],

      grillePasEvalue: false,
      textGrillePasEvalue: "",

      cGrilleCellule: [],
      nFiltreCellule: [],
      cIconCellule: [],
      pGrilleBlockRowColumns: [],

      pGrille: 0,
      newGrille: true,
      grilleColumns: [],
      grilleBlocs: [],
      grilleBlocsLignes: [],
      grilleCellules: [],
      countBlocs: 0,

      filtreCellule: [
        { text: "Aucun", value: 0 },
        { text: "Les autres lignes du bloc ne sont pas nécessaires", value: 1 },
        { text: "Les autres blocs ne sont pas nécessaires", value: 2 },
      ],
      valueFiltreCellule: 0,

      previewLignes: [
        {
          typeLigne: "",
          previewColumns: [
            {
              indexCelluleStyle: 0,
              selected: false,
            },
          ],
        },
      ],
      ligneActif: true,
    
    };
  },

  created() {
    const user = localStorage.getItem("user");
    if (user) {
      const userData = JSON.parse(user);
      this.pUser = userData.pUser;
    }
    this.getLangues();
    this.getCompetences();
    this.getGrillesRegles();
    if (this.$route.query.id) {
      this.newGrille = false;
      this.pGrille = this.$route.query.id;
      this.getGrilles(this.pGrille);
    }
    const querystring = window.location.search;
    const params = new URLSearchParams(querystring);
    const preview = params.get("preview");
    if (preview) {
      this.getColumns();
      this.getBlocs();
      this.getBlocLignes();
      this.getCellules();
      this.curr = 6;
    }
  },

  methods: {
    /* Fonctions grille */
    stepCompleteGrille(step) {
      return this.curr > step;
    },
    stepStatusGrille(step) {
      return this.curr > step ? "green" : "blue";
    },
    toListe() {
      this.$router.push("/liste");
    },
    getLangues() {
      httpRequest("/langues").then((response) => {
        this.langue = response.data;
      });
    },
    getCompetences() {
      httpRequest("/competences").then((response) => {
        this.competence = response.data;
      });
    },
    getGrillesRegles() {
      httpRequest("/admgrilleregles/actives").then((response) => {
        this.grillesRegles = response.data;
      });
    },
    async getGrilles(pGrille) {
      const response = await httpGet("/grilles/" + pGrille);
      this.cGrille = response.message.cGrille;
      this.cGrilleDesc = response.message.cGrilleDesc;
      this.cGrilleLangue = response.message.cGrilleLangue;
      this.cGrilleCompetence = response.message.cGrilleCompetence;
      this.pGrilleRegle = parseInt(response.message.pGrilleRegle);
      this.bActif = parseInt(response.message.bActif);
      this.enteteGrille = "En-tête de la grille - " + this.cGrille;
    },
    validateGrille() {
      this.validStepGrille = false;
      if (this.$refs.formGrille.validate()) {
        this.validStepGrille = true;
        this.enteteGrille = "En-tête de la grille - " + this.cGrille;
        this.chercherGrille();
        this.getColumns();
      }
    },
    async chercherGrille() {

      await httpPost("/grilles/searchcGrilleLangueCompetenceCreation", {
        cGrilleLangue: this.cGrilleLangue,
        cGrilleCompetence: this.cGrilleCompetence,
      }).then((response) => {
        
        if(response.message.length > 0) {

          let existe = this.validationGrilleExiste(response.message);
          if(!existe) {
            if(this.newGrille) {
              this.saveGrille("create");
              this.curr = 2;
            }
          } else {
            let existeV = this.validationGrilleExisteEtat(response.message);
            if(existeV) {
              this.$swal({
                title: "Erreur",
                text: "Il existe déjà une grille active pour cette langue et compétence",
                type: "error",
                icon: "error",
                confirmButtonText: "OK",
              });
              this.curr = 1;
            } else {
              if(!this.newGrille) {
                this.saveGrille("update");
                this.curr = 2;
              }
            }
          }

          /* response.message.forEach((grille) => {
            if(parseInt(grille.pGrille) != parseInt(this.pGrille) && parseInt(grille.bActif == 1) && this.bActif == 1) {
              
              this.$swal({
                title: "Erreur",
                text: "Il existe déjà une grille active pour cette langue et compétence",
                type: "error",
                icon: "error",
                confirmButtonText: "OK",
              });
              this.curr = 1;
              return;
            } else if(!this.newGrille) {
              this.saveGrille("update");
              this.curr = 2;
              return;
            } else if(this.newGrille) {
              this.saveGrille("create");
              this.curr = 2;
              return;
            }
          }); */
        } else {
          if (this.newGrille) {
            this.saveGrille("create");
          } 
          this.curr = 2;
        }
      });
    },

    validationGrilleExiste(grille) {
      let grilleExiste = false;
      grille.forEach((g) => {
        if (parseInt(g.pGrille) === parseInt(this.pGrille)) {
          grilleExiste = true;
        }
      });
      return grilleExiste;
    },

    validationGrilleExisteEtat(grille) {
      let grilleExisteEtat = false;
      let nActif = 0;
      if(this.bActif) {
        nActif = 1;
      }
      grille.forEach((g) => {
        if(parseInt(g.pGrille) != parseInt(this.pGrille) && parseInt(g.bActif) === 1 && nActif === 1) {
          grilleExisteEtat = true;
          return;
        }
      });
      return grilleExisteEtat;
    },

    async saveGrille(action) {
      if (action === "create") {
        const response = await httpPost("/grilles/create", {
          cGrille: this.cGrille,
          cGrilleDesc: this.cGrilleDesc,
          cGrilleLangue: this.cGrilleLangue,
          cGrilleCompetence: this.cGrilleCompetence,
          pGrilleRegle: this.pGrilleRegle,
          bActif: this.bActif,
          pUser: this.pUser,
        });
        if (response.id) {
          this.pGrille = response.id;
          this.newGrille = false;
        }
      } else {
        await httpPost("/grilles/update", {
          pGrille: this.pGrille,
          cGrille: this.cGrille,
          cGrilleDesc: this.cGrilleDesc,
          cGrilleLangue: this.cGrilleLangue,
          cGrilleCompetence: this.cGrilleCompetence,
          pGrilleRegle: this.pGrilleRegle,
          bActif: this.bActif,
          pUser: this.pUser,
        });
      }
    },

    //Fonctions colonnes
    stepCompleteColonne(step) {
      return this.curr > step;
    },
    stepStatusColonne(step) {
      return this.curr > step ? "green" : "blue";
    },
    getColumns() {
      httpRequest("/grilleColumns/" + this.pGrille).then((response) => {
        this.grilleColumns = response.data;
      });
    },
    validateColonne() {
      this.validStepColonne = false;
      if (this.$refs.formColonne.validate()) {
        this.validStepColonne = true;
        this.saveColumns();
      }
    },
    validateColonnes() {
      //this.validStepColonne = false;
      if (this.grilleColumns.length > 0) {
        //this.validStepColonne = true;
        this.$refs.formColonne.reset();
        this.getBlocs();
        this.curr = 3;
      } else {
        this.curr = 2;
        this.$swal({
          title: "Erreur",
          text: "Vous devez ajouter au moins une colonne",
          type: "error",
          icon: "error",
          confirmButtonText: "OK",
        });
        this.$refs.formColonne.reset();
        document.getElementById("cColonne").focus();
      }
    },
    async saveColumns() {
      //console.log("saveColumns -- ", this.pGrilleColumns);
      if (this.pGrilleColumns === "") {
        const response = await httpPost("/grilleColumns/create", {
          cGrilleColumns: this.cGrilleColumns,
          nValue: this.nValue,
          pGrille: this.pGrille,
        });
        if (response) {
          this.getColumns();
          this.clearFormColonne();
        }
      } else {
        const response = await httpPost("/grilleColumns/update", {
          pGrilleColumns: this.pGrilleColumns,
          cGrilleColumns: this.cGrilleColumns,
          nValue: this.nValue,
        });
        if (response) {
          this.getColumns();
          this.clearFormColonne();
        }
      }
    },
    editColonne(item) {
      this.pGrilleColumns = item.pGrilleColumns;
      this.cGrilleColumns = item.cGrilleColumns;
      this.nValue = item.nValue;
    },
    async deleteColumn(item) {
      const response = await httpPost("/grilleColumns/delete", {
        pGrilleColumns: item.pGrilleColumns,
      });
      if (response) {
        this.clearFormColonne();
        this.getColumns();
      }
    },
    clearFormColonne() {
      this.$refs.formColonne.reset();
      this.pGrilleColumns = "";
      //this.validStepColonne = true;
      //document.getElementById("cColonne").focus();
    },

    /* Fonctions Blocs */
    stepCompleteBloc(step) {
      return this.curr > step;
    },
    stepStatusBloc(step) {
      return this.curr > step ? "green" : "blue";
    },
    async getBlocs() {
      const blocs = await httpGet("/grilleBlocks/" + this.pGrille);
      this.grilleBlocs = blocs.message;
    },
    async getCountBlocs() {
      const count = await httpGet("/grilleBlocks/countblocs/" + this.pGrille);
      this.countBlocs = count.message;
    },
    validateBloc() {
      this.validStepBloc = false;
      if (this.$refs.formBloc.validate()) {
        this.validStepBloc = true;
        this.saveBlocs();
      }
    },

    validateBlocs() {
      if (this.grilleBlocs.length > 0) {
        this.validStepBloc = true;
        this.getBlocLignes();
        this.getCountBlocs();
        this.flagCellule = false;
        this.curr = 4;
      } else {
        this.curr = 3;
        this.$swal({
          title: "Erreur",
          text: "Vous devez ajouter au moins un bloc",
          type: "error",
          icon: "error",
          confirmButtonText: "OK",
        });
        this.$refs.formBloc.reset();
        document.getElementById("cBloc").focus();
      }
    },

    async saveBlocs() {
      if (this.pGrilleBlock === "") {
        const response = await httpPost("/grilleBlocks/create", {
          cGrilleBlock: this.cGrilleBlock,
          cGrilleBlockDesc: this.cGrilleBlockDesc,
          bActif: this.bActifBlock,
          bFilter: this.bFilterBlock,
          pGrille: this.pGrille,
        });
        if (response) {
          this.getBlocs();
          this.$refs.formBloc.reset();
          this.bActifBlock = false;
        }
      } else {
        const response = await httpPost("/grilleBlocks/update", {
          pGrilleBlock: this.pGrilleBlock,
          cGrilleBlock: this.cGrilleBlock,
          cGrilleBlockDesc: this.cGrilleBlockDesc,
          bActif: this.bActifBlock,
        });
        if (response) {
          this.getBlocs();
          this.$refs.formBloc.reset();
          this.bActifBlock = false;
          this.pGrilleBlock = "";
        }
      }
    },
    editBloc(item) {
      this.pGrilleBlock = item.pGrilleBlock;
      this.cGrilleBlock = item.cGrilleBlock;
      this.cGrilleBlockDesc = item.cGrilleBlockDesc;
      if (parseInt(item.bActif) === 1) {
        this.bActifBlock = true;
      } else {
        this.bActifBlock = false;
      }
      //this.bActifBlock = item.bActif;
    },
    async deleteBloc(item) {
      const response = await httpGet("/grilleRows/searchgrilleblock/" + item.pGrille + "/" + item.pGrilleBlock);
      if (response.status) {
        this.$swal({
          title: "Vous êtes sûr ?",
          text: "Vous allez supprimer un bloc qui contient des lignes",
          icon: "warning",
          showCancelButton: true,
          confirmButtonColor: "#3085d6",
          cancelButtonColor: "#d33",
          confirmButtonText: "Oui, supprimer",
        }).then((result) => {
          if (result.isConfirmed) {
            this.deleteBlocConfirmed(item);
          }
        });
      } else {
        this.deleteBlocConfirmed(item);
      }
    },

    async deleteBlocConfirmed(item) {
      const response = await httpPost("/grilleBlocks/delete", {
        pGrilleBlock: item.pGrilleBlock,
      });
      if (response) {
        this.clearFormBloc();
        this.getBlocs();
      }
    },

    clearFormBloc() {
      this.$refs.formBloc.reset();
      this.validStepBloc = true;
      document.getElementById("cBloc").focus();
    },

    /* Fonctions Lignes */
    stepCompleteLigne(step) {
      return this.curr > step;
    },
    stepStatusLigne(step) {
      return this.curr > step ? "green" : "blue";
    },

    async getBlocLignes() {
      const response = await httpGet("/grilleRows/grille/" + this.pGrille);
      this.grilleBlocsLignes = response.message;
      //console.log("grilleBlocsLignes en fonction ", this.grilleBlocsLignes);
    },

    filterRow(bloc) {
      const filteredRows = this.grilleBlocsLignes.filter((row) => parseInt(row.pGrilleBlock) === bloc);
      return filteredRows;
    },

    // OJO validar grabación
    validateLigne(item, index) {
      //console.log("validateLigne, indexBloc, formLigne", index, this.$refs["formLigne"]);
      this.validStepLigne = false;
      if (this.$refs["formLigne"][index].validate()) {
        this.validStepLigne = true;
        this.saveLignes(item, index);
      }
      /* if (this.grilleLigneIndex[index] === "" || this.grilleLigneIndex[index] === undefined) {
        console.log("indefinido, countBlocsLignes, formLigne ", this.countBlocsLignes, this.$refs["formLigne"]);
        this.grilleLigneIndex[index] = this.countBlocsLignes;
        this.countBlocsLignes++;
      }
      if (this.$refs["formLigne"][this.grilleLigneIndex[index]].validate()) {
        this.validStepLigne = true;
        this.saveLignes(item, index);
      } */
    },

    async saveLignes(item, index) {
      const response = await httpPost("/grilleRows/create", {
        cTitre: this.cGrilleLigne[index],
        pGrille: this.pGrille,
        pGrilleBlock: item.pGrilleBlock,
      });
      if (response) {
        this.getBlocLignes();
        //this.$refs["formLigne"][this.grilleLigneIndex[index]].reset();
        this.$refs.formLigne[index].reset();
      }
    },

    editLigne(item, index) {
      this.pGrilleBlockRows[index] = item.pGrilleBlockRows;
      this.cGrilleLigne[index] = item.cTitre;
    },

    async deleteRow(item) {
      await httpPost("/grilleRows/delete", {
        pRow: item.pGrilleBlockRows,
      });
      this.getBlocLignes();
    },
    validateLignes() {
      httpRequest("/grilleRows/countrows/" + this.pGrille).then((response) => {
        if (!response.data.status) {
          this.$swal({
            title: "Erreur",
            text: "Vous devez ajouter au moins une ligne",
            type: "error",
            icon: "error",
            confirmButtonText: "OK",
          });
        } else {
          this.validStepLigne = true;
          this.getColumns();
          this.getBlocs();
          this.getCellules();
          this.curr = 5;
        }
      });
    },

    /* Fonction cellules  */
    stepCompleteCellule(step) {
      return this.curr > step;
    },
    stepStatusCellule(step) {
      return this.curr > step ? "green" : "blue";
    },

    buildCellules() {
      if (this.grilleBlocs.length > 0 && this.grilleBlocsLignes.length > 0 && this.grilleColumns.length > 0) {
        this.celluleBlock = [];
        this.grilleBlocs.forEach((block, indexBlock) => {
          this.celluleBlock[indexBlock] = [];
          this.grilleBlocsLignes.forEach((row, indexRow) => {
            this.celluleBlock[indexBlock][indexRow] = [];
            this.grilleColumns.forEach((column, indexColumn) => {
              this.celluleBlock[indexBlock][indexRow][indexColumn] = {
                cGrilleCellule: "",
                nFiltreCellule: "",
                cIconCellule: "",
                pGrilleBlockRowColumns: "",
              };
            });
          });
        });
      }
      this.flagCellule = true;
    },

    remplirCellules() {
      for (let i = 0; i < this.grilleCellules.length; i++) {
        this.celluleBlock[this.grilleCellules[i].blockIndex][this.grilleCellules[i].rowIndex][this.grilleCellules[i].columnIndex].cGrilleCellule = this.grilleCellules[i].cGrilleBlockRowColumns;
        this.celluleBlock[this.grilleCellules[i].blockIndex][this.grilleCellules[i].rowIndex][this.grilleCellules[i].columnIndex].nFiltreCellule = parseInt(this.grilleCellules[i].nFiltre);
        this.celluleBlock[this.grilleCellules[i].blockIndex][this.grilleCellules[i].rowIndex][this.grilleCellules[i].columnIndex].pGrilleBlockRowColumns = parseInt(this.grilleCellules[i].pGrilleBlockRowColumns);
        this.celluleBlock[this.grilleCellules[i].blockIndex][this.grilleCellules[i].rowIndex][this.grilleCellules[i].columnIndex].cIconCellule = "mdi-check-circle";
      }
    },


    validateCellule(b, r, c, bloc, row, column, type) {
      let index = Number(`${b}${r}${c}`);
      if (this.celluleBlock[b][r][c].pGrilleBlockRowColumns === undefined
        || this.celluleBlock[b][r][c].pGrilleBlockRowColumns === null
        || this.celluleBlock[b][r][c].pGrilleBlockRowColumns === "") {
        this.celluleBlock[b][r][c].pGrilleBlockRowColumns = 0;
      }
      if (this.celluleBlock[b][r][c].cGrilleCellule != undefined
        || this.celluleBlock[b][r][c].cGrilleCellule != null) {
        if (type === 'create') {
          this.saveCellules(index, bloc, row, column, b, r, c);
        } else if (type === 'delete' && this.pGrilleBlockRowColumns[index] != 0) {
          this.deleteCellule(index, b, r, c);
        }
      }
    },


    async saveCellules(index, bloc, row, column, b, r, c) {
      if (this.celluleBlock[b][r][c].nFiltreCellule === undefined
        || this.celluleBlock[b][r][c].nFiltreCellule === null
        || this.celluleBlock[b][r][c].nFiltreCellule === "") {
        this.celluleBlock[b][r][c].nFiltreCellule = 0;
      }
      let nValue = "";
      if (this.grilleColumns[c].nValue != undefined 
        || this.grilleColumns[c].nValue != null 
        || this.grilleColumns[c].nValue != "") {
        nValue = parseInt(this.grilleColumns[c].nValue);
      }

      const response = await httpPost("/grilleRowColumns/create", {
        pGrilleBlockRowColumns: this.celluleBlock[b][r][c].pGrilleBlockRowColumns,
        pGrille: parseInt(this.pGrille),
        pGrilleColumns: parseInt(column),
        pGrilleBlock: parseInt(bloc),
        pGrilleBlockRows: parseInt(row),
        blockIndex: b,
        rowIndex: r,
        columnIndex: c,
        //index: index,
        cGrilleBlockRowColumns: this.celluleBlock[b][r][c].cGrilleCellule,
        nFiltre: parseInt(this.celluleBlock[b][r][c].nFiltreCellule),
        nValue: nValue,
      });
      if (response) {
        this.celluleBlock[b][r][c].cIconCellule = "mdi-check-circle";
        this.getCellules();
      }
    },

    async deleteCellule(index, b, r, c) {
      const response = await httpPost("/grilleRowColumns/delete", {
        pGrilleBlockRowColumns: this.celluleBlock[b][r][c].pGrilleBlockRowColumns,
      });
      if (response) {
        this.getCellules();
      }
    },

    async getCellules() {
      const response = await httpGet("/grilleRowColumns/pgrille/" + this.pGrille);
      this.grilleCellules = response;
      this.buildCellules();
      if (this.grilleCellules.length > 0) {
        this.remplirCellules();
      }
    },


    /*     getCellule(index) {
          //console.log("getCellule - index", index);
          const cellule = this.grilleCellules.filter(
            (item) => parseInt(item.index) === index
          );
          //console.log("getCellule - cellule", cellule);
          for (let i = 0; i < cellule.length; i++) {
            this.cGrilleCellule[cellule[i].index] = cellule[i].cGrilleBlockRowColumns;
            this.nFiltreCellule[cellule[i].index] = parseInt(cellule[i].nFiltre);
            this.pGrilleBlockRowColumns[cellule[i].index] = cellule[i].pGrilleBlockRowColumns;
            this.cIconCellule[cellule[i].index] = "mdi-check-circle";
          }
        }, */

    /*     changeValueCellule(index, value) {
          this.cGrilleCellule[index] = value;
        },
    
        changeValueFiltreCellule(index, value) {
          this.nFiltreCellule[index] = value;
          //console.log("changeValueFiltreCellule index, value ", index, value);
          //console.log("changeValueFiltreCellule nFiltreCellule ", this.nFiltreCellule);
        }, */

    validateCellules() {
      this.getCellules();
      if (this.grilleCellules.length > 0) {
        //this.indexTablePreview();
        this.buildIndexTablePreview(this.grilleBlocs, this.grilleBlocsLignes, this.grilleColumns);
        this.curr = 6;
      } else {
        this.$swal({
          title: "Erreur",
          text: "Vous devez ajouter au moins une cellule",
          type: "error",
          icon: "error",
          confirmButtonText: "OK",
        });
      }
    },

    /***********/
    /* preview */
    /***********/

    filterCellule(pGrilleColumns, pGrilleBlock, pGrilleBlockRow) {
      const filteredCellule = this.grilleCellules.filter(
        (cellule) =>
          parseInt(cellule.pGrilleColumns) === parseInt(pGrilleColumns) &&
          parseInt(cellule.pGrilleBlock) === parseInt(pGrilleBlock) &&
          parseInt(cellule.pGrilleBlockRows) === parseInt(pGrilleBlockRow)
      );
      return filteredCellule;
    },

    buildIndexTablePreview(grilleBlocs, grilleRows, grilleColumns) {
      this.previewLignes = [];
      let indexCellule = 0;
      let index = 0;
      let blocActif = grilleBlocs.filter((bloc) => parseInt(bloc.bActif) === 1);
      for (let i = 0; i < blocActif.length; i++) {
        let blocRows = grilleRows.filter((row) => parseInt(row.pGrilleBlock) === parseInt(blocActif[i].pGrilleBlock));
        for (let j = 0; j < blocRows.length + 1; j++) {
          if (j === 0) {
            this.previewLignes[index] = {
              typeLigne: "bloc",
              previewColumns: []
            };
          } else {
            this.previewLignes[index] = {
              typeLigne: "ligne",
              previewColumns: []
            };
            for (let k = 0; k < grilleColumns.length; k++) {
              this.previewLignes[index].previewColumns[k] = {
                indexCelluleStyle: indexCellule,
                selected: false,
                pGrilleBlockRowColumns: ""
              };
              indexCellule++;
            }
          }
          index++;
        }
      }
      return this.previewLignes;
    },

    changeStyleCellule(event, column, block, row) {
      let cellule = this.filterCellule(column, block, row);

      if (cellule.length > 0) {
        let indexRow = "";
        let indexColumn = "";
        let indexCelluleStyle = "";

        for (let i = 0; i < event.composedPath().length; i++) {
          let x = event.composedPath()[i];
          if (x.className === "cellule") {
            indexColumn = x.cellIndex - 1;
          } else if (x.className === "rowCellule") {
            indexRow = x.rowIndex;
          }
        }
        if (indexRow !== "" && indexColumn !== "") {
          indexCelluleStyle = this.previewLignes[indexRow].previewColumns[indexColumn].indexCelluleStyle;
          this.changeColeurCellule(indexRow, indexCelluleStyle);
          this.modifierPreviewLignes(indexRow, indexColumn, cellule[0]);
          //this.inactiverRow(indexRow, parseInt(cellule[0].nFiltre));
        }

      }
    },

    changeColeurCellule(indexLigne, indexCelluleStyle) {
      for (let i = 0; i < this.grilleColumns.length; i++) {
        let j = this.previewLignes[indexLigne].previewColumns[i].indexCelluleStyle;
        document.getElementsByClassName("cellule")[j].style.backgroundColor = "#FFFFFF";
      }
      document.getElementsByClassName("cellule")[indexCelluleStyle].style.backgroundColor = "#90CAF9";
    },

    modifierPreviewLignes(indexLigne, indexColumn, cellule) {
      this.previewLignes[indexLigne].previewColumns.forEach(element => {
        element.selected = false;
        element.pGrilleBlockRowColumns = "";
      });
      this.previewLignes[indexLigne].previewColumns[indexColumn].selected = true;
      this.previewLignes[indexLigne].previewColumns[indexColumn].pGrilleBlockRowColumns = cellule.pGrilleBlockRowColumns;
    },

    inactiverRow(indexLigne, filtre) {
      if (filtre === 1 || filtre === 2) {
        this.filtreCelluleStyle(indexLigne, filtre);
      } else {
        this.cleanFiltre(indexLigne);
      }
    },

    filtreCelluleStyle(indexLigne, filtre) {
      this.cleanFiltre(indexLigne);
      for (let i = indexLigne + 1; i < this.previewLignes.length; i++) {
        if (this.previewLignes[i].typeLigne === "ligne") {
          this.previewLignes[i].previewColumns.forEach(element => {
            let c = element.indexCelluleStyle;
            document.getElementsByClassName("cellule")[c].style.backgroundColor = "#cccccc";
            document.getElementsByClassName("cellule")[c].style.color = "#666666";
            document.getElementsByClassName("cellule")[c].style.pointerEvents = "none";
          });
        } else if (filtre === 1) {
          break;
        }
      }
    },

    filtreAllCellulesStyle() {
      for (let i = 0; i < this.previewLignes.length; i++) {
        if (this.previewLignes[i].typeLigne === "ligne") {
          this.previewLignes[i].previewColumns.forEach(element => {
            let c = element.indexCelluleStyle;
            document.getElementsByClassName("cellule")[c].style.backgroundColor = "#cccccc";
            document.getElementsByClassName("cellule")[c].style.color = "#666666";
            document.getElementsByClassName("cellule")[c].style.pointerEvents = "none";
          });
        }
      }
    },

    cleanFiltre(indexLigne) {
      for (let i = indexLigne + 1; i < this.previewLignes.length; i++) {
        if (this.previewLignes[i].typeLigne === "ligne") {
          this.previewLignes[i].previewColumns.forEach(element => {
            let c = element.indexCelluleStyle;
            element.selected = false;
            element.pGrilleBlockRowColumns = "";
            document.getElementsByClassName("cellule")[c].style.backgroundColor = "#FFFFFF";
            document.getElementsByClassName("cellule")[c].style.color = "#000000";
            document.getElementsByClassName("cellule")[c].style.pointerEvents = "auto";
          });
        }
      }
    },

    cleanAllFiltre() {
      for (let i = 0; i < this.previewLignes.length; i++) {
        if (this.previewLignes[i].typeLigne === "ligne") {
          this.previewLignes[i].previewColumns.forEach(element => {
            let c = element.indexCelluleStyle;
            element.selected = false;
            element.pGrilleBlockRowColumns = "";
            document.getElementsByClassName("cellule")[c].style.backgroundColor = "#FFFFFF";
            document.getElementsByClassName("cellule")[c].style.color = "#000000";
            document.getElementsByClassName("cellule")[c].style.pointerEvents = "auto";
          });
        }
      }
    },

    changePasEvalue() {
      if (this.grillePasEvalue) {
        this.filtreAllCellulesStyle();
      } else {
        this.textGrillePasEvalue = "";
        this.cleanAllFiltre();
      }
    },
    //-----------------------------------

    /* indexTablePreview() {
      let indexCellule = 0;
      let index = 0;

      let blocActif = this.grilleBlocs.filter((item) => parseInt(item.bActif) === 1);


      for (let i = 0; i < blocActif.length; i++) {
        let countRowsBloc = this.grilleBlocsLignes.filter(
          (item) => parseInt(item.pGrilleBlock) === parseInt(blocActif[i].pGrilleBlock)
        );

        for (let j = 0; j < countRowsBloc.length + 1; j++) {
          if (j === 0) {
            this.previewLignes[index] = { typeLigne: "bloc", previewColumns: [] };
          } else {
            this.previewLignes[index] = { typeLigne: "ligne", previewColumns: [] };
            for (let k = 1; k <= this.grilleColumns.length; k++) {
              this.previewLignes[index].previewColumns[k] = { indexCelluleStyle: indexCellule, selected: false };
              indexCellule++;
            }
          }
          index++;
        }
      }
    },

    changeStyle(event, cellule) {

      if (cellule === undefined) {
        return;
      }
      var path = event.path || (event.composedPath && event.composedPath());
      if (path[2].rowIndex === undefined && path[1].cellIndex === undefined) {
        return;
      }
      let indexRow = path[2].rowIndex;
      let indexColumn = path[1].cellIndex;
      let index = this.previewLignes[indexRow].previewColumns[indexColumn].indexCelluleStyle;
      console.log("changeStyle - indexRow, indexColumn, index", indexRow, indexColumn, index);
      this.changeColeurCellule(indexRow, indexColumn, index);
      this.inactiverRow(cellule, indexRow, indexColumn, index);
      this.changeCelluleSelected(indexRow, indexColumn);
    },

    changeCelluleSelected(indexRow, indexColumn) {
      let r = indexRow;
      let c = indexColumn;
      let e = this.previewLignes[r].previewColumns[c].selected;
      for (let i = 1; i <= this.grilleColumns.length; i++) {
        this.previewLignes[indexRow].previewColumns[i].selected = false;
      }
      if (e) {
        this.previewLignes[indexRow].previewColumns[indexColumn].selected = false;
      } else {
        this.previewLignes[indexRow].previewColumns[indexColumn].selected = true;
      }
    },

    changeColeurCellule(indexRow, indexColumn, index) {
      for (let j = 1; j <= this.grilleColumns.length; j++) {
        let i = this.previewLignes[indexRow].previewColumns[j].indexCelluleStyle;
        document.getElementsByClassName("cellule")[i].style.backgroundColor = "white";
      }
      //console.log("changeColeurCellule", indexRow, indexColumn, index, this.previewLignes[indexRow].previewColumns[indexColumn].selected);
      if (!this.previewLignes[indexRow].previewColumns[indexColumn].selected) {
        document.getElementsByClassName("cellule")[index].style.backgroundColor = "#90CAF9";
      }
    },

    inactiverRow(cellule, indexRow, indexColumn) {

      switch (parseInt(cellule.nFiltre)) {
        case 1:
          this.filtreCellule1(indexRow, indexColumn);
          break;
        case 2:
          this.filtreCellule2(indexRow, indexColumn);
          break;
        default:
          this.cleanFiltre(indexRow);
      }
    },


    // "Les autres lignes du bloc ne sont pas nécessaires", value: 1 
    // "Les autres blocs ne sont pas nécessaires", value: 2
    filtreCellule1(indexRow, indexColumn) {
      if (this.valueFiltreCellule === 2) {
        this.cleanFiltre(indexRow);
      }
      this.valueFiltreCellule = 1;
      if (!this.previewLignes[indexRow].previewColumns[indexColumn].selected) {
        for (let i = indexRow + 1; i < this.previewLignes.length; i++) {
          if (this.previewLignes[i].typeLigne === "ligne") {
            for (let j = 1; j <= this.grilleColumns.length; j++) {
              let c = this.previewLignes[i].previewColumns[j].indexCelluleStyle;
              this.previewLignes[i].previewColumns[j].selected = false;
              document.getElementsByClassName("cellule")[c].style.backgroundColor = "#cccccc";
              document.getElementsByClassName("cellule")[c].style.color = "#666666";
              document.getElementsByClassName("cellule")[c].style.pointerEvents = "none";
            }
          } else {
            i = this.previewLignes.length;
          }
        }
      } else {
        this.cleanFiltre(indexRow);
      }
    },

    filtreCellule2(indexRow, indexColumn) {
      this.valueFiltreCellule = 2;
      if (!this.previewLignes[indexRow].previewColumns[indexColumn].selected) {
        for (let i = indexRow + 1; i < this.previewLignes.length; i++) {
          if (this.previewLignes[i].typeLigne === "ligne") {
            for (let j = 1; j <= this.grilleColumns.length; j++) {
              let c = this.previewLignes[i].previewColumns[j].indexCelluleStyle;
              this.previewLignes[i].previewColumns[j].selected = false;
              document.getElementsByClassName("cellule")[c].style.backgroundColor = "#cccccc";
              document.getElementsByClassName("cellule")[c].style.color = "#666666";
              document.getElementsByClassName("cellule")[c].style.pointerEvents = "none";
            }
          }
        }
      } else {
        this.cleanFiltre(indexRow);
      }

    },

    cleanFiltre(indexRow) {
      if (this.valueFiltreCellule === 1) {
        for (let i = indexRow + 1; i < this.previewLignes.length; i++) {
          if (this.previewLignes[i].typeLigne === "ligne") {
            for (let j = 1; j <= this.grilleColumns.length; j++) {
              let c = this.previewLignes[i].previewColumns[j].indexCelluleStyle;
              document.getElementsByClassName("cellule")[c].style.backgroundColor = "white";
              document.getElementsByClassName("cellule")[c].style.color = "black";
              document.getElementsByClassName("cellule")[c].style.pointerEvents = "auto";
            }
          } else {
            i = this.previewLignes.length;
          }
        }
      } else if (this.valueFiltreCellule === 2) {
        for (let i = indexRow + 1; i < this.previewLignes.length; i++) {
          if (this.previewLignes[i].typeLigne === "ligne") {
            for (let j = 1; j <= this.grilleColumns.length; j++) {
              let c = this.previewLignes[i].previewColumns[j].indexCelluleStyle;
              document.getElementsByClassName("cellule")[c].style.backgroundColor = "white";
              document.getElementsByClassName("cellule")[c].style.color = "black";
              document.getElementsByClassName("cellule")[c].style.pointerEvents = "auto";
            }
          }
        }
      }
      this.valueFiltreCellule = 0;
    },
    changePasEvalue() {
      if (this.grillePasEvalue) {
        this.filtreAllCellules();
      } else {
        this.textGrillePasEvalue = "";
        this.cleanAllFiltre();
      }
    }, */
  },
};
