var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('validation-observer',{ref:"observer",scopedSlots:_vm._u([{key:"default",fn:function(ref){
var invalid = ref.invalid;
return [_c('v-form',{ref:"form",attrs:{"lazy-validation":""}},[_c('v-row',[_c('v-col',{attrs:{"cols":"6"}},[_c('validation-provider',{attrs:{"name":"text","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('div',[_c('v-text-field',{attrs:{"label":"Nom du bloc","error-messages":errors,"hide-details":"auto"},model:{value:(_vm.FormData.cGrilleBlock),callback:function ($$v) {_vm.$set(_vm.FormData, "cGrilleBlock", $$v)},expression:"FormData.cGrilleBlock"}})],1)]}}],null,true)})],1),_c('v-col',{attrs:{"cols":"6"}},[_c('validation-provider',{attrs:{"name":"text","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('div',[_c('v-text-field',{attrs:{"label":"Description du bloc","error-messages":errors,"hide-details":"auto"},model:{value:(_vm.FormData.cGrilleBlockDesc),callback:function ($$v) {_vm.$set(_vm.FormData, "cGrilleBlockDesc", $$v)},expression:"FormData.cGrilleBlockDesc"}})],1)]}}],null,true)})],1),_c('v-col',[_c('v-switch',{staticClass:"ml-3",attrs:{"label":"Bloquer les autres blocs","hint":"Lorsque vous activez cette option, les autres blocs ne seront pas évalués."},model:{value:(_vm.FormData.bFilter),callback:function ($$v) {_vm.$set(_vm.FormData, "bFilter", $$v)},expression:"FormData.bFilter"}})],1),_c('v-col',{staticClass:"d-flex align-center"},[_c('v-btn',{attrs:{"color":"primary","disabled":invalid},on:{"click":_vm.AddOtherBlock}},[_vm._v(" Ajouter un autre bloc")])],1)],1)],1)]}}])}),_c('v-row',{staticClass:"ma-5"},[_c('v-btn',{attrs:{"color":"primary","disabled":_vm.grilleBlocks.lenght == 0},on:{"click":_vm.saveBlockAndContinue}},[_vm._v(" Continuer vers les colonnes")]),_c('v-btn',{staticClass:"ml-5",attrs:{"color":"error"},on:{"click":_vm.back}},[_vm._v(" Annuler ")])],1),_c('v-divider'),_c('v-simple-table',{scopedSlots:_vm._u([{key:"default",fn:function(){return [_c('thead',[_c('tr',[_c('th',{staticClass:"text-left"},[_vm._v("Nom du bloc")]),_c('th',{staticClass:"text-left"},[_vm._v("Description du bloc")]),_c('th',{staticClass:"text-left"},[_vm._v("Bloquer les autres blocs")]),_c('th',{staticClass:"d-flex justify-center d-flex align-center"},[_vm._v("Actions")])])]),_c('tbody',_vm._l((_vm.grilleBlocks),function(item){return _c('tr',{key:item.name},[_c('td',[_vm._v(_vm._s(item.cGrilleBlock))]),_c('td',[_vm._v(_vm._s(item.cGrilleBlockDesc))]),_c('td',[(item.bFilter === 0)?_c('span',[_vm._v(" Faux")]):_c('span',[_vm._v(" Vrai")])]),_c('td',{staticClass:"d-flex justify-center d-flex align-center"},[_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-btn',_vm._g({staticClass:"mr-2",attrs:{"text":"","small":""},on:{"click":function($event){return _vm.deleteItemBlock(item)}}},on),[_c('v-icon',[_vm._v("mdi-delete")])],1)]}}],null,true)},[_c('span',[_vm._v("Supprimer le bloc")])])],1)])}),0)]},proxy:true}])})],1)}
var staticRenderFns = []

export { render, staticRenderFns }