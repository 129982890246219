import { httpGet } from '@/services/httpService';

export default {
    name: 'ListGrilles',
    created () {
        this.getGrilles();
    },
    data() {
        return {
            headers: [
                { text: 'Grille', value: 'cGrille' },
                { text: 'Langue', value: 'cGrilleLangue' },
                { text: 'Compétence', value: 'cGrilleCompetence' },
                { text: 'Actions', value: 'action', sortable: false, align: 'center' },
            ],
            grilles: [],
            search: '',
            isloading: false,
            itemsPerPage: 10,
        };
    },
    //methods
    methods: {
        createGrille(){
            console.log('createGrille');
            // /Listgrilles
            this.$router.push('/grilles');
        },
        async getGrilles(){
            console.log('getGrilles');
            const result = await httpGet('/grilles');
            console.log(result);
            this.grilles = result;
        },
        editGrille (id){
            console.log('editGrille - pGrille = ',id);
            // push grilles route
            this.$router.push('/grilles?id=' + id);

        },
        Preview (id){
            console.log('Preview',id);
            // push grilles route
            this.$router.push('/previewPage/' + id +'/1');
        },
    }
}