import { httpRequest, httpPost } from '@/services/httpService';

export default {
    name: 'AppTypeInterface',
    created() {
        this.getTypeInterfaces();
    },
    data() {
        return {
            on: true,
            isloading: false,
            itemsPerPage: 10,
            typeInterfaces: [],
            search: '',
            headers: [
                { text: 'Type d\'interface', value: 'cAppTypeInterface' },
                { text: 'Description', value: 'cAppTypeInterfaceDesc' },
                { text: 'Actions', value: 'modifier', sortable: false, align: 'center' },
            ],
            dialogCreateAppTypeInterface: false,

            formTypeInterface: {
                type: '',
                description: '',
                id: '',
            },
            messageError: '',
            typeAction: '',
        }
    },
    methods: {
        getTypeInterfaces() {
            this.isloading = true;
            httpRequest('/admAppTypesInterfaces')
                .then(response => {
                    this.typeInterfaces = response.data;
                    this.isloading = false;
                })
        },

        openDialogCreateAppTypeInterface(item, typeAction) {
            this.typeAction = typeAction;
            this.dialogCreateAppTypeInterface = true;
        },

        closeDialogCreateAppTypeInterface() {
            this.clearForm();
            this.dialogCreateAppTypeInterface = false;
        },

        createUpdateTypeInterface() {
            if (this.typeAction == 'create') {
                this.searchTypeInterface();
            } else {
                this.updateTypeInterface();
            }
        },

        async searchTypeInterface() {
            const response = await httpPost('/admAppTypesInterfaces/searchAppTypeInterfaceNom', {'cAppTypeInterface': this.formTypeInterface.type});
            if (!response.status) {
                this.createTypeInterface();
            } else {
                this.$swal({
                    position: 'boton',
                    icon: 'warning',
                    title: 'Ce type d\'interface existe déjà',
                    showConfirmButton: false,
                    timer: 2500
                })
            }
        },

        async createTypeInterface() {
            await httpPost('/admAppTypesInterfaces/create', {
                'cAppTypeInterface': this.formTypeInterface.type,
                'cAppTypeInterfaceDesc': this.formTypeInterface.description,
            })
            this.clearForm();
            this.dialogCreateAppTypeInterface = false;
            this.getTypeInterfaces();
            var msg = 'Le type d\'interface a été créé avec succès';
            this.$swal({
                type: 'success',
                title: 'Succès',
                icon: 'success',
                text: msg,
                confirmButtonText: 'Ok',
            })
        },

        openDialogUpdateAppTypeInterface(item, typeAction) {
            this.dialogCreateAppTypeInterface = true;
            this.formTypeInterface.type = item.cAppTypeInterface;
            this.formTypeInterface.description = item.cAppTypeInterfaceDesc;
            this.formTypeInterface.id = item.pAppTypeInterface;
            this.typeAction = typeAction;
        },

        async updateTypeInterface() {
            await httpPost('/admAppTypesInterfaces/update', {
                pAppTypeInterface: this.formTypeInterface.id,
                cAppTypeInterface: this.formTypeInterface.type,
                cAppTypeInterfaceDesc: this.formTypeInterface.description,
            })
            this.clearForm();
            this.dialogCreateAppTypeInterface = false;
            this.getTypeInterfaces();
            var msg = 'Le type d\'interface a été modifié avec succès';
            this.$swal({
                type: 'success',
                title: 'Succès',
                icon: 'success',
                text: msg,
                confirmButtonText: 'Ok',
            })
        },

        clearForm() {
            this.formTypeInterface.type = '';
            this.formTypeInterface.description = '';
            this.formTypeInterface.id = '';
        },
    },
}
                


