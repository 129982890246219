export default {
    name: 'groupesComponent',
    props: ['list', 'unite'],

    data() {
        return {
            on: false,
            isloading: false,
            groupes: [],
            itemModifyStat: {},
            search: '',
            dialogCreateGroupe: false,
            dialogChangeStat: false,
            valid: true,
            formGroupe: {
                nom: '',
                description: '',
                bActif: false,
            },
            messageError: '',
            snackbar: false,
            textSnackbar: '',
            colorSnackbar: '',
            colorBtnSnackbar: '',
            timeout: 2000,
            departementSelected: [],
            headers: [
                { text: 'Nom du groupe', value: 'cGroupe' },
                { text: 'Actions', value: 'modifier', sortable: false, align: 'center' },
            ],
        }
    },
    methods: {
        editGroupe(item) {
            this.$store.state.modalCreate.title = this.unite.cDepartement;
            this.$emit('openDialogCreateGroupe', item);
        },
    },
}
