import { httpRequest } from "@/services/httpService";

export default {
    name: "GrillesPreview",
    props: {
        grilleBlocks: {
            type: Array,
            required: true,
        },
        grilleRows: {
            type: Array,
            required: true,
        },
        grilleColumns: {
            type: Array,
            required: true,
        },
        grilleBlockRowsColumns: {
            type: Array,
            required: true,
        },
        correction: {
            type: Array,
            required: false,
        },
    },
    created() {
        this.getGrille();
    },
    data() {
        return {
            cGrille: "",
            cGrilleDesc: "",
            cGrilleLangue: "",
            cGrilleCompetence: "",
            pasEvalue: false,
        }
    },

    methods: {
        filterRow(id) {
            // filter grilleBlockRowsColumns by row
            console.log("filterRow", id);
            console.log("grilleBlockRowsColumns", this.grilleBlockRowsColumns);
            const t = this.grilleBlockRowsColumns.filter((item) => item.pGrilleBlockRows === id);
            console.log("filterRow", t);
            return t;
        },
        filterByBlock(block) {
            // filter row by block
            console.info("filterByBlock", block);
            return this.grilleRows.filter((item) => item.pBlock === block);
        },
        result(value) {
            console.log("result", value);
            this.$emit('result', {
                value
            });
        },
        getGrille() {
            console.log("Estoy en getGrille");
            console.log("pGrille ---> ", this.grilleBlocks.message[0].pGrille);
            const response = httpRequest('/grilles/' + this.grilleBlocks[0].pGrille)
            if (response.status) {
                this.cGrille = response.message.cGrille;
                this.cGrilleDesc = response.message.cGrilleDesc;
                this.cGrilleLangue = response.message.cGrilleLangue;
                this.cGrilleCompetence = response.message.cGrilleCompetence;
            } else {
                this.$swal({
                    title: "Erreur",
                    text: response.message,
                    type: "error",
                    icon: "error",
                    confirmButtonText: "OK",
                });
            }
        },
    },
};