import { httpRequest, httpPost } from '@/services/httpService';

export default {
    name: 'Competences',
    data() {
        return {
            on: true,
            valid: false,
            isloading: false,
            itemsPerPage: 10,
            competences: [],
            loading: false,
            search: '',
            headers: [
                { text: 'Compétence', value: 'cCompetence' },
                { text: 'Actions', value: 'modifier', sortable: false, align: 'center' }
            ],
            dialogCreateCompetence: false,
            form: {
                competence: '',
                id: '',
            },
            typeAction: '',
        }
    },
    created() {
        this.getCompetences();
    },
    methods: {
        getCompetences() {
            this.loading = true;
            httpRequest('/competences').then(response => {
                this.competences = response.data;
                this.loading = false;
            })
        },
        openDialogCreateCompetence(item, typeAction) {
            this.typeAction = typeAction;
            this.dialogCreateCompetence = true;
        },
        openDialogUpdateCompetence(item, typeAction) {
            this.typeAction = typeAction;
            this.form.competence = item.cCompetence;
            this.form.id = item.pCompetence;
            this.dialogCreateCompetence = true;
        },
        createUpdateCompetence() {
            if (this.typeAction === 'create') {
                this.searchCompetence();
            }
            else {
                this.updateCompetence();
            }
        },
        async searchCompetence() {
            console.log('searchCompetence', this.form.competence);
            const response = await httpPost('/competences/search', { 'cCompetence': this.form.competence });
            if (!response.status) {
                console.log("Encontrado");
                this.createCompetence();
            } else {
                this.$swal({
                    title: 'Erreur',
                    text: 'Cette compétence existe déjà',
                    type: 'error',
                    icon: 'error',
                    confirmButtonText: 'Ok'
                });
            }
        },
        async createCompetence() {
            const response = await httpPost('/competences/create', { 'cCompetence': this.form.competence });
            if (response.status) {
                this.$swal({
                    title: 'Succès',
                    text: 'La compétence a été créée',
                    type: 'success',
                    icon: 'success',
                    confirmButtonText: 'Ok'
                });

            } else {
                this.$swal({
                    title: 'Erreur',
                    text: 'Une erreur est survenue',
                    type: 'error',
                    icon: 'error',
                    confirmButtonText: 'Ok'
                });
            }
            this.getCompetences();
            this.closeDialogCreateCompetence();
        },
        async updateCompetence() {
            const response = await httpPost('/competences/update',
                {
                    'cCompetence': this.form.competence,
                    'pCompetence': this.form.id
                });
            if (response.status) {
                this.$swal({
                    title: 'Succès',
                    text: 'La compétence a été modifiée',
                    type: 'success',
                    icon: 'success',
                    confirmButtonText: 'Ok'
                });
            } else {
                this.$swal({
                    title: 'Erreur',
                    text: 'Une erreur est survenue',
                    type: 'error',
                    icon: 'error',
                    confirmButtonText: 'Ok'
                });
            }
            this.getCompetences();
            this.closeDialogCreateCompetence();
        },
        deleteCompetence(item) {
            this.$swal({
                title: 'Êtes-vous sûr ?',
                text: 'La compétence sera supprimée',
                type: 'warning',
                icon: 'warning',
                showCancelButton: true,
                confirmButtonText: 'Oui, supprimez-la!',
                cancelButtonText: 'Non, gardez-la'
            }).then((result) => {
                if (result.value) {
                    httpPost('/competences/delete', { 'pCompetence': item.pCompetence });
                    this.$swal(
                        'Supprimé!',
                        'La compétence a été supprimée.',
                        'success'
                    );
                    this.getCompetences();
                }
            })
        },
        closeDialogCreateCompetence() {
            this.clearForm();
            this.dialogCreateCompetence = false;
        },
        clearForm() {
            this.$refs.observer.reset();
            this.form.competence = '';
            this.form.id = '';
            this.typeAction = '';
        },
    }
}

