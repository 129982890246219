<template>
<v-app>
  <div class="text-center">
    <v-dialog
      v-model="show"
      width="500"
    >      
      <v-card>
        <v-card-title class="text-h5 grey lighten-2">
          {{ title }}
        </v-card-title>

        <v-card-text>
         {{ message }}
        </v-card-text>

        <v-divider></v-divider>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            color="primary"
            text
            @click="closeDialog"
          >
            I accept
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
  </v-app>
</template>
<script>
  export default {
    name: "AlertModal",
    props: {
      show: {
        type: Boolean,
        default: false
      }
    },
    methods: {
      closeDialog() {
        this.$emit('close');
      }
    }   
  }
</script>