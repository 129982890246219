<template>
<v-app>
  <v-dialog v-model="show" persistent max-width="550px">
    <v-card>
      <v-card-title class="text-h5">{{ message }} </v-card-title>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn color="blue darken-1" text @click="close">Annuler</v-btn>
        <v-btn color="blue darken-1" text @click="confirm">Oui</v-btn>
        <v-spacer></v-spacer>
      </v-card-actions>
    </v-card>
  </v-dialog>
  </v-app>
</template>
<script>
export default {
  name: "ConfirmModal",
  props: {
    message: {
      type: String,
      default: 'confirm ?',
    },
    show: {
      type: Boolean,
      default: false,
    },
  },
  created() {},
  methods: {
    close() {
      this.$emit("close");
    },
    confirm() {
      this.$emit("confirm");
    },
  },
};
</script>
