import "vue-loading-overlay/dist/vue-loading.css";
import { httpRequest, httpPost } from "@/services/httpService";

export default {
  name: "RolesRessouces",
  created() {
    this.getRoles();
    this.getRolesRessources();
  },
  data() {
    return {
      on: true,
      isloading: false,
      itemsPerPage: 10,
      roles: [],
      rolesRessources: [],
      ressources: [
        "dashboard",
        "users",
        "roles/ressources",
        "unites",
        "appinterface",
        "apptypeinterface",
        "grilleregles",
        "reports",
        "grilles",
      ],
      ressourcesSelected: [],
      search: "",
      headers: [
        { text: "Rôle", value: "cRole" },
        { text: "Nom du ressource", value: "cRessource" },
        {
          text: "Actions",
          value: "modifier",
          sortable: false,
          align: "center",
        },
      ],
      dialogCreateRolesRessources: false,
      formRolesRessources: {
        cRolesRessources: "",
        pRoles: "",
        cRole: "",
        cRessource: "",
        id: "",
        bActif: false,
      },
      typeAction: "",
    };
  },
  computed: {
    allRessources() {
      return (
        this.formRolesRessources.cRessource.length === this.ressources.length
      );
    },
    someRessources() {
      return (
        this.formRolesRessources.cRessource.length > 0 && !this.allRessources
      );
    },
    icon() {
      if (this.allRessources) return "mdi-close-box";
      if (this.someRessources) return "mdi-minus-box";
      return "mdi-checkbox-blank-outline";
    },
  },
  methods: {
    toggle() {
      this.$nextTick(() => {
        if (this.allRessources) {
          this.formRolesRessources.cRessource = [];
        } else {
          this.formRolesRessources.cRessource = this.ressources.slice();
        }
      });
    },
    getRoles() {
      this.isloading = true;
      httpRequest("/roles").then((response) => {
        this.roles = response.data;
        this.isloading = false;
      });
      console.log("Roles ", this.roles);
    },
    getRolesRessources() {
      this.isloading = true;
      httpRequest("/admRolesRessources").then((response) => {
        this.rolesRessources = response.data;
        this.isloading = false;
      });
      console.log("RolesRessources ", this.rolesRessources);
    },

    openDialogCreateRoleRessource(item, typeAction) {
      this.typeAction = typeAction;
      this.dialogCreateRolesRessources = true;
    },
    closeDialogCreateRolesRessources() {
      this.clearForm();
      this.dialogCreateRolesRessources = false;
    },

    createUpdateRolesRessources() {
      if (this.typeAction == "create") {
        this.searchRolesRessources();
      } else {
        this.updateRolesRessources();
      }
    },
    async searchRolesRessources() {
      const response = await  httpPost("/admRolesRessources/searchRoles", {
        pRoles: this.formRolesRessources.pRoles,
      });
      if (!response.status) {
        this.createRolesRessources();
      } else {
        this.$swal({
          position: "bottom",
          icon: "warning",
          title: "Cette ressource existe déjà",
          timer: 2500,
        });
      }
    },

    async createRolesRessources() {
      await httpPost("/admRolesRessources/create", {
        cRolesRessources: this.formRolesRessources.cRolesRessources,
        pRoles: this.formRolesRessources.pRoles,
        cRessource: this.formRolesRessources.cRessource.toString(),
        bActif: this.formRolesRessources.bActif,
      });
      this.getRolesRessources();
      this.clearForm();
      this.dialogCreateRolesRessources = false;
    },

    async updateRolesRessources() {
      await httpPost("/admRolesRessources/update", {
        cRolesRessources: this.formRolesRessources.cRolesRessources,
        cRessource: this.formRolesRessources.cRessource,
        pRoles: this.formRolesRessources.pRoles,
        bActif: this.formRolesRessources.bActif,
      });
      this.clearForm();
      this.dialogCreateRolesRessources = false;
      this.getRolesRessources();
      this.$swal({
        type: "success",
        title: "Succès",
        icon: "success",
        text: "Ressource modifiée avec succès",
        confirmButtonText: "Ok",
      });
    },
    
    openDialogUpdateRolesRessources(item, typeAction) {
      this.dialogCreateRolesRessources = true;
      this.typeAction = typeAction;
      this.formRolesRessources.cRolesRessources = item.cRolesRessources;
      
      const ressources = item.cRessource.split(",");
      this.formRolesRessources.cRessource = ressources;

      this.formRolesRessources.pRoles = item.pRoles;
      this.formRolesRessources.bActif = item.bActif;
    },

    clearForm() {
      this.formRolesRessources.cRolesRessources = "";
      this.formRolesRessources.pRoles = "";
      this.formRolesRessources.cRoles = "";
      this.formRolesRessources.cRessource = "";
      this.formRolesRessources.bActif = false;
    },
  },
};
