import { httpRequest, httpPost } from "@/services/httpService";
export default {
  name: "GrillesHeader",
  props: {
    FormData: {
      type: Object,
      default: () => ({
        cGrille: "",
        cGrilleDesc: "",
        cGrilleLangue: "",
        cGrilleCompetence: "",
        pGrilleRegle: "",
        bActif: false,
      }),
    },
    type: {
      type: String,
      default: "create",
    },
  },
  data() {
    return {
      valid: false,
      competence: [],
      lang: [],
      grillesRegles: [],
      grilleExiste: false,
    };
  },
  created() {
    console.log("created formCreateGrille==============================");
    this.getLangues();
    this.getCompetences();
    this.getGrillesRegles();
    console.log(this.type);
  },
  methods: {
    saveGrille() {
      this.chercherGrille();
      console.log("saveGrille --->", this.grilleExiste);
      this.saveHeader();
    },

    async saveHeader() {
      // get blocks
      console.log("saveHeader==============================", this.type);
      if (this.type === "update") {
        console.log("saveHeader", this.FormData);
        await httpPost("/grilles/update", this.FormData);
        // emit
        this.$emit("Next");
      } else {
        console.log("saveHeader", this.FormData);
        if (!this.grilleExiste) {
          const response = await httpPost("/grilles/create", this.FormData);
          console.log("response", response);
          window.location.href = "/grilles?id=" + response.id;
        } else {
          this.$swal({
            type: "error",
            title: "Erreur",
            text: "Il existe déjà une grille avec ces paramètres",
            icon: "error",
            confirmationButtonText: "OK",
          });
        }
      }
    },

    async chercherGrille() {
      const response = await httpPost("/grilles/searchgrillelanguecompetence", {
        cGrilleLangue: this.FormData.cGrilleLangue,
        cGrilleCompetence: this.FormData.cGrilleCompetence,
        bActif: 1,
      });
      this.grilleExiste = response.status;
      console.log("var grilleExiste", this.grilleExiste);
    },

    // getInterfaces() {
    //   this.isloading = true;
    //   httpRequest("/admAppInterfaces").then((response) => {
    //     this.lang = response.data;
    //   });
    // },
    getLangues() {
      this.isloading = true;
      httpRequest("/langues").then((response) => {
        this.lang = response.data;
      });
    },
    getCompetences() {
      this.isloading = true;
      httpRequest("/competences").then((response) => {
        this.competence = response.data;
      });
    },
    getGrillesRegles() {
      this.loading = true;
      httpRequest("/admgrilleregles").then((response) => {
        this.grillesRegles = response.data;
      });
    },
  },
};
