import axios from 'axios';
import { email, required } from 'vee-validate/dist/rules'
import { extend, ValidationObserver, ValidationProvider } from 'vee-validate'
import { httpPost } from '../../../services/httpService';

const URL = 'http://127.0.0.1:8000';

extend('email', {
    ...email,
    message: 'Veuillez entrer un courriel valide',
  })

  extend('required', {
    ...required,
    message: '{_field_} ce champ est requis',
  })

export default {
    name: "register",  
    
    components: {
        ValidationProvider,
        ValidationObserver,
      },

    // create component
    created() {
        this.getDepartement();
    },

    // données du composant
    data() {
        return {
            info: '',
            valid: false,
            reset: false,
            listDepartements: [],
            listGroupes: [],
            form: {
                name: '',
                lastName: '',
                departement:'',
                groupe:'',
                email: '',                
                active: false
            },
            error: {
                name: false,
                email: false,
                password: false,
                password_confirmation: false,
                active: false
            },
            rules: {
                

            },
            loading: false
        }
    },

    methods: {
        register() {

            httpPost('/register',{
                cNom: this.form.lastName,
                cPrenom: this.form.name,
                pGroupe: this.form.groupe,
                cCourriel: this.form.email,
                cPassword: '12345',
                bActif: this.form.active
            })
            // axios get request  
            // axios
            //     .post(URL+'/api/register',
            //     {
            //         cNom: this.form.lastName,
            //         cPrenom: this.form.name,
            //         pGroupe: this.form.groupe,
            //         cCourriel: this.form.email,
            //         cPassword: '12345',
            //         bActif: this.form.active
            //     })
            .then(response => {
                // response .status succes modal swal
                this.loading = false;
                if (response.status === 'success') {
                    this.$swal({
                        title: 'Succès',
                        text: 'Vous êtes inscrits avec succès',
                        type: 'success',
                        icon: 'success',
                        confirmButtonText: 'Ok'
                    })
                    this.$router.push('/users');
                }
                
            })  
        },
        validateUQAM() {
            console.log('validateUQAM');
            //axios get request
            axios.get('https://apipersonnes.dev.uqam.ca/v1/personnes?mail=' + this.form.email)
            .then(response => (this.info = response))
           
        },
        getDepartement() {
            axios
                .get(URL+'/api/departements')
                .then(response => (this.listDepartements = response.data))
        },
        getGroupes(id) {
            console.log('getGroupes');
            axios
                .get(URL+'/api/groupes/dept/'+id)
                .then(response => (this.listGroupes = response.data))
        },
    },
  };