import 'vue-loading-overlay/dist/vue-loading.css';
import { httpRequest, httpPost } from '@/services/httpService';
import { extend, ValidationObserver, ValidationProvider } from 'vee-validate'
import { numeric, required } from 'vee-validate/dist/rules'

extend('numeric', {
    ...numeric,
    message: 'Veuillez entrer un nombre valide',
})

extend('required', {
    ...required,
    message: '{_field_} ce champ est requis',
})

export default {
    name: 'GrillesRegles',
    components: {
        ValidationProvider,
        ValidationObserver,
      },
    created() {
        this.getGrillesRegles();
    },
    data() {
        return {
            on: true,
            valid: false,
            isloading: false,
            itemsPerPage: 10,
            grillesRegles: [],
            loading: false,
            search: '',
            headers: [
                { text: 'Nom de la règle', value: 'cGrilleRegle' },
                { text: 'Type de règle', value: 'cTypeRegle' },
                { text: 'Valeur', value: 'nValeur' },
                { text: 'Actions', value: 'modifier', sortable: false, align: 'center' }
            ],
            dialogCreateGrilleRegle: false,
            formRegles: {
                nom: '',
                type: '',
                valeur: '',
                description: '',
                bActif: false,
                id: '',
            },
            typeAction: '',
            typesRegles: ['Pourcentage', 'Chiffre'],
        }
    },
    methods: {
        getGrillesRegles() {
            this.loading = true;
            httpRequest('/admgrilleregles')
                .then(response => {
                    this.grillesRegles = response.data;
                    this.loading = false;
                })
        },

        createUpdateGrilleRegle() {
            if (this.typeAction == 'create') {
                this.searchGrilleRegle();
            } else {
                this.updateGrilleRegle();
            }
        },

        async searchGrilleRegle() {
            const response = await httpPost('/admgrilleregles/search', { 'cGrilleRegle': this.formRegles.nom });
            if (!response.status) {
                this.createGrilleRegle();
            } else {
                this.$swal({
                    position: 'bottom',
                    icon: 'warning',
                    title: 'Cette règle existe déjà',
                    showConfirmButton: false,
                    timer: 2500
                })
            }
        },

        async createGrilleRegle() {
            await httpPost('/admgrilleregles/create', {
                cGrilleRegle: this.formRegles.nom,
                cTypeRegle: this.formRegles.type,
                nValeur: this.formRegles.valeur,
                cGrilleRegleDesc: this.formRegles.description,
                bActif: this.formRegles.bActif
            });
            this.clearForm();
            this.dialogCreateGrilleRegle = false;
            this.getGrillesRegles();
            this.$swal({
                type: 'success',
                title: 'Succès',
                icon: 'success',
                text: 'La règle a été créée avec succès',
                confirmButtonText: 'Ok',
            })
        },

        async updateGrilleRegle() {
            await httpPost('/admgrilleregles/update', {
                pGrilleRegle: this.formRegles.id,
                cGrilleRegle: this.formRegles.nom,
                cTypeRegle: this.formRegles.type,
                nValeur: this.formRegles.valeur,
                cGrilleRegleDesc: this.formRegles.description,
                bActif: this.formRegles.bActif
            });
            this.clearForm();
            this.dialogCreateGrilleRegle = false;
            this.getGrillesRegles();
            this.$swal({
                type: 'success',
                title: 'Succès',
                icon: 'success',
                text: 'La règle a été modifiée avec succès',
                confirmButtonText: 'Ok',
            })
        },

        openDialogCreateGrilleRegle(item, typeAction) {
            this.typeAction = typeAction;
            this.dialogCreateGrilleRegle = true;
        },

        closeDialogCreateGrilleRegle() {
            this.clearForm();
            this.dialogCreateGrilleRegle = false;
        },

        openDialogUpdateGrilleRegle(item, TypeAction) {
            this.formRegles.nom = item.cGrilleRegle;
            this.formRegles.type = item.cTypeRegle;
            this.formRegles.valeur = item.nValeur;
            this.formRegles.description = item.cGrilleRegleDesc;
            this.formRegles.bActif = parseInt(item.bActif); 
            this.formRegles.id = item.pGrilleRegle;
            this.typeAction = TypeAction;
            this.dialogCreateGrilleRegle = true;
        },

        clearForm() {
            this.$refs.observer.reset();
            this.formRegles.nom = '';
            this.formRegles.type = '';
            this.formRegles.valeur = '';
            this.formRegles.description = '';
            this.formRegles.bActif = false;
            this.typeAction = '';
        },

    },
}



