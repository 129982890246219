<!-- page vue -->
<template>
  <v-dialog v-model="$store.state.modalRoles.show" persistent max-width="600px">
    <v-card class="mx-auto">
      <v-card-title class="primary">
          <span class="text-h5 white--text">
             Rôles de l'utilisateur :
            {{ user ? user.cPrenom + " " + user.cNom : "" }}
          </span>
      </v-card-title>

      <!-- <v-toolbar dark color="primary">
        <v-toolbar-title>
          <span class="text-h5">
            Roles de l'utilisateur :
            {{ user ? user.cPrenom + " " + user.cNom : "" }}
          </span>
        </v-toolbar-title>
      </v-toolbar> -->
      <v-card-text class="mt-4">
        <v-simple-table v-if="roles.length > 0" dense>
          <thead>
            <tr>
              <th class="text-left">Rôle</th>
              <th class="text-left">Actif</th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="item in roles" :key="item.name">
              <td>{{ item.cRoles }}</td>
              <td>
                <v-checkbox v-model="item.active"></v-checkbox>
              </td>
            </tr>
          </tbody>
        </v-simple-table>
        <Error500 v-if="!roles.length > 0"></Error500>
      </v-card-text>
      <v-card-actions>
        <v-btn class="ml-3 mr-4 mt-3 mb-3" color="error" @click="cancel">Annuler</v-btn>
        <v-btn class="mt-3 mb-3" color="primary" @click="saveRole"
          >Enregistrer</v-btn
        >
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
// vue component
import Error500 from "@/components/Error500.vue";
import { httpPost } from "@/services/httpService";

export default {
  name: "Roles",
  components: {
    Error500,
  },
  props: {
    user: {
      type: Object,
      required: true,
    },
    roles: {
      type: Array,
      required: true,
    },
  },
  data() {
    return {
      pUser: "",
      name: "",
      isloading: false,
      fullPage: true,
      modalOpen: false,
      modalContent: "",
      modalTitle: "",
      cNomComplet: "",
    };
  },
  methods: {
    async saveRole() {
      let listRoles = [];
      let pUser = this.user.pUser;
      this.roles.map((rol) => {
        if (rol.active === true) {
          listRoles.push(rol.pRoles);
        }
      });
      await httpPost("/userroles/create", { pUser, listRoles })
        .then((response) => {
          console.log(response);
          this.$swal({
            title: "Succès",
            text: "Le rôle a été enregistré",
            icon: "success",
            type: "success",
            confirmButtonText: "Ok",
          });
        })
        .catch((error) => {
          console.log(error);
          this.$swal({
            title: "Erreur",
            icon: "error",
            text: "Le rôle n'a pas été enregistré",
            type: "error",
            confirmButtonText: "Ok",
          });
        });
      this.$store.dispatch("setModalRoles", false);
    },
    cancel() {
      this.roles.map((rol) => {
        rol.active = false;
      });
      this.$store.dispatch("setModalRoles", false);
    },
  },
};
</script>
