import "vue-loading-overlay/dist/vue-loading.css";
import { httpRequest } from '@/services/httpService';
import createRapportComponent from '@/views/private/reports/formCreateRapport.vue';

export default {
  name: "Rapports",
  
  components : {
    createRapportComponent,
  },

  created() {
    this.getRapports();
  },

  data() {
    return {
      on: true,
      isloading: false,
      itemsPerPage: 10,
      search: "",
      listQuery: [],
      headers: [
        { text: "Nom de la requête", value: "cQueryBuilder" },
        { text: "Actions", value: "modifier", sortable: false, align: "center" },
      ],
      dialogCreateRapport: false,
      typeActionRapport: "",
    };
  },
  
  methods: {
    getRapports() {
      this.isloading = true;
      httpRequest("/admQueryBuilders")
        .then((response) => {
          this.listQuery = response.data;
          this.isloading = false;
        })
    },

    openDialogCreateRapport(item, typeAction) {
      this.$store.state.modalCreate.title = "Création d'une requête";
      this.typeActionRapport = typeAction;
      this.$store.state.modalCreate.show = true;
    }
  },
}