<template>
  <Bar
    :chart-options="chartOptions"
    :chart-data="chartData"
    :chart-id="chartId"
    :dataset-id-key="datasetIdKey"
    :plugins="plugins"
    :css-classes="cssClasses"
    :styles="styles"
    :width="width"
    :height="height"
  />
</template>

<script>
import { Bar } from 'vue-chartjs/legacy'

import {
  Chart as ChartJS,
  Title,
  Tooltip,
  Legend,
  BarElement,
  CategoryScale,
  LinearScale
} from 'chart.js'

ChartJS.register(Title, Tooltip, Legend, BarElement, CategoryScale, LinearScale)

export default {
  name: 'BarChart',
  components: {
    Bar
  },
  props: {
    chartId: {
      type: String,
      default: 'bar-chart'
    },
    datasetIdKey: {
      type: String,
      default: 'label'
    },
    width: {
      type: Number,
      default: 400
    },
    height: {
      type: Number,
      default: 400
    },
    cssClasses: {
      default: '',
      type: String
    },
    styles: {
      type: Object,
      default: () => {}
    },
    plugins: {
      type: Array,
      default: () => []
    },
    resultatsData: {
      type: Array,
      required: true,
    },
  },
  data() {
    return {
      chartOptions: {
        responsive: true,
        maintainAspectRatio: false,
        plugins: {
          legend: {
            position: 'bottom'
          },
          title: {
            display: true,
            text: 'Nombre de grilles traitées par mois (année en cours)'
          }
        }
      }
    }
  },
  methods: {
    getYear() {
      return new Date().getFullYear()
    }
  },
  computed: {
    chartData() {
      return {
        labels: this.resultatsData.map((item) => item.nMois),
        datasets: [
          {
            label: 'Grilles traitées (année ' + this.getYear() + ')',
            data: this.resultatsData.map((item) => item.compte),
            backgroundColor: [
              'rgba(65, 184, 131, 0.2)',
              'rgba(228, 102, 81, 0.2)',
              'rgba(0, 216, 255, 0.2)',
              'rgba(221, 27, 22, 0.2)',
            ],
            borderColor: [
              'rgb(65, 184, 131)',
              'rgb(228, 102, 81)',
              'rgb(0, 216, 255)',
              'rgb(221, 27, 22)',
            ],
            borderWidth: 1
          }
        ]
      }
    }
  }
}
</script>
