// vue component
import Loading from 'vue-loading-overlay';
import 'vue-loading-overlay/dist/vue-loading.css';
import { httpRequest, httpPost } from '@/services/httpService';
import modalInterface  from '@/components/userInterface.vue';
import modalRoles from '@/components/modalRoles.vue';
import createUserComponent from '@/views/private/signup/CreateUser.vue';

export default {
    name: 'Users',
    components: {
        Loading,
        modalInterface,
        modalRoles,
        createUserComponent
    },
    created() {
        this.getUsers();
        this.getRoles();
    },
    data() {
        return {
            on: true,
            usersData: [],
            listInterface: [], // users interface from the other software
            roles: [],
            userEdit:{},
            isloading: false,
            itemsPerPage: 10,
            search: '',
            itemModifyStat: {},
            dialogDelete: false,
            modalInterfaceShow: false,
            messageModal: '',
            typeAction: '',
            headers: [
                { text: 'Nom d\'utilisateur', value: 'cNomComplet' },
                { text: 'Courriel', value: 'cCourriel' },                               
                { text: 'Actions', value: 'modifier', sortable: false, align: 'center'},
            ],
            items: [
                {
                    icon: 'mdi-account-multiple',
                    text: 'Users',
                },
                {
                    icon: 'mdi-account-multiple',
                    text: 'Users2',
                },
            ],
        }
    },
    
    methods: {
        getUsers() {
            this.isloading = true;
            httpRequest('/users')
                .then(response => {
                    //console.log("users", response.data);
                    this.usersData = response.data;
                    this.isloading = false;
                })
        },
        createEditUser(typeAction, item) {
            this.typeAction = typeAction;
            this.userEdit = item;
            this.userEdit.bActif = parseInt(item.bActif);
            this.$store.state.modalCreate.show = true;
        },
        getRoles() {
            httpRequest("/roles")
              .then((response) => {
                this.roles = response.data;
              })
              .catch((error) => {
                console.log(error);
              });
          },

        async editRole(event) {
            this.userEdit = event;
            await this.getUserRoles(this.roles, event.pUser);
            this.$forceUpdate();
            this.$store.dispatch('setModalRoles', { show: true })
        },
        async getUserRoles(roles, user) {
            const response = await httpPost("/userroles/searchuser", {
              pUser: user,
            });
            if (response) {
              const userRoles = JSON.parse(response.pRoles);
              for (const role of roles) {
                const hasRole = userRoles.includes(role.pRoles);
                role.active = hasRole;
              }
              return roles;
            }
          },
       
        deleteItem(item) {
            //console.log(item.bActif);
            if (item.bActif) {
                this.$swal({
                    title: 'Êtes-vous sûr?',
                    text: 'Voulez-vous vraiment désactiver l\'utilisateur ' + item.cNomComplet + ' ?',
                    type: 'warning',
                    icon: 'warning',
                    showCancelButton: true,
                    confirmButtonText: 'Oui, désactiver!',
                    cancelButtonText: 'Non, annuler!',
                    reverseButtons: true
                }).then((result) => {
                    if (result.value) {
                        this.itemModifyStat = { pUser: item.pUser, bActif: 0 };                        
                        this.deleteItemConfirm();
                        this.$swal('Désactivé!', 'L\'utilisateur ' + item.cNomComplet + ' a été désactivé.', 'success');
                    }
                })

            } else {
                this.$swal({
                    title: 'Êtes-vous sûr?',
                    text: 'Voulez-vous vraiment activer l\'utilisateur ' + item.cNomComplet + ' ?',
                    type: 'warning',
                    icon: 'warning',
                    showCancelButton: true,
                    confirmButtonText: 'Oui, activer!',
                    cancelButtonText: 'Non, annuler!',
                    reverseButtons: true
                }).then((result) => {
                    if (result.value) {
                        this.itemModifyStat = { pUser: item.pUser, bActif: 1 };                        
                        this.deleteItemConfirm();
                        this.$swal('Activer', 'L\'utilisateur ' + item.cNomComplet + ' a été activé', 'success');
                    }
                })
            }
            this.dialogDelete = true
        },

        closeDelete() {
            this.dialogDelete = false;
        },
        async deleteItemConfirm() {
            await httpPost('/users/updatebActif', this.itemModifyStat)
            this.closeDelete()
            this.getUsers();
        },
        linkUtilisateur(item) { 
            this.userEdit = item;
            this.getListInterface();           
            this.$store.dispatch('setModalInterface', { show: true, user: item });
            this.getUsers();
        },
        async getListInterface() {
            const response = await httpPost('/admAppInterfaces/indexAppInterface', {bActif:1, cType:'Utilisateurs'});
            if (response) {
                this.listInterface = response;
            }
        }
    },
}