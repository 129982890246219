import { httpRequest, httpPost, httpGet } from "@/services/httpService";

export default {
  name: "GrilleEchantillon",
  created() {
    this.getLangues();
    this.getCompetences();
    this.dDateCorrectionDebut = this.getDate();
    if (this.$route.query.id) {
      this.newEchantillon = false;
      this.pEchantillon = this.$route.query.id;
      this.getEchantillon(this.pEchantillon);
    }
    const user = localStorage.getItem("user");
      if (user) {
        const userData = JSON.parse(user);
        this.pUser = userData.pUser;
      }
  },
  watch: {
    cTypeResponse() {
      if (this.cTypeResponse != "question") {
        this.cTypeResponseDetail = "";
      }
      this.validStepEntete = true;
    },
    cTypeResponseDetail() {
      this.validStepEntete = true;
    },
  },
  data() {
    return {
      isloading: false,
      curr: 1,
      lastStep: 4,
      textRules: [(v) => !!v || "Cet élément est obligatoire"],
      validStepEntete: true,
      validStepQuestions: true,
      validStepGrille: true,
      langues: [],
      competences: [],
      pEchantillon: "",
      cEchantillon: "",
      cEchantillonLangue: "",
      cEchantillonCompetence: "",
      testResultID: "",
      evaluatorName: "",
      cTypeResponse: "",
      cTypeResponseDetail: "",
      cGrilleAttribuee: "",
      bActif: false,
      newEchantillon: true,
      questions: [
        {
          pGrilleEchantillonQuestion: "",
          cLangue: "",
          cCompetence: "",
          cGrilleEchantillonQuestion: "",
          cGrilleEchantillonCommentaires: "",
          pGrilleEchantillon: "",
          bAttribution: false,
        },
      ],
      questionsR: [
        {
          pGrilleEchantillonQuestion: "",
          cLangue: "",
          cCompetence: "",
          cGrilleEchantillonQuestion: "",
          cGrilleEchantillonCommentaires: "",
          pGrilleEchantillon: "",
          bAttribution: false,
          response: "",
        },
      ],
      blocs: [
        {
          pGrilleBlock: "",
          cGrilleBlock: "",
          cGrilleBlockDesc: "",
          pGrille: "",
          response: "",
          pGrilleEchantillon: "",
          pGrilleBlockRows: "",
          pGrilleEchantillonQuestion: "",
        },
      ],
      grille: [],

      grilleColumns: [],
      grilleBlocs: [],
      grilleBlocsLignes: [],
      grilleCellules: [],
      previewLignes: [
        {
          typeLigne: "",
          previewColumns: [
            {
              indexCelluleStyle: 0,
              selected: false,
              pGrilleBlockRowColumns: "",
              nValue: "",
            },
          ],
        },
      ],
      grillePasEvalue: false,
      textGrillePasEvalue: "",
      cType: "echantillon",
      pUser: "",
      grilleResultat: [],
      grilleResultatDetails: [],
      dDateCorrectionDebut: "",
      dDateCorrectionFin: "",
    };
  },
  methods: {
    getLangues() {
      httpRequest("/langues").then((response) => {
        this.langues = response.data;
      });
    },
    getCompetences() {
      httpRequest("/competences").then((response) => {
        this.competences = response.data;
      });
    },
    stepComplet(step) {
      return this.curr > step;
    },
    toListe() {
      this.$router.push("/echantillonlist");
    },

    async getEchantillon(pEchantillon) {
      const response = await httpPost("/echantillons/searchByEchantillonID", {
        pEchantillon: pEchantillon,
      });
      if (response.status) {
        this.cEchantillon = response.message.cEchantillon;
        this.cEchantillonLangue = response.message.cEchantillonLangue;
        this.cEchantillonCompetence = response.message.cEchantillonCompetence;
        this.testResultID = response.message.testResultID;
        this.evaluatorName = response.message.evaluatorName;
        this.cTypeResponse = response.message.cTypeResponse;
        this.cTypeResponseDetail = response.message.cTypeResponseDetail;
        this.cGrilleAttribuee = response.message.cGrilleAttribuee;
        this.bActif = parseInt(response.message.bActif);
      }
    },

    validateEchantillon() {
      this.validStepEntete = false;
      if (this.$refs.formEchantillon.validate()) {
        if (
          this.cTypeResponse === "question" &&
          this.cTypeResponseDetail === ""
        ) {
          this.$swal({
            title: "Erreur",
            text: "Vous devez sélectionner une type de réponse",
            type: "error",
            icon: "error",
            confirmButtonText: "Ok",
          });
          return;
        }
        this.validStepEntete = true;
        this.chercherEchantillon();
        this.getQuestions();
      }
    },
    async chercherEchantillon() {
      await httpPost("/echantillons/searchByEchantillon", {
        cEchantillon: this.cEchantillon,
        cEchantillonLangue: this.cEchantillonLangue,
        cEchantillonCompetence: this.cEchantillonCompetence,
        bActif: this.bActif,
      }).then((response) => {
        if (response.status && this.newEchantillon) {
          this.$swal({
            title: "Erreur",
            type: "error",
            icon: "error",
            text: "Il existe déjà un échantillon avec ces informations",
            confirmButtonText: "Ok",
          });
          this.curr = 1;
        } else {
          if (this.newEchantillon) {
            this.saveEchantillon("create");
          } else {
            this.saveEchantillon("update");
          }
          this.curr = 2;
        }
      });
    },
    async saveEchantillon(action) {
      if (action === "create") {
        const response = await httpPost("/echantillons/create", {
          cEchantillon: this.cEchantillon,
          cEchantillonLangue: this.cEchantillonLangue,
          cEchantillonCompetence: this.cEchantillonCompetence,
          testResultID: this.testResultID,
          evaluatorName: this.evaluatorName,
          cTypeResponse: this.cTypeResponse,
          cTypeResponseDetail: this.cTypeResponseDetail,
          cGrilleAttribuee: this.cGrilleAttribuee,
          bActif: this.bActif,
        });
        if (response.id) {
          this.pEchantillon = response.id;
          this.newEchantillon = false;
        }
      } else {
        await httpPost("/echantillons/update", {
          pEchantillon: parseInt(this.pEchantillon),
          cEchantillon: this.cEchantillon,
          cEchantillonLangue: this.cEchantillonLangue,
          cEchantillonCompetence: this.cEchantillonCompetence,
          testResultID: this.testResultID,
          evaluatorName: this.evaluatorName,
          cTypeResponse: this.cTypeResponse,
          cTypeResponseDetail: this.cTypeResponseDetail,
          cGrilleAttribuee: this.cGrilleAttribuee,
          bActif: this.bActif,
        });
      }
    },

    async getQuestions() {
      const response = await httpPost(
        "/grilleEchantillonQuestions/searchLangueCompetence",
        {
          cLangue: this.cEchantillonLangue,
          cCompetence: this.cEchantillonCompetence,
        }
      );
      if (response.status) {
        this.questions = [];
        for (let i = 0; i < response.message.length; i++) {
          this.questions.push({
            pGrilleEchantillonQuestion:
              response.message[i].pGrilleEchantillonQuestion,
            cLangue: response.message[i].cLangue,
            cCompetence: response.message[i].cCompetence,
            cGrilleEchantillonQuestion:
              response.message[i].cGrilleEchantillonQuestion,
            cGrilleEchantillonCommentaires:
              response.message[i].cGrilleEchantillonCommentaires,
            pGrilleEchantillon: this.pEchantillon,
            bAttribution: false,
          });
        }
        this.getQuestionsEchantillon();
      } else {
        this.$swal({
          title: "Erreur",
          text: "Il n'existe pas de questions pour cette langue et cette compétence",
          icon: "error",
          type: "error",
          confirmButtonText: "Ok",
        });
        this.curr = 1;
      }
    },

    async getQuestionsEchantillon() {
      const response = await httpPost(
        "/echantillonQuestionAttributions/searchBypGrilleEchantillon",
        {
          pGrilleEchantillon: parseInt(this.pEchantillon),
        }
      );
      if (response.status) {
        for (let i = 0; i < response.message.length; i++) {
          for (let j = 0; j < this.questions.length; j++) {
            if (
              parseInt(response.message[i].pGrilleEchantillonQuestion) ===
              this.questions[j].pGrilleEchantillonQuestion
            ) {
              this.questions[j].bAttribution = true;
            }
          }
        }
      }
    },

    validateQuestions() {
      this.validStepQuestions = false;
      let bAttribution = false;
      this.questions.forEach((question) => {
        if (question.bAttribution) {
          bAttribution = true;
        }
      });
      if (bAttribution) {
        //this.saveQuestions(this.questions);
        if (this.cTypeResponse === "bloc" || this.cTypeResponse === "ligne") {
          this.getBlocsLignes();
        } else {
          this.getQuestionsResponses(this.questions);
        }

        this.validStepQuestions = true;
        this.curr = 3;
      } else {
        this.$swal({
          title: "Erreur",
          text: "Vous devez sélectionner au moins une question",
          icon: "error",
          confirmButtonText: "Ok",
          type: "error",
        }).then(() => {
          this.curr = 2;
        });
      }
    },

    async saveQuestions(questions) {
      const questionsToSave = questions.filter(
        (question) => question.bAttribution
      );
      const response = await httpPost("/echantillonQuestionAttributions/createQuestionAttribution", {
          questions: questionsToSave,
        }
      );
      return response.status;
    },

    filterQuestions() {
      const filteredQuestions = this.questions.filter((question) => question.bAttribution);
      return filteredQuestions;
    },

    getBlocsLignes() {
      if (this.cTypeResponse === "bloc") {
        this.getBlocs();
      } else {
        this.getLignes();
      }
    },

    getQuestionsResponses(questions) {
      this.questionsR = [];
      questions.forEach((question) => {
        if (question.bAttribution) {
          this.questionsR.push({
            pGrilleEchantillonQuestion: question.pGrilleEchantillonQuestion,
            cLangue: question.cLangue,
            cCompetence: question.cCompetence,
            cGrilleEchantillonQuestion: question.cGrilleEchantillonQuestion,
            cGrilleEchantillonCommentaires:
              question.cGrilleEchantillonCommentaires,
            pGrilleEchantillon: this.pEchantillon,
            bAttribution: question.bAttribution,
            response: "",
          });
        }
      });
      this.getEchantillonReponses();
    },

    async getBlocs() {
      const response = await httpPost("/grilleBlocks/searchByLangueCompetence", {
          cGrilleLangue: this.cEchantillonLangue,
          cGrilleCompetence: this.cEchantillonCompetence,
        }
      );

      if (response.status) {
        if (response.message.length === 0) {
          this.$swal({
            title: "Erreur",
            text: "Il n'existe pas de blocs pour cette langue et cette compétence",
            icon: "error",
            type: "error",
            confirmButtonText: "Ok",
          }).then(() => {
            this.curr = 1;
          });
        } else {
          this.blocs = [];
          for (let i = 0; i < response.message.length; i++) {
            this.blocs.push({
              pGrilleBlock: response.message[i].pGrilleBlock,
              cGrilleBlock: response.message[i].cGrilleBlock,
              cGrilleBlockDesc: response.message[i].cGrilleBlockDesc,
              pGrille: response.message[i].pGrille,
              response: "",
              pGrilleBlockRows: "",
              pGrilleEchantillon: this.pEchantillon,
              pGrilleEchantillonQuestion: "",
            });
          }
          this.getEchantillonReponses();
        }
      } else {
        this.$swal({
          title: "Erreur",
          text: "Il n'existe pas de blocs pour cette langue et cette compétence",
          icon: "error",
          type: "error",
          confirmButtonText: "Ok",
        }).then(() => {
          this.curr = 1;
        });
      }
    },

    async getLignes() {
      const response = await httpPost("/grilleRows/searchByLangueCompetence", {
        cGrilleLangue: this.cEchantillonLangue,
        cGrilleCompetence: this.cEchantillonCompetence,
      });
      if (response.status) {
        if (response.message.length === 0) {
          this.$swal({
            title: "Erreur",
            text: "Il n'existe pas de lignes pour cette langue et cette compétence",
            icon: "error",
            type: "error",
            confirmButtonText: "Ok",
          }).then(() => {
            this.curr = 1;
          });
        } else {
          this.blocs = [];
          for (let i = 0; i < response.message.length; i++) {
            this.blocs.push({
              pGrilleBlock: "",
              cGrilleBlock:
                response.message[i].cGrilleBlock +
                " : " +
                response.message[i].cTitre,
              cGrilleBlockDesc: response.message[i].cSousTitre,
              pGrille: response.message[i].pGrille,
              response: "",
              pGrilleBlockRows: response.message[i].pGrilleBlockRows,
              pGrilleEchantillon: this.pEchantillon,
              pGrilleEchantillonQuestion: "",
            });
          }
          this.getEchantillonReponses();
        }
      } else {
        this.$swal({
          title: "Erreur",
          text: "Il n'existe pas des lignes pour cette langue et cette compétence",
          icon: "error",
          type: "error",
          confirmButtonText: "Ok",
        }).then(() => {
          this.curr = 1;
        });
      }
    },

    async validateResponses() {
      this.validStepResponses = false;
      let validation = false;
      if (this.cTypeResponse === "bloc" || this.cTypeResponse === "ligne") {
        this.blocs.forEach((bloc) => {
          if (bloc.response != "") {
            validation = true;
          }
        });
      } else {
        this.questionsR.forEach((question) => {
          if (question.response != "") {
            validation = true;
          }
        });
      }

      if (validation) {
        this.validStepResponses = true;
        const responseGrille = await this.getGrille(
          this.cEchantillonLangue,
          this.cEchantillonCompetence
        );
        if (this.cGrilleAttribuee === "oui" && responseGrille) {
            this.getGrilleResultat(parseInt(this.pEchantillon), this.cType);
            this.curr = 4;
        } else {
            this.curr = 5;
        }
      } else {
        this.$swal({
          title: "Erreur",
          text: "Vous devez saisir au moins une réponse",
          icon: "error",
          type: "error",
          confirmButtonText: "Ok",
        }).then(() => {
          this.curr = 3;
        });
      }
    },

    async getGrilleResultat(pEchantillon, cType) {
        const response = await httpPost("/grilleResultats/searchBypEchantillonCtype" , {
            pEchantillon: pEchantillon,
            cType: cType
        });
        if (response.status) {
            this.grilleResultat =  response.message;
            this.grillePasEvalue = parseInt(this.grilleResultat.bPasCorrigee);
            this.textGrillePasEvalue = this.grilleResultat.cPasCorrigee;
        }
    },

    async saveResponses(responses, type) {
      if (type === "bloc") {
        const responsesToSave = responses.filter((bloc) => bloc.response != "");
        const response = await httpPost("/echantillonQuestionReponses/createResponses", {
            responses: responsesToSave,
          }
        );
        return response.status;
      } else {
        let responsesToSave = this.transformerResponses(responses);
        const response = await httpPost("/echantillonQuestionReponses/createResponses", {
            responses: responsesToSave,
          }
        );
        return response.status;
      }
    },

    transformerResponses(responses) {
      let responsesTransformed = [];
      let responsesToSave = responses.filter(
        (response) => response.response != ""
      );
      responsesToSave.forEach((response) => {
        responsesTransformed.push({
          response: response.response,
          pGrilleEchantillon: parseInt(response.pGrilleEchantillon),
          pGrilleEchantillonQuestion: parseInt(
            response.pGrilleEchantillonQuestion
          ),
          pGrilleBlock: "",
          pGrilleBlockRows: "",
        });
      });
      return responsesTransformed;
    },

    async getEchantillonReponses() {
      const response = await httpPost("/echantillonQuestionReponses/searchBypGrilleEchantillon", {
          pGrilleEchantillon: parseInt(this.pEchantillon),
        }
      );
      if (response.status) {
        if (this.cTypeResponse === "bloc" || this.cTypeResponse === "ligne") {
          response.message.forEach((response) => {
            this.blocs.forEach((bloc) => {
              if (this.cTypeResponse === "bloc") {
                if (
                  parseInt(response.pGrilleBlock) ===
                  parseInt(bloc.pGrilleBlock)
                ) {
                  bloc.response = response.cGrilleEchantillonQuestionReponse;
                }
              } else {
                if (
                  parseInt(response.pGrilleBlockRows) ===
                  parseInt(bloc.pGrilleBlockRows)
                ) {
                  bloc.response = response.cGrilleEchantillonQuestionReponse;
                }
              }
            });
          });
        } else {
          response.message.forEach((response) => {
            this.questionsR.forEach((question) => {
              if (
                parseInt(response.pGrilleEchantillonQuestion) ===
                parseInt(question.pGrilleEchantillonQuestion)
              ) {
                question.response = response.cGrilleEchantillonQuestionReponse;
              }
            });
          });
        }
      }
      return response.status;
    },

    async saveEchantillonQuestionsResponses() {
      let bValidQ = false;
      let bValidR = false;
      let bValidG = false;

      bValidQ = await this.saveQuestions(this.questions);
      if (this.validationSaveResponses) {
        let type = "";
        if (this.cTypeResponse === "bloc" || this.cTypeResponse === "ligne") {
          type = "bloc";
          bValidR = await this.saveResponses(this.blocs, type);
        } else {
          type = "question";
          bValidR = await this.saveResponses(this.questionsR, type);
        }
      }

      if (this.cGrilleAttribuee === "oui") {
        bValidG = await this.saveGrilleEchantillon();
      }

      if (this.cGrilleAttribuee === "oui") {
        if (bValidQ && bValidR && bValidG) {
          this.$swal({
            title: "Succès",
            text: "L'échantillon a été sauvegardé",
            icon: "success",
            type: "success",
            confirmButtonText: "Ok",
          });
        } else {
          this.$swal({
            title: "Erreur",
            text: "Erreur lors de la sauvegarde de l'échantillon",
            icon: "error",
            type: "error",
            confirmButtonText: "Ok",
          });
        }
      } else {
        if (bValidQ && bValidR) {
          this.$swal({
            title: "Succès",
            text: "L'échantillon a été sauvegardé",
            icon: "success",
            type: "success",
            confirmButtonText: "Ok",
          });
        } else {
          this.$swal({
            title: "Erreur",
            text: "Erreur lors de la sauvegarde de l'échantillon",
            icon: "error",
            type: "error",
            confirmButtonText: "Ok",
          });
        }
      }
      this.toListe();
    },

    validationSaveQuestion() {
      let flag = false;
      if (this.questions === undefined) {
        this.$swal({
          title: "Erreur",
          text: "Erreur lors de la récupération des questions",
          icon: "error",
          type: "error",
          confirmButtonText: "Ok",
        });
      } else {
        flag = true;
      }
      return flag;
    },
    validationSaveResponses() {
      let flag = false;
      if (this.blocs === undefined) {
        this.$swal({
          title: "Erreur",
          text: "Erreur lors de la récupération des blocs",
          icon: "error",
          type: "error",
          confirmButtonText: "Ok",
        });
      } else {
        flag = true;
      }
      return flag;
    },

    retournerFin() {
      if (this.cGrilleAttribuee === "oui") {
        this.curr = 4;
      } else {
        this.curr = 3;
      }
    },

    async getGrille(langue, competence) {
      const response = await httpPost("/grilles/searchgrillelanguecompetence", {
        cGrilleLangue: langue,
        cGrilleCompetence: competence,
        bActif: 1,
      });
      if (response.status) {
        this.grille = response.message;
        this.creationTableauGrille(this.grille.pGrille);
      } else {
        this.$swal({
          title: "Erreur",
          text: "Erreur lors de la récupération de la grille",
          icon: "error",
          type: "error",
          confirmButtonText: "Ok",
        });
      }
      return response.status;
    },

    async creationTableauGrille(pGrille) {
      const responseColumns = await this.getColumnsGrille(pGrille);
      if (responseColumns) {
        this.grilleColumns = responseColumns;
      }
      const responseBloc = await this.getBlocsGrille(pGrille);
      if (responseBloc) {
        this.grilleBlocs = responseBloc;
      }
      const responseRows = await this.getRowsGrille(pGrille);
      if (responseRows) {
        this.grilleBlocsLignes = responseRows;
      }
      const responseCellules = await this.getCellulesGrille(pGrille);
      if (responseCellules) {
        this.grilleCellules = responseCellules;
      }
      this.buildIndexTablePreview(
        this.grilleBlocs,
        this.grilleBlocsLignes,
        this.grilleColumns
      );
    },

    async getColumnsGrille(pGrille) {
      const response = await httpGet("/grilleColumns/" + pGrille);
      return response;
    },
    async getBlocsGrille(pGrille) {
      const response = await httpGet("/grilleBlocks/" + pGrille);
      return response.message;
    },
    async getRowsGrille(pGrille) {
      const response = await httpGet("/grilleRows/searchByGrille/" + pGrille);
      return response.message;
    },
    async getCellulesGrille(pGrille) {
      const response = await httpGet("/grilleRowColumns/pgrille/" + pGrille);
      return response;
    },

    filterRow(pGrilleBlock) {
      let filteredRow = this.grilleBlocsLignes.filter(
        (ligne) => parseInt(ligne.pGrilleBlock) === parseInt(pGrilleBlock)
      );
      return filteredRow;
    },

    buildIndexTablePreview(grilleBlocs, grilleRows, grilleColumns) {
      this.previewLignes = [];
      let indexCellule = 0;
      let index = 0;
      let blocActif = grilleBlocs.filter((bloc) => parseInt(bloc.bActif) === 1);
      for (let i = 0; i < blocActif.length; i++) {
        let blocRows = grilleRows.filter(
          (row) =>
            parseInt(row.pGrilleBlock) === parseInt(blocActif[i].pGrilleBlock)
        );
        for (let j = 0; j < blocRows.length + 1; j++) {
          if (j === 0) {
            this.previewLignes[index] = {
              typeLigne: "bloc",
              previewColumns: [],
            };
          } else {
            this.previewLignes[index] = {
              typeLigne: "ligne",
              previewColumns: [],
            };
            for (let k = 0; k < grilleColumns.length; k++) {
              this.previewLignes[index].previewColumns[k] = {
                indexCelluleStyle: indexCellule,
                selected: false,
                pGrilleBlockRowColumns: "",
                nValue: "",
              };
              indexCellule++;
            }
          }
          index++;
        }
      }
      this.getCellulesGrilleResultat(parseInt(this.grilleResultat.pGrilleResult));
      return this.previewLignes;
    },

    async getCellulesGrilleResultat(pGrilleResultat) {
        if(this.grillePasEvalue) {
            this.filtreAllCellules();
        } else {
            this.cleanAllFiltre();
            const response = await httpPost("/grilleResultatDetails/searchByGrilleResultat", {
                pGrilleResultat: pGrilleResultat,
            });
            if (response.status) {
                this.grilleResultatDetails = response.message; 
                this.updatePreviewLignes();
                this.updateColeurPreviewLignes();
            } else {
                this.$swal({
                    title: "Erreur",
                    text: "Erreur lors de la récupération des cellules de la grille",
                    icon: "error",
                    type: "error",
                    confirmButtonText: "Ok",
                });
            }
        }
    },

    updatePreviewLignes() { 
      this.grilleResultatDetails.forEach((grilleResultatDetail) => {
        this.previewLignes.forEach((previewLigne) => {
          if(previewLigne.typeLigne === "ligne") {
            previewLigne.previewColumns.forEach((previewColumn) => {
              if(previewColumn.indexCelluleStyle === parseInt(grilleResultatDetail.nIndexCelluleStyle)) {
                previewColumn.selected = true;
                previewColumn.pGrilleBlockRowColumns = parseInt(grilleResultatDetail.pGrilleBlockRowColumns);
                previewColumn.nValue = parseInt(grilleResultatDetail.nValue);
              }
            });
          }
        });
      });
    },
    
    updateColeurPreviewLignes() {
      this.grilleResultatDetails.forEach((grilleResultatDetail) => {
        document.getElementsByClassName("cellule")[parseInt(grilleResultatDetail.nIndexCelluleStyle)].style.backgroundColor = "#90CAF9";
      });
    },


    filterCellule(pGrilleColumns, pGrilleBlock, pGrilleBlockRow) {
      const filteredCellule = this.grilleCellules.filter(
        (cellule) =>
          parseInt(cellule.pGrilleColumns) === parseInt(pGrilleColumns) &&
          parseInt(cellule.pGrilleBlock) === parseInt(pGrilleBlock) &&
          parseInt(cellule.pGrilleBlockRows) === parseInt(pGrilleBlockRow)
      );
      return filteredCellule;
    },

    changeStyleCellule(event, column, block, row) {
      let cellule = this.filterCellule(column, block, row);

      if (cellule.length > 0) {
        let indexRow = "";
        let indexColumn = "";
        let indexCelluleStyle = "";

        for (let i = 0; i < event.composedPath().length; i++) {
          let x = event.composedPath()[i];
          if (x.className === "cellule") {
            indexColumn = x.cellIndex - 1;
          } else if (x.className === "rowCellule") {
            indexRow = x.rowIndex;
          }
        }

        if (indexRow !== "" && indexColumn !== "") {
          indexCelluleStyle = this.previewLignes[indexRow].previewColumns[indexColumn].indexCelluleStyle;
          this.changeColeurCellule(indexRow, indexCelluleStyle);
          this.modifierPreviewLignes(indexRow, indexColumn, cellule[0]);
          //this.inactiverRow(indexRow, parseInt(cellule[0].nFiltre));
        }
      }
    },

    changeColeurCellule(indexLigne, indexCelluleStyle) {
      for (let i = 0; i < this.grilleColumns.length; i++) {
        let j = this.previewLignes[indexLigne].previewColumns[i].indexCelluleStyle;
        document.getElementsByClassName("cellule")[j].style.backgroundColor = "#FFFFFF";
      }
      document.getElementsByClassName("cellule")[indexCelluleStyle].style.backgroundColor = "#90CAF9";
    },

    modifierPreviewLignes(indexLigne, indexColumn, cellule) {
      this.previewLignes[indexLigne].previewColumns.forEach((element) => {
        element.selected = false;
        element.pGrilleBlockRowColumns = "";
        element.nValue = "";
      });

      this.previewLignes[indexLigne].previewColumns[indexColumn].selected = true;
      this.previewLignes[indexLigne].previewColumns[indexColumn].pGrilleBlockRowColumns = cellule.pGrilleBlockRowColumns;
      this.previewLignes[indexLigne].previewColumns[indexColumn].nValue = cellule.nValue;
    },

    filtreAllCellules() {
      for (let i = 0; i < this.previewLignes.length; i++) {
        if (this.previewLignes[i].typeLigne === "ligne") {
          this.previewLignes[i].previewColumns.forEach((element) => {
            let c = element.indexCelluleStyle;
            element.selected = false;
            element.pGrilleBlockRowColumns = "";
            document.getElementsByClassName("cellule")[c].style.backgroundColor = "#cccccc";
            document.getElementsByClassName("cellule")[c].style.color = "#666666";
            document.getElementsByClassName("cellule")[c].style.pointerEvents = "none";
          });
        }
      }
    },

    cleanAllFiltre() {
      for (let i = 0; i < this.previewLignes.length; i++) {
        if (this.previewLignes[i].typeLigne === "ligne") {
          this.previewLignes[i].previewColumns.forEach((element) => {
            let c = element.indexCelluleStyle;
            element.selected = false;
            element.pGrilleBlockRowColumns = "";
            document.getElementsByClassName("cellule")[c].style.backgroundColor = "#FFFFFF";
            document.getElementsByClassName("cellule")[c].style.color = "#000000";
            document.getElementsByClassName("cellule")[c].style.pointerEvents = "auto";
          });
        }
      }
    },
    changePasEvalue() {
      if (this.grillePasEvalue) {
        this.filtreAllCellules();
      } else {
        this.textGrillePasEvalue = "";
        this.cleanAllFiltre();
      }
    },

    resetGrille() {
      this.grillePasEvalue = false;
      this.textGrillePasEvalue = "";
      this.cleanAllFiltre();
    },

    validateGrille() {
      this.validStepGrille = false;
      if (this.validationGrille()) {
        this.validStepGrille = true;
        this.curr = 5;
      }
    },

    validateSaveGrille() {
      let flag = true;
      if (this.grille.pGrille === "" || this.grille.pGrille === null) {
        flag = false;
        this.$swal({
          title: "Erreur",
          text: "Il faut choisir une grille",
          icon: "error",
          type: "error",
          confirmButtonText: "Ok",
        });
      }
      return flag;
    },

    validationGrille() {
      let valid = false;
      for (let i = 0; i < this.previewLignes.length; i++) {
        if (this.previewLignes[i].typeLigne === "ligne") {
          for (
            let j = 0;
            j < this.previewLignes[i].previewColumns.length;
            j++
          ) {
            if (this.previewLignes[i].previewColumns[j].selected) {
              valid = true;
            }
          }
        }
      }
      if (!valid) {
        if (this.grillePasEvalue && this.textGrillePasEvalue === "") {
          this.$swal({
            title: "Erreur",
            text: "Veuillez saisir le motif de non évaluation",
            icon: "error",
            type: "error",
            confirmButtonText: "Ok",
          });
          return valid;
        } else if (this.grillePasEvalue && this.textGrillePasEvalue !== "") {
          valid = true;
        } else {
          this.$swal({
            title: "Erreur",
            text: "Veuillez sélectionner au moins une cellule",
            icon: "error",
            type: "error",
            confirmButtonText: "Ok",
          });
        }
      }
      return valid;
    },

    validationCellules() {
      let valid = false;
      for (let i = 0; i < this.previewLignes.length; i++) {
        if (this.previewLignes[i].typeLigne === "ligne") {
          for (let j = 0; j < this.previewLignes[i].previewColumns.length; j++) {
            if (this.previewLignes[i].previewColumns[j].selected) {
              valid = true;
            }
          }
        }
      }
      return valid;
    },

    getDate() {
      const d = new Date();
      var da = d.getFullYear() + "-" + (d.getMonth() + 1) + "-" + d.getDate();
      var h = d.getHours() + ":" + d.getMinutes() + ":" + d.getSeconds();
      return da + " " + h;
    },

    async saveGrilleEchantillon() {
      if (this.validateSaveGrille() && this.validationGrille()) {
        let bSave = false;
        this.dDateCorrectionFin = this.getDate();

        const response = await httpPost("/grilleResultats/createBypEchantillon", {
          pGrille: parseInt(this.grille.pGrille),
          pCorrecteur: parseInt(this.pUser),
          langue: this.cEchantillonLangue,
          competence: this.cEchantillonCompetence,
          cType: this.cType,
          dDateCorrectionDebut: this.dDateCorrectionDebut,
          dDateCorrectionFin: this.dDateCorrectionFin,
          pEchantillon: parseInt(this.pEchantillon),
          bPasCorrigee: this.grillePasEvalue,
          cPasCorrigee: this.textGrillePasEvalue,
        });
        if (response.status) {
          bSave = true;
          let responseDetails = "";
          if (this.validationCellules()) {
            responseDetails = await this.saveGrilleResultat(parseInt(response.id));
            if (responseDetails) {
              bSave = true;
            } else {
              bSave = false;
            }
          } 
        }
        return bSave;
      }
    },

    async saveGrilleResultat(pGrilleResultat) {
      const grilleResults = [];
      for (let i = 0; i < this.previewLignes.length; i++) {
        if (this.previewLignes[i].typeLigne === "ligne") {
          this.previewLignes[i].previewColumns.forEach((element) => {
            if (element.selected) {
              grilleResults.push({
                pGrilleResultat: pGrilleResultat,
                pGrilleBlockRowColumns: element.pGrilleBlockRowColumns,
                nIndexCelluleStyle: element.indexCelluleStyle,
                nValue: element.nValue,
              });
            }
          });
        }
      }
      const response = await httpPost("/grilleResultatDetails/create", {
        grilleResults: grilleResults,
      });
      return response.status;
    },
  },
};
