import { loginApi } from "@/services/auth";
import { version } from "../../../../../package.json";
import { email, required } from "vee-validate/dist/rules";
import { extend } from "vee-validate";
import VueCookies from "vue-cookies";
//import { httpPost } from "@/services/httpService";

extend("email", {
  ...email,
  message: "Veuillez entrer un courriel valide",
});

extend("required", {
  ...required,
  message: "{_field_} ce champ est requis",
});

export default {
  name: "Signin",
  created() {
    this.appVersion = version;
    // params 
    this.nextURL = this.$route.query.redirect;
  },
  data() {
    return {
      appVersion: "0.0.0",
      cCourriel: "",
      password: "",
      valid: false,
      errors: [],
      loading: false,
      show1: false,
      error: {
        email: false,
      },
      nextURL: "",
    };
  },
  methods: {
    validate() {
      this.loading = true;
      loginApi({ cCourriel: this.cCourriel, cPassword: this.password }).then(
        (response) => {
          if (response.data.success) {
            this.$store.dispatch(
              "doSetUser",
              JSON.stringify(response.data.user)
            );
            this.$store.dispatch(
              "doSetRoles",
              JSON.stringify(response.data.user_roles)
            );
            const userRoles = this.getRole();

            //set resources
            this.$store.dispatch("doSetResources", response.data.resources);
            this.$store.dispatch("doSetToken", response.data.token);
            //redirect dashboard
            if (this.password === "12345") {
              this.$router.push("user/passwordRecovery/" + response.data.user);
            }

            if (this.nextURL) {
              this.$router.push(this.nextURL);
            } else {
              if (userRoles.includes("6")) {
                this.$router.push("/entrainement");
              } else if (userRoles.includes("7")) {
                this.$router.push("/rechauffement");
              } else {
                this.$router.push("/dashboard");
              }
            }
            this.loading = false;
          } else {
            //this.errors = response.data.errors;
            this.$swal.fire({
              title: "Oops...",
              text: response.data.message,
              icon: "error",
              confirmButtonText: "OK",
            });
            this.loading = false;
          }
        }
      );
    },

    getRole() {
      const userR = [];
      const userRoles = localStorage.getItem("user_roles");
      // vuex store setRole mutation
      this.$store.commit("setRole", userRoles);

      const userRolesData = JSON.parse(userRoles);
      userRolesData.forEach((rol_obj) => {
        userR.push(rol_obj.pRoles.replace(/\[|\]/g, "").split(","));
      });
      return userR;
    },

    reset() {
      this.$refs.form.reset();
    },
    checkIsUserLogged() {
      const token = VueCookies.get("token");
      if (token) {
        this.$router.push("/dashboard");
      }
    }
  },
  mounted() {
    this.checkIsUserLogged()
  }
};
