// vue component
import Loading from 'vue-loading-overlay';
import { email, required } from 'vee-validate/dist/rules';
import { extend, ValidationObserver, ValidationProvider } from 'vee-validate'
import { httpRequest, httpPost } from '@/services/httpService';
import groupesComponent from '@/views/private/config/Options/Organisation/Groupes.vue';
import createGroupeComponent from '@/views/private/config/Options/Organisation/formCreateGroupe.vue';
import SnackMessage from '@/components/SnackMessage.vue';

extend('email', {
    ...email,
    message: 'Veuillez entrer un courriel valide',
})

extend('required', {
    ...required,
    message: 'Ce champ est requis',
})

export default {
    name: 'Unites',

    components: {
        Loading,
        groupesComponent,
        createGroupeComponent,
        SnackMessage,
        ValidationProvider,
        ValidationObserver
    },
    created() {
        this.getUnites();
    },
    data() {
        return {
            on: false,
            isloading: false,
            unites: [],
            show: false,
            search: '',
            dialogCreateUnite: false,
            dialogCreateGroupe: false,
            messageModal: '',
            itemModifyStat: {},
            dialogChangeStat: false,
            valid: true,
            formUnite: {
                nom: '',
                description: '',
                pDepartement: '',
                bActif: false,
            },
            messageError: '',
            snackbar: false,
            textSnackbar: '',
            colorSnackbar: '',
            colorBtnSnackbar: '',
            timeout: 2000,
            itemSelected: {
                id: '',
                nom: '',
                description: '',
                pDepartement: '',
                cDepartement: '',
                bActif: false,
            },
            typeAction: '',
            typeActionGroupe: 'create',
        }
    },
    computed: {
        filteredUnites: function () {
            // filter cDepartement
            return this.unites.filter(unite => { return unite.cDepartement.toLowerCase().includes(this.search.toLowerCase()) }).sort((a, b) => { return a.cDepartement.localeCompare(b.cDepartement) });
        }
    },
    methods: {
        getUnites() {
            this.isloading = true;
            httpRequest('/departements/groupe')
                .then(response => {
                    this.unites = response.data;
                    this.isloading = false;
                })
        },

        createUpdateUnite() {
            if (this.typeAction == 'create') {
                this.searchDepartement();
            } else {
                this.updateUnite();
            }
        },

        async searchDepartement() {
            const respons = await httpPost('/departements/searchdepartement', { 'cDepartement': this.formUnite.nom })
            if (!respons.status) {
                this.createUnite();
            } else {
                this.$swal({
                    position: 'bottom',
                    icon: 'warning',
                    title: 'Cette unité existe déjà',
                    showConfirmButton: false,
                    timer: 2500
                })
            }
        },

        openDialogCreateUnite(item, typeAction) {
            this.typeAction = typeAction;
            this.dialogCreateUnite = true;
        },

        closeDialogCreateUnite() {
            this.clearForm();
            this.dialogCreateUnite = false;
        },

        async createUnite() {
            console.log(this.formUnite.bActif)
            await httpPost('/departements/create', {
                cDepartement: this.formUnite.nom,
                cDescription: this.formUnite.description,
                bActif: this.formUnite.bActif,
            })
            this.clearForm();
            this.dialogCreateUnite = false;
            this.getUnites();

            this.$swal({
                type: 'success',
                title: 'Succès',
                icon: 'success',
                text: 'L\'unité a été créée avec succès',
                confirmButtonText: 'Ok',
            })
        },

        openDialogUpdateUnite(item, typeAction) {
            this.dialogCreateUnite = true;
            this.formUnite.nom = item.cDepartement;
            this.formUnite.description = item.cDescription;
            this.formUnite.pDepartement = item.pDepartement;
            this.formUnite.bActif = item.bActif;
            this.typeAction = typeAction;
        },

        async updateUnite() {
            await httpPost('/departements/update', {
                pDepartement: this.formUnite.pDepartement,
                cDepartement: this.formUnite.nom,
                cDescription: this.formUnite.description,
                bActif: this.formUnite.bActif,
            })
            this.clearForm();
            this.dialogCreateUnite = false;
            this.getUnites();

            this.$swal({
                title: "Succès",
                text: "Succès",
                type: "success",
                icon: 'success',
                confirmButtonText: "Ok"
            });
        },

        createGroupe(item) {
            this.itemSelected = item;
            this.$store.state.modalCreate.title = item.cDepartement;
            this.typeActionGroupe = 'create'
            this.$store.state.modalCreate.show = true;
        },

        clearForm() {
            this.formUnite.nom = '';
            this.formUnite.description = '';
            this.messageError = '';
            this.formUnite.bActif = false;
            
            this.$refs.observer.reset();
        },

        openDialogCreateGroupe(item) {
            console.log(item);
            this.itemSelected = {
                pDepartement: item.pDepartement,
                cDepartement: item.cDepartement,
                bActif: parseInt(item.bActif),
                pGroupe: item.pGroupe,
                nom: item.cGroupe,
                description: item.cDescription
            };
            console.log(this.itemSelected);
            this.typeActionGroupe = 'update';
            this.$store.state.modalCreate.show = true;
        }
    },
}