import { httpPost } from "@/services/httpService";
export default {
    name: "Blocks",
    props: {
        grille: {
            type: Object,
            default: function () {
                return {};
            }
        },
        grilleBlocks: {
            type: Array,
            default: function () {
                return [];
            },
        },
    },
    data() {
        return {
            FormData: {
                cGrilleBlock: "",
                cGrilleBlockDesc: "",
                nFilter: "",
                nOrdre: "",
                bFilter: false,
            },
            Invalid: false,
            orders: [
                { text: "1", value: 1 },
                { text: "2", value: 2 },
                { text: "3", value: 3 },
                { text: "4", value: 4 },
                { text: "5", value: 5 },
                { text: "6", value: 6 },
                { text: "7", value: 7 },
                { text: "8", value: 8 },
                { text: "9", value: 9 },
                { text: "10", value: 10 },
            ],
        };
    },
    methods: {
        async AddOtherBlock() {
            const formData = {
                cGrilleBlock: this.FormData.cGrilleBlock,
                cGrilleBlockDesc: this.FormData.cGrilleBlockDesc,
                //nFilter: this.FormData.nFilter,
                //nOrdre: this.FormData.nOrdre,
                bFilter: this.FormData.bFilter,
                pGrille: this.grille.pGrille,
                bActif: 1,
            };
            console.log(formData);
            const result = await httpPost("/grilleBlocks/create", formData);
            console.log(result);
            // emit refresh
            this.$emit("refresh");
            this.clearForm();
        },
        async deleteItemBlock(item) {
            // delete item by name from grille.blocks
            console.log("deleteItemBlock", item);
            const result = await httpPost("/grilleBlocks/delete", { pGrilleBlock: item.pGrilleBlock });
            console.log(result);
            this.$emit("refresh");
        },
        saveBlockAndContinue() {
            this.$emit("Next");
        },
        back() {
            this.$emit("back");
        },
        clearForm() {
            this.FormData = {
                cGrilleBlock: "",
                cGrilleBlockDesc: "",
                nFilter: "",
                nOrdre: "",
                bFilter: false,
            };
            this.formData.cGrilleBlock = "";
        },
    },
};