<template>
  <v-navigation-drawer class="grey lighten-3" v-model="toggle" fixed app>
    <template v-slot:prepend>
      <v-list-item two-line>
        <v-list-item-content>
          <v-list-item-title
            ><strong>{{ name }}</strong></v-list-item-title
          >
          <v-list-item-subtitle>Connecté</v-list-item-subtitle>
        </v-list-item-content>
      </v-list-item>
    </template>

    <v-divider></v-divider>

    <v-list>
      <div v-for="item in items" :key="item.title">
        <div v-if="item.subitem == 0">
          <v-list-item v-if="item.show" :to="item.route">
            <v-list-item-icon>
              <v-icon v-text="item.icon"></v-icon>
            </v-list-item-icon>
            <v-list-item-title v-text="item.title"></v-list-item-title>
          </v-list-item>
        </div>
        <div v-else>
          <v-list-group
            v-if="item.show"
            :key="item.title"
            :prepend-icon="item.icon"
            no-action
          >
            <template v-slot:activator>
              <v-list-item-content>
                <v-list-item-title v-text="item.title"></v-list-item-title>
              </v-list-item-content>
            </template>
            <div v-for="child in item.items" :key="child.title">
              <v-list-item :to="child.route" v-if="child.show">
                <v-list-item-content>
                  <v-list-item-title class="pl-5" v-text="child.title"></v-list-item-title>
                </v-list-item-content>
              </v-list-item>
          </div>
          </v-list-group>
        </div>
      </div>
    </v-list>
  </v-navigation-drawer>
</template>

<script>
export default {
  name: "Drawer",
  created() {
    const user = localStorage.getItem("user");
    if (user) {
      const userData = JSON.parse(user);
      this.name = userData.cPrenom + " " + userData.cNom;
    }

    const userRoles = localStorage.getItem("user_roles");
    const userRolesData = JSON.parse(userRoles);
    userRolesData.forEach((rol_obj) => {
      const roles_id = rol_obj.pRoles.replace(/\[|\]/g, "").split(",");
      roles_id.forEach((rol_id) => {
        this.user_roles.push(rol_id);
      });
    });
    
    // Check if menu items should be displayed according to role
    // Role 1: Administrateur
    // Role 2: Administrateur des grilles
    // Role 3: Modificateur des grilles
    // Role 4: Consultation
    // Role 6: Entraînement
    // Role 7: Réchauffement
    if( this.user_roles.includes("1") ){
      this.items.forEach(itm => {
        itm["show"] = true;
        if(itm["subitem"] != 0){
          itm["items"].forEach(sub_itm => {
            sub_itm["show"] = true;
          });
        }
      });
    }else{
      let rapport_item = this.items[1];
      if(this.user_roles.includes("4")){
        rapport_item["show"] = true;
        rapport_item["items"].forEach(sub_itm => {
          sub_itm["show"] = true;
        });
      }

      let entrainement_item = this.items[2];
      entrainement_item["show"] = this.user_roles.includes("6");

      let rechauffement_item = this.items[3];
      rechauffement_item["show"] = this.user_roles.includes("7");
      /* if(this.user_roles.includes("7")){
        rechauffement_item["show"] = true;
        rechauffement_item["items"].forEach(sub_itm => {
          sub_itm["show"] = true;
        });
      } */

      let grilles_item = this.items[4];
      //console.log("grilles_item 4 -- ", grilles_item);
      grilles_item["show"] =
        this.user_roles.includes("2") ||
        this.user_roles.includes("3");
      grilles_item["items"][0]["show"] =
        this.user_roles.includes("2") ||
        this.user_roles.includes("3");
      grilles_item["items"][1]["show"] = this.user_roles.includes("2");
      grilles_item["items"][2]["show"] = this.user_roles.includes("2");
    } 
   
  },
  props: ["toggle"],
  data() {
    return {
      name: "",
      user_roles: [],
      items: [
        {
          title: "Dashboard",
          //icon: "mdi-view-dashboard",
          icon: "mdi-monitor-dashboard",
          route: "/dashboard",
          show: false,
          subitem: 0,
        },
        {
          title: "Rapports",
          icon: "mdi-atom",
          route: "/reports",
          show: false,
          subitem: 1,
          items: [
            {
              title: "Query builder",
              //route: "/rapports",
              route: "/underconstruction",
              show: false,
            },
            {
              title: "Rapports",
              //route: "/reporttypes",
              route: "/underconstruction",
              show: false,
            },
          ],
        },
        {
          title: "Entraînement",
          icon: "mdi-weight-lifter",
          route: "/entrainement",
          show: false,
          subitem: 0,
        },
        {
          title: "Réchauffement",
          icon: "mdi-table-edit",
          route: "/rechauffement",
          show: false,
          subitem: 0,
          /* items: [
            {
              title: "Écriture",
              route: "/refresh",
              show: false,
            },
            {
              title: "Lecture",
              route: "/refresh",
              show: false,
            },
          ], */
        },

        {
          title: "Grilles d'évaluation",
          //icon: "mdi-puzzle",
          icon: "mdi-view-grid-plus-outline",
          route: "/grilles",
          show: false,
          subitem: 1,
          items: [
            /* {
              title: "Configuration de grilles",
              route: "/Listgrilles",
              show: false,
            }, */
            {
              title: "Création",
              route: "/liste",
              show: false,
            },
            {
              title: "Règles",
              route: "/grilleregles",
              show: false,
            },
            {
              title: "Affectation de grilles",
              route: "/grilleurl",
              show: false,
            },
            {
              title: "Configuration de l’échantillon",
              route: "/echantillonlist",
              show: false,
            },
            {
              title: "Questions des échantillons",
              route: "/echantillonquestion",
              show: false,
            },
          ],
        },
        {
          title: "Interfaces",
          icon: "mdi-xml",
          route: "/appinterface",
          show: false,
          subitem: 1,
          items: [
            {
              title: "Création",
              route: "/appinterface",
              show: false,
            },
            {
              title: "Types",
              route: "/apptypeinterface",
              show: false,
            },
          ],
        },
        {
          title: "Paramètres",
          icon: "mdi-cog",
          route: "/settings",
          show: false,
          subitem: 1,
          items: [
            {
              title: "Langues",
              route: "/langues",
              show: false,
            },
            {
              title: "Compétences",
              route: "/competences",
              show: false,
            },
            {
              title: "Rôles ressources",
              route: "/roles/ressources",
              show: false,
            },

            /* {
              title: "Règles",
              route: "/grilleregles",
              show: false,
            }, */
          ],
        },
        {
          title: "Gestion des utilisateurs",
          icon: "mdi-account-cog",
          route: "/users",
          show: false,
          subitem: 1,
          items: [
            {
              title: "Utilisateurs",
              route: "/users",
              show: false,
            },
            {
              title: "Unités",
              route: "/unites",
              show: false,
            },
          ],
        },

        {
          title: "Quiter",
          icon: "mdi-exit-run",
          route: "/logout",
          show: true,
          subitem: 0,
        },
      ],
    };
  },
};
</script>