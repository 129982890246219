import { httpGet, httpPost } from "@/services/httpService";

export default {
  name: "GrilleEntrainement",
  data() {
    return {
      pGrille: "",
      cGrille: "",
      cGrilleDesc: "",
      cGrilleLangue: "",
      cGrilleCompetence: "",
      cType: "entrainement",
      cSousType: "",
      echantillons: [],
      echantillon: [],
      questions: [],
      responses: [
        {
          response: "",
          ligne: "",
          path: "",
        },
      ],
      grilleColumns: [],
      grilleBlocs: [],
      grilleBlocsLignes: [],
      grilleCellules: [],
      previewLignes: [
        {
          typeLigne: "",
          previewColumns: [
            {
              indexCelluleStyle: 0,
              selected: false,
              pGrilleBlockRowColumns: "",
              nValue: "",
            },
          ],
        },
      ],
      grillePasEvalue: false,
      textGrillePasEvalue: "",
      grilleEnregistree: false,
      pUser: "",
      flagCleanCellules: 0,
      pathMedia: "https://evaluation.cecl.uqam.ca/media/echantillons/",
      bEnregistrer: false,
      bCorrection: false,
      dDateCorrectionDebut: "",
      dDateCorrectionFin: "",
      grillePasEvalueCorrigee: "",
      textGrillePasEvalueCorrigee: "",
      grilleResultatDetails: [],
    };
  },
  async created() {
    if (this.$route.params.id && this.$route.params.type) {
      this.pGrille = this.$route.params.id;
      this.type = this.$route.params.type;
      if (parseInt(this.type) === 2) {
        this.cSousType = "Visualiser + Échantillon + Enregistrer";
      } else if (parseInt(this.type) === 3) {
        this.cSousType = "Visualiser + Échantillon + Enregistrer + Correction";
      }
      const user = localStorage.getItem("user");
      if (user) {
        const userData = JSON.parse(user);
        this.pUser = userData.pUser;
      }

      //await this.creationEchantillon();
      this.creationAll();
      this.dDateCorrectionDebut = this.getDate();
    } else {
      this.$swal({
        title: "Erreur",
        text: "Vous n'avez pas accès à cette page",
        icon: "error",
        confirmButtonText: "Ok",
      });
    }
  },

  methods: {
    creationAll() {
      // promise creationEchantillon and then creationTableauGrille
      this.creationEchantillon().then(() => {
        this.creationTableauGrille();
      });
    },

    async creationEchantillon() {
      //Grille
      const responseGrille = await this.getGrilles(this.pGrille);
      if (responseGrille) {
        this.cGrille = responseGrille.message.cGrille;
        this.cGrilleDesc = responseGrille.message.cGrilleDesc;
        this.cGrilleLangue = responseGrille.message.cGrilleLangue;
        this.cGrilleCompetence = responseGrille.message.cGrilleCompetence;
      } else {
        this.$swal({
          title: "Erreur",
          text: "La grille n'existe pas",
          icon: "error",
          confirmButtonText: "Ok",
        });
      }
      //Echantillon
      if (this.cGrilleLangue !== "" && this.cGrilleCompetence !== "") {
        const responseEchantillon = await this.getEchantillon(
          this.cGrilleLangue,
          this.cGrilleCompetence,
          1
        );
        if (responseEchantillon) {
          this.echantillon = this.randomInEchantillons(
            responseEchantillon.message
          );

          this.questions = await this.getQuestions(
            this.echantillon.pEchantillon
          );
          this.responses = [];

          if (
            this.echantillon.cTypeResponse === "bloc" ||
            this.echantillon.cTypeResponse === "ligne"
          ) {
            if (this.echantillon.cTypeResponse === "bloc") {
              let responseResponse = await this.getResponsesBloc(
                this.echantillon.pEchantillon
              );
              if (responseResponse) {
                responseResponse.forEach((response) => {
                  this.responses.push({
                    response: response.cGrilleEchantillonQuestionReponse,
                    ligne: response.cGrilleBlock,
                    path: "",
                  });
                });
              }
            } else if (this.echantillon.cTypeResponse === "ligne") {
              let responseResponse = await this.getResponsesLigne(
                this.echantillon.pEchantillon
              );
              if (responseResponse) {
                responseResponse.forEach((response) => {
                  this.responses.push({
                    response: response.cGrilleEchantillonQuestionReponse,
                    ligne: response.cTitre,
                    path: "",
                  });
                });
              }
            }
          } else {
            let responseResponse = await this.getQuestionsResponses(
              this.echantillon.pEchantillon
            );
            if (responseResponse) {
              responseResponse.forEach((response) => {
                this.responses.push({
                  response: response.cGrilleEchantillonQuestionReponse,
                  ligne: response.cGrilleEchantillonQuestion,
                  path:
                    this.pathMedia + response.cGrilleEchantillonQuestionReponse,
                });
              });
            }
          }
        } else {
          this.$swal({
            title: "Erreur",
            text: "Aucun échantillon n'a été trouvé",
            icon: "error",
            confirmButtonText: "Ok",
          });
        }
      }
      //await this.creationTableauGrille();
    },

    async getGrilles(pGrille) {
      const response = await httpPost("/grilles/searchbypgrille", {
        pGrille: pGrille,
      });
      return response;
    },

    async getEchantillon(cGrilleLangue, cGrilleCompetence, bActif) {
      const response = await httpPost(
        "/echantillons/searchByEchantillonLangueCompetence",
        {
          cEchantillonLangue: cGrilleLangue,
          cEchantillonCompetence: cGrilleCompetence,
          bActif: bActif,
        }
      );
      return response;
    },

    randomInEchantillons(echantillons) {
      let random = Math.round(Math.random() * (echantillons.length - 0) + 0);
      return echantillons[random];
    },

    async getQuestions(pEchantillon) {
      const response = await httpPost(
        "/echantillonQuestionAttributions/searchGrilleEchantillonQuestionAttributionByPGrilleEchantillon",
        {
          pGrilleEchantillon: pEchantillon,
        }
      );
      return response.message;
    },
    async getResponsesBloc(pEchantillon) {
      const response = await httpPost(
        "/echantillonQuestionReponses/searchBypGrilleEchantillonJoinGrilleBlockByGrilleEchantillon",
        {
          pGrilleEchantillon: pEchantillon,
        }
      );
      return response.message;
    },
    async getResponsesLigne(pEchantillon) {
      const response = await httpPost(
        "/echantillonQuestionReponses/searchBypGrilleEchantillonJoinGrilleBlockRowsByGrilleEchantillon",
        {
          pGrilleEchantillon: pEchantillon,
        }
      );
      return response.message;
    },
    async getQuestionsResponses(pEchantillon) {
      const response = await httpPost(
        "/echantillonQuestionReponses/searchBypGrilleEchantillonJoinGrilleEchantillonQuestionByGrilleEchantillon",
        {
          pGrilleEchantillon: pEchantillon,
        }
      );
      return response.message;
    },

    /* Tableau */
    async creationTableauGrille() {
      const responseColumns = await this.getColumns(this.pGrille);
      if (responseColumns) {
        this.grilleColumns = responseColumns;
      }
      const responseBloc = await this.getBlocs(this.pGrille);
      if (responseBloc) {
        this.grilleBlocs = responseBloc;
      }
      const responseRows = await this.getRows(this.pGrille);
      if (responseRows) {
        this.grilleBlocsLignes = responseRows;
      }
      const responseCellules = await this.getCellules(this.pGrille);
      if (responseCellules) {
        this.grilleCellules = responseCellules;
      }
      this.buildIndexTablePreview(
        this.grilleBlocs,
        this.grilleBlocsLignes,
        this.grilleColumns
      );
      this.getCellulesGrilleResultat(this.pGrille);
    },

    async getColumns(pGrille) {
      const response = await httpGet("/grilleColumns/" + pGrille);
      return response;
    },
    async getBlocs(pGrille) {
      const response = await httpGet("/grilleBlocks/" + pGrille);
      return response.message;
    },
    async getRows(pGrille) {
      const response = await httpGet("/grilleRows/searchByGrille/" + pGrille);
      return response.message;
    },
    async getCellules(pGrille) {
      const response = await httpGet("/grilleRowColumns/pgrille/" + pGrille);
      return response;
    },

    filterRow(pGrilleBlock) {
      let filteredRow = this.grilleBlocsLignes.filter(
        (ligne) => parseInt(ligne.pGrilleBlock) === parseInt(pGrilleBlock)
      );
      return filteredRow;
    },

    filterCellule(pGrilleColumns, pGrilleBlock, pGrilleBlockRow) {
      const filteredCellule = this.grilleCellules.filter(
        (cellule) =>
          parseInt(cellule.pGrilleColumns) === parseInt(pGrilleColumns) &&
          parseInt(cellule.pGrilleBlock) === parseInt(pGrilleBlock) &&
          parseInt(cellule.pGrilleBlockRows) === parseInt(pGrilleBlockRow)
      );
      return filteredCellule;
    },

    buildIndexTablePreview(grilleBlocs, grilleRows, grilleColumns) {
      this.previewLignes = [];
      let indexCellule = 0;
      let index = 0;
      let blocActif = grilleBlocs.filter((bloc) => parseInt(bloc.bActif) === 1);
      for (let i = 0; i < blocActif.length; i++) {
        let blocRows = grilleRows.filter(
          (row) =>
            parseInt(row.pGrilleBlock) === parseInt(blocActif[i].pGrilleBlock)
        );
        for (let j = 0; j < blocRows.length + 1; j++) {
          if (j === 0) {
            this.previewLignes[index] = {
              typeLigne: "bloc",
              previewColumns: [],
            };
          } else {
            this.previewLignes[index] = {
              typeLigne: "ligne",
              previewColumns: [],
            };
            for (let k = 0; k < grilleColumns.length; k++) {
              this.previewLignes[index].previewColumns[k] = {
                indexCelluleStyle: indexCellule,
                selected: false,
                pGrilleBlockRowColumns: "",
                nValue: "",
              };
              indexCellule++;
            }
          }
          index++;
        }
      }
      return this.previewLignes;
    },

    changeStyleCellule(event, column, block, row) {
      let cellule = this.filterCellule(column, block, row);

      if (cellule.length > 0) {
        let indexRow = "";
        let indexColumn = "";
        let indexCelluleStyle = "";

        for (let i = 0; i < event.composedPath().length; i++) {
          let x = event.composedPath()[i];
          if (x.className === "cellule") {
            indexColumn = x.cellIndex - 1;
          } else if (x.className === "rowCellule") {
            indexRow = x.rowIndex;
          }
        }

        if (indexRow !== "" && indexColumn !== "") {
          indexCelluleStyle =
            this.previewLignes[indexRow].previewColumns[indexColumn]
              .indexCelluleStyle;
          this.changeColeurCellule(indexRow, indexCelluleStyle);
          this.modifierPreviewLignes(indexRow, indexColumn, cellule[0]);
          //this.inactiverRow(indexRow, parseInt(cellule[0].nFiltre));
        }
      }
    },

    changeColeurCellule(indexLigne, indexCelluleStyle) {
      for (let i = 0; i < this.grilleColumns.length; i++) {
        let j =
          this.previewLignes[indexLigne].previewColumns[i].indexCelluleStyle;
        document.getElementsByClassName("cellule")[j].style.backgroundColor =
          "#FFFFFF";
      }
      document.getElementsByClassName("cellule")[
        indexCelluleStyle
      ].style.backgroundColor = "#90CAF9";
    },

    modifierPreviewLignes(indexLigne, indexColumn, cellule) {
      this.previewLignes[indexLigne].previewColumns.forEach((element) => {
        element.selected = false;
        element.pGrilleBlockRowColumns = "";
        element.nValue = "";
      });
     
      this.previewLignes[indexLigne].previewColumns[indexColumn].selected = true;
      this.previewLignes[indexLigne].previewColumns[indexColumn].pGrilleBlockRowColumns = cellule.pGrilleBlockRowColumns;
      this.previewLignes[indexLigne].previewColumns[indexColumn].nValue = cellule.nValue;
    },

    inactiverRow(indexLigne, filtre) {
      //console.log("inactiverRow - filtre", filtre);
      if (filtre === 1 || filtre === 2) {
        this.filtreCellule(indexLigne, filtre);
      } else {
        this.cleanFiltre(indexLigne, filtre);
      }
    },

    filtreCellule(indexLigne, filtre) {
      this.cleanFiltre(indexLigne, filtre);
      for (let i = indexLigne + 1; i < this.previewLignes.length; i++) {
        if (this.previewLignes[i].typeLigne === "ligne") {
          this.previewLignes[i].previewColumns.forEach((element) => {
            let c = element.indexCelluleStyle;
            document.getElementsByClassName("cellule")[
              c
            ].style.backgroundColor = "#cccccc";
            document.getElementsByClassName("cellule")[c].style.color =
              "#666666";
            document.getElementsByClassName("cellule")[c].style.pointerEvents =
              "none";
          });
        } else if (filtre === 1) {
          break;
        }
      }
    },

    filtreAllCellules() {
      for (let i = 0; i < this.previewLignes.length; i++) {
        if (this.previewLignes[i].typeLigne === "ligne") {
          this.previewLignes[i].previewColumns.forEach((element) => {
            let c = element.indexCelluleStyle;
            document.getElementsByClassName("cellule")[c].style.backgroundColor = "#cccccc";
            document.getElementsByClassName("cellule")[c].style.color = "#666666";
            document.getElementsByClassName("cellule")[c].style.pointerEvents = "none";
          });
        }
      }
    },

    cleanFiltre(indexLigne, filtre) {
      if (this.flagCleanCellules != 0) {
        for (let i = indexLigne + 1; i < this.previewLignes.length; i++) {
          if (this.previewLignes[i].typeLigne === "ligne") {
            this.previewLignes[i].previewColumns.forEach((element) => {
              let c = element.indexCelluleStyle;
              element.selected = false;
              element.pGrilleBlockRowColumns = "";
              document.getElementsByClassName("cellule")[c].style.backgroundColor = "#FFFFFF";
              document.getElementsByClassName("cellule")[c].style.color = "#000000";
              document.getElementsByClassName("cellule")[c].style.pointerEvents = "auto";
            });
          }
        }
      }
      if (filtre === 1 || filtre === 2) {
        this.flagCleanCellules = 1;
      } else {
        this.flagCleanCellules = 0;
      }
    },

    cleanAllFiltre() {
      for (let i = 0; i < this.previewLignes.length; i++) {
        if (this.previewLignes[i].typeLigne === "ligne") {
          this.previewLignes[i].previewColumns.forEach((element) => {
            let c = element.indexCelluleStyle;
            element.selected = false;
            element.pGrilleBlockRowColumns = "";
            document.getElementsByClassName("cellule")[c].style.backgroundColor = "#FFFFFF";
            document.getElementsByClassName("cellule")[c].style.color = "#000000";
            document.getElementsByClassName("cellule")[c].style.pointerEvents = "auto";
          });
        }
      }
    },

    changePasEvalue() {
      if (this.grillePasEvalue) {
        this.filtreAllCellules();
      } else {
        this.textGrillePasEvalue = "";
        this.cleanAllFiltre();
      }
    },

    resetGrille() {
      this.grillePasEvalue = false;
      this.textGrillePasEvalue = "";
      this.cleanAllFiltre();
      document.getElementById("cardGrille").style.display = "none";
      document.getElementById("boutonCancelGrille").style.display = "none";

    },

    validateGrille() {
      let flag = true;
      if (this.pGrille === "" || this.pGrille === null) {
        flag = false;
        this.$swal({
          title: "Erreur",
          text: "Il faut choisir une grille",
          icon: "error",
          confirmButtonText: "Ok",
        });
      }
      return flag;
    },

    validationCellules() {
      let valid = false;
      for (let i = 0; i < this.previewLignes.length; i++) {
        if (this.previewLignes[i].typeLigne === "ligne") {
          for (let j = 0; j < this.previewLignes[i].previewColumns.length; j++) {
            if (this.previewLignes[i].previewColumns[j].selected) {
              valid = true;
            }
          }
        }
      }
      return valid;
    },

    async validateSaveGrilleResultat() {
      let validation = await this.saveGrille();
      if (validation) {
        
        if(parseInt(this.type) === 2) {
          this.$swal({
            title: 'Succès',
            text: 'La grille a été sauvegardée avec succès',
            icon: 'success',
            confirmButtonText: 'Ok',
          }).then ((result) => {
            if (result.value) {
              this.$router.push("/logout");
            }
          });
        } else {
          this.$swal({
            title: 'La grille a été sauvegardée avec succès',
            text: 'Vous pouvez maintenant comparer votre résultat avec celui du correcteur',
            icon: 'success',
            confirmButtonText: 'Ok',
          }).then ((result) => {
            if (result.value) {
              this.bEnregistrer = true;
              document.getElementById("cardGrilleCorrigee").style.display = "block";
              document.getElementById("boutonCancelGrille").style.display = "block";
            }
          });
        }        
      }
    },

    getDate() {
      const d = new Date();
      var da = d.getFullYear() + "-" + (d.getMonth() + 1) + "-" + d.getDate();
      var h = d.getHours() + ":" + d.getMinutes() + ":" + d.getSeconds();
      return da + " " + h;
    },

    async saveGrille() {
      if (this.validateGrille() && this.validateGrilleResultat()) {
        let bSave = false;
        this.dDateCorrectionFin = this.getDate();

        const response = await httpPost("/grilleResultats/create", {
          pGrille: parseInt(this.pGrille),
          pCorrecteur: parseInt(this.pUser),
          langue: this.cGrilleLangue,
          competence: this.cGrilleCompetence,
          cType: this.cType,
          cSousType: this.cSousType,
          dDateCorrectionDebut: this.dDateCorrectionDebut,
          dDateCorrectionFin: this.dDateCorrectionFin,
          pEchantillon: parseInt(this.echantillon.pEchantillon),
          bPasCorrigee: this.grillePasEvalue,
          cPasCorrigee: this.textGrillePasEvalue,
        });
        if (response.status) {
          bSave = true;
          let responseDetails = "";
          if(this.validationCellules()){
            responseDetails = await this.saveGrilleResultat(parseInt(response.id));
            if(responseDetails){
              bSave = true;
            } else {
              bSave = false;
            }
          }
        }
        return bSave;
      }
    },

    async saveGrilleResultat(pGrilleResultat) {
      const grilleResults = [];
      for (let i = 0; i < this.previewLignes.length; i++) {
        if (this.previewLignes[i].typeLigne === "ligne") {
          this.previewLignes[i].previewColumns.forEach((element) => {
            if (element.selected) {
              grilleResults.push({
                pGrilleResultat: pGrilleResultat,
                pGrilleBlockRowColumns: element.pGrilleBlockRowColumns,
                nIndexCelluleStyle: element.indexCelluleStyle,
                nValue: element.nValue,
              });
            }
          });
        }
      }
      const response = await httpPost("/grilleResultatDetails/create", {
        grilleResults: grilleResults,
      });
      return response.status;
    },

    validateGrilleResultat() {
      let flag = false;
      for (let i = 0; i < this.previewLignes.length; i++) {
        if (this.previewLignes[i].typeLigne === "ligne") {
          for (let j = 0; j < this.previewLignes[i].previewColumns.length; j++) {
            if (this.previewLignes[i].previewColumns[j].selected) {
              flag = true;
            }
          }
        }
      }
      if (!flag) {
        if(this.grillePasEvalue && this.textGrillePasEvalue === ""){
            this.$swal({
                title: "Erreur",
                text: "Veuillez saisir le motif de non évaluation",
                icon: "error",
                confirmButtonText: "Ok",
            });
            return flag;
        } else if(this.grillePasEvalue && this.textGrillePasEvalue !== ""){
            return true;
        } else {
            this.$swal({
                title: "Erreur",
                text: "Veuillez sélectionner au moins une cellule",
                icon: "error",
                confirmButtonText: "Ok",
            });
        }
      }
      return flag;
    },

    cancelGrille() {
      this.$router.push("/logout");
    },

    async getCellulesGrilleResultat(pGrille) {
      const response = await httpPost("/grilleResultats/searchBypGrilleResultatDetails", {
        pGrille: pGrille,
        cType: "echantillon"
        });
        
      if (response.status) {
        this.grilleResultatDetails = response.message; 
        this.updateColeurPreviewLignes();
      } else {
        this.$swal({
          title: "Erreur",
          text: "Erreur lors de la récupération des cellules de la grille",
          icon: "error",
          confirmButtonText: "Ok",
        });
      }
    },
    
    updateColeurPreviewLignes() {
      this.grilleResultatDetails.forEach((grilleResultatDetail) => {
        document.getElementsByClassName("celluleCorrigee")[parseInt(grilleResultatDetail.nIndexCelluleStyle)].style.backgroundColor = "#90CAF9";
      });
    },
  },
};
