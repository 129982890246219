import { httpPost } from "@/services/httpService";
export default {
  name: "fillData",
  props: {
    grilleBlocks: {
      type: Array,
      required: true,
    },
    grilleRows: {
      type: Array,
      required: true,
    },
    grilleColumns: {
      type: Array,
      required: true,
    },
  },
  data: () => ({
    items: [
      "Les autres lignes du bloc ne sont pas nécessaires",
      "Les autres blocs ne sont pas nécessaires",
    ],
  }),
  methods: {
    filterRow(block) {
      console.log("filterRow", block);
      const res = this.grilleRows.filter((row) => row.pGrilleBlock === block);
      console.log("filterRow", res);
      return res;
    },
    async changeValue(row, column, value) {
      console.log("changeValue", row, column, value);
      const res = await httpPost("/grilleRowColumns/create", {
        pGrilleBlockRows: row,
        cGrilleBlockRowColumns: column,
        nValue: value,
      });
      console.log(res);
    },
  },
};
