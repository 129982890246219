var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-card',{attrs:{"outlined":""}},[_c('v-card-title',[_c('span',{staticClass:"headline"},[_vm._v("Échantillons")]),_c('v-divider',{staticClass:"mx-4",attrs:{"inset":"","vertical":""}}),_c('v-btn',{staticClass:"ml-2",attrs:{"color":"primary"},on:{"click":_vm.createEchantillon}},[_c('v-icon',[_vm._v("mdi-plus-thick")]),_vm._v(" Ajouter un échantillon ")],1),_c('v-spacer'),_c('v-text-field',{attrs:{"append-icon":"mdi-magnify","label":"Rechercher","single-line":"","hide-details":""},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}})],1),_c('v-data-table',{staticClass:"outlined",attrs:{"headers":_vm.headers,"items":_vm.echantillons,"page":1,"items-per-page":_vm.itemsPerPage,"search":_vm.search,"loading":_vm.isloading,"loading-text":"Chargement... Veuillez patienter","footer-props":{
      'items-per-page-text': 'Lignes par page',
      'items-per-page-all-text': 'Tout',
    }},scopedSlots:_vm._u([{key:"item",fn:function(ref){
    var item = ref.item;
return [_c('tr',[_c('td',[_vm._v(_vm._s(item.cEchantillon))]),_c('td',[_vm._v(_vm._s(item.cEchantillonLangue))]),_c('td',[_vm._v(_vm._s(item.cEchantillonCompetence))]),_c('td',[_vm._v(_vm._s(_vm.filterQuestionsAttributions(item.pEchantillon)))]),_c('td',[(item.pGrille)?_c('div',[_vm._v("Oui")]):_c('div',[_vm._v("Non")])]),_c('td',{staticClass:"d-flex justify-center d-flex align-center"},[_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
    var on = ref.on;
return [_c('v-btn',_vm._g({staticClass:"mr-2",attrs:{"text":"","small":""},on:{"click":function($event){return _vm.editEchantillon(item.pEchantillon)}}},on),[_c('v-icon',[_vm._v("mdi-pencil")])],1)]}}],null,true)},[_c('span',[_vm._v("Modifier l'échantillon")])])],1)])]}},{key:"no-results",fn:function(){return [_vm._v(" Aucun résultat! ")]},proxy:true},{key:"no-data",fn:function(){return [_vm._v(" Pas de données à afficher! ")]},proxy:true}])})],1)}
var staticRenderFns = []

export { render, staticRenderFns }